<div class="dropdown">
    <ul>
      <li *ngFor="let attribute of filteredAttributes; let i = index" 
      (click)="selectAttribute(attribute)"
      [ngClass]="{'active': i === activeIndex}"
      #attributeItem>
        {{ attribute?.displayName }}
      </li>
    </ul>
  </div>
  