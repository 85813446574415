<button class="meterFilter">
    <div class="filterHeader">
        <span>{{name}}</span>
        <!-- <img src="./././assets/images/filter-icon.svg" class="filterIcon"> -->
        <img src="assets/images/filter (1).svg" class="filterCommonIcon">
        <span *ngIf="filters && filters.length && filterCount()" class="content">{{filterCount()}}</span>
    </div>
    <div class="meterPopup devicePopup">
        <div class="seprator">
            <div class="sortingButtons" (click)="sort('inc')">
                <img src="assets/images/up.svg" class="filterCommonIcon"> Ascending
            </div>
            <div class="sortingButtons decending" (click)="sort('dec')">
                <img src="assets/images/up.svg" class="decending filterCommonIcon"> Decending
            </div>
        </div>
        <div class="optionsFilter">
            <div id="searchDiv" class="searchbar">
                <div class="d-flex align-items-center" style="width: 75%;">
                    <img src="assets/images/searchNew.svg" class="searchIcon">
                    <input type="text" placeholder="Search" [(ngModel)]="searchedText" (input)="onChangeInput()">
                </div>
                <button *ngIf="searchedText" (click)="clearInputFilter()">Clear</button>
            </div>
           
            <div class="searchResult" [ngClass]="{'noFilterCenter' : !(filteredData?.length)}">
                <div *ngIf="displaySelectAll && filteredData?.length" class="selectBtn">
                    <mat-checkbox [(ngModel)]="allSelected" [checked]="checkAllItems()" (change)="selectAllItems()">Select All</mat-checkbox>
                </div>
                <div class="dashboards" *ngFor="let option of filteredData;let i=index">
                    <mat-checkbox [(ngModel)]="option.selected" (change)="emitAppliedFilterEvent(option)">
                        {{option.name === 'true' || option.name === 'false' ? (option.name === 'true' ? 'Yes': 'No') :
                       ( option.name ? (option.name === 'AI') ? option.name : (option.name | titlecase) : '(Blanks)')}}</mat-checkbox>
                </div>
                <span class="noData" *ngIf="!(filteredData?.length)">
                    No filters Available
                </span>
            </div>
        </div>
    </div>
</button>