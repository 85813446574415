import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TelemetryService } from 'src/app/reusable/services/telemetry.service';
import { interval, Subscription } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { PENDING } from 'src/app/modules/device-management/device-filter/download-data-modal/download-telemetry-data-modal/constant';
import { statusList, FILES_IN_PROGRESS, READY_FILES, POLLING_STATUS_RUNNING, POLLING_STATUS_STOPPED, SUCCESS } from './constant';

@Injectable({
  providedIn: 'root'
})
export class DownloadTnpSharedService {

  public downloadStatusLoader:any = new BehaviorSubject("");
  statusList = statusList;
  FILES_IN_PROGRESS = FILES_IN_PROGRESS;
  READY_FILES = READY_FILES;
  private pollingSubscription: Subscription | null = null;
  currentPollingStatus = POLLING_STATUS_STOPPED;

  constructor(
    private telemetryService: TelemetryService
  ) { }

  showFileStatusOnSidebar(status) {
    this.downloadStatusLoader.next(this.statusList[status])
  }

  fetchNotificationForReadyFiles():any {
    this.telemetryService.getNotificationForReadyFiles().subscribe(res => {
      if(res[READY_FILES].length > 0) {
        this.showFileStatusOnSidebar(SUCCESS)
      }
      if(res[FILES_IN_PROGRESS] > 0) {
        this.showFileStatusOnSidebar(PENDING);
      }
    });
   
  }

  shortPollingForDownloadStatusOfFiles() {
    // if pooling is already running no need to request for new polling interval for fetch status of files
    if(this.currentPollingStatus === POLLING_STATUS_RUNNING) 
      return;

    this.currentPollingStatus = POLLING_STATUS_RUNNING;
    this.pollingSubscription = interval(30000) // Poll every minute
    .pipe(
      switchMap(() => this.telemetryService.getNotificationForReadyFiles()), // Call API on every interval
      takeWhile((response) => response[FILES_IN_PROGRESS] !== 0, true) // Continue until files in progress is 0
    )
    .subscribe({
      next: (data) => {
        if(data[READY_FILES].length > 0) {
          this.showFileStatusOnSidebar(SUCCESS)
        }
        if(data[FILES_IN_PROGRESS] > 0) {
          this.showFileStatusOnSidebar(PENDING);
        }
      },
      complete: () => {
        this.stopPolling();
       //Polling stopped as pending is 0
      },
      error: (error) => {
        // error while pooling data
        this.stopPolling(); // Stop polling on error
      },
    });
  }

  stopPolling() {
    if (this.pollingSubscription) {
      this.currentPollingStatus = POLLING_STATUS_STOPPED;
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null;
    }
  }
}
