import { Component, OnInit } from '@angular/core';
import { DeviceProfileService } from '../device-profile/device-profile.service';
import { MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver-es';
import { DevicesService } from '../devices/devices.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { getLoginUser } from 'src/app/reusable/user-util';
import * as moment from 'moment';
import { BulkRegistrationData, Device, DeviceProfile, OnboardingDevice } from './interface';
import { ProfileResponse } from '../device-modal/device';
import { DEVICE_TYPE_COOLING_METER, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_WATER_METER } from 'src/app/reusable/constant';
import { bulkRegistrationMessages, bulkRegistrationCsvHeaders, DATE_FORMAT} from '../device-modal/helper';

@Component({
  selector: 'app-bulk-registration',
  templateUrl: './bulk-registration.component.html',
  styleUrls: [
    './bulk-registration.component.css',
    '../../../../assets/Reusable-CSS/form.scss',
    '../../../../assets/Reusable-CSS/buttons.scss',
    '../../../../assets/styles/form.css',
    '../../../../assets/Reusable-CSS/matTooltip.scss'
  ]
})

export class BulkRegistrationComponent implements OnInit {
  public profiles: Array<DeviceProfile>;
  public userName: string;
  public profile: string;
  public isUniqueIdentifier = false;
  public loading: boolean = false;


  public file: File;
  deviceRecord: BulkRegistrationData;
  records: Array<BulkRegistrationData> = [];
  files: File[] = [];
  progressValue: number = 0;
  deviceType: string;
  connectorProtocol: string;

  constructor(
    public deviceService: DevicesService,
    private toastr: ToastService,
    private dialogRef: MatDialogRef<BulkRegistrationComponent>,
    public deviceProfileService: DeviceProfileService,
  ) { }

  ngOnInit() {
    this.userName = getLoginUser();
    this.getDeviceProfiles();
  }

  getDeviceProfiles() {
    this.deviceService.getAvailableProfiles().then((response: ProfileResponse) => {
      const profileIds = response.profileIds;
      const profiles = [];
      for (const key in profileIds) {
        const value = profileIds[key];
        profiles.push({ name: key, id: value[0], protocol: value[1] })
      }
      this.profiles = profiles;
    }).catch((error: Error) => {
      throw error;
    });
  }


  onSelect(event) {
    this.files = [];
    this.files.push(...event.addedFiles);
    this.file = this.files[0];
    if (this.isValidCSVFile(this.file)) {
      this.onUploadSuccess(this.files);
    }
    else {
      this.file = null;
      this.toastr.warning(bulkRegistrationMessages.fileValidation);
    }

  }

  onUploadSuccess(event): void {
    const file: File = event[0];
    const fileReader: FileReader = new FileReader();
    fileReader.onload = (e: any) => {
      const fileContent: string = e.target.result;
      let csvRecordsArray = (<string>fileContent).split(/\r\n|\n/);
      // Process the file content or send it to the server
      let headersRow = this.getHeaderArray(csvRecordsArray);

      // check if all required header present or not
      if (!this.validateHeaders(headersRow)) {
        this.file = null;
        this.toastr.warning(bulkRegistrationMessages.unsupportedFormat);
        return;
      }
      if (!(csvRecordsArray?.length > 1)) {
        this.file = null;
        this.toastr.warning(bulkRegistrationMessages.noValidData);
        return;
      }
      this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
    };

    fileReader.onprogress = (e: ProgressEvent<FileReader>) => {
      if (e.lengthComputable) {
        this.progressValue = Math.round((e.loaded / e.total) * 100);
      }
    };

    fileReader.readAsText(file);
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray, headerLength) {
    let csvArr = [];
    for (let i = 1; i < csvRecordsArray.length; i++) {
      let currentRecord = (<string>csvRecordsArray[i]).split(',');
      if (currentRecord?.length == headerLength) {
        const deviceRecord = new BulkRegistrationData({ profile: this.profile, deviceType: this.deviceType, csvRecord: currentRecord });
        this.deviceRecord = deviceRecord;
        csvArr.push(deviceRecord);
      }
    }
    return csvArr;
  }

  isValidCSVFile(file: File) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  validateHeaders(headerArr) {
    const headerValues = this.addCustomHeaders();
    for (let i = 0; i < headerArr.length; i++) {
      const headingName = headerArr[i];
      const idx = headerValues.findIndex(item => item.toLowerCase() === headingName.toLowerCase())
      if (idx === -1) {
        return false;
      }
    }
    return true;
  }

  addCustomHeaders() {
    const headerValues = [];
    let customHeaders = [];
    const { commonHeader, waterMeterHeader, energyMeterHeader, coolingMeterHeader } = bulkRegistrationCsvHeaders;
    headerValues.push(...commonHeader);
    const deviceType = this.deviceType;
    switch (deviceType) {
      case DEVICE_TYPE_WATER_METER:
        customHeaders = waterMeterHeader;
        break;
      case DEVICE_TYPE_ENERGY_METER:
        customHeaders = energyMeterHeader;
        break;
      case DEVICE_TYPE_COOLING_METER:
        customHeaders = coolingMeterHeader;
        break;
    }
    headerValues.push(...customHeaders);
    return headerValues;
  }

  onUploadError(e) {
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }


  async bulkRegistrationOfDevices() {
    if (!(this.records?.length && this.profile)) {
      this.toastr.info(bulkRegistrationMessages.mantatoryFieldValidation);
      return;
    }

    this.loading = true;
    const payloadRecords = [];
    for (let i = 0; i < this.records?.length; i++) {
      const {commissioningDate, profileID, serialNumber, measurableLocation, zone, site, location, manufacturerName, operation, useGateway, servicePointID, parentMeterServicePointID, manufacturingDate,firmwareVersion,batch, isBillable, utilityServiceType, customerMeterType, customerType, buildingNumber, customerDescription, port,
        energyBreakerCapacity, gatewayName, ipAddress, meterPassword, waterDiameter
       } = this.records[i];
      let payload: OnboardingDevice = {
        profileID,
        serialNumber,
        measurableLocation,
        zone,
        site,
        location,
        manufacturerName,
        operation,
        operationBy: "",
        commissioningDate: new Date(moment(commissioningDate, DATE_FORMAT).format()),
        useGateway,
        metadata: {
          servicePointID: servicePointID || null,
          parentMeterServicePointID: parentMeterServicePointID || null,
          batch: batch || null,
          manufacturingDate: manufacturingDate ? new Date(moment(manufacturingDate, DATE_FORMAT).format()) : null,
          firmwareVersion: firmwareVersion,
          isBillable: isBillable,
          utilityServiceType: utilityServiceType,
          customerMeterType: customerMeterType,
          customerType: customerType,
          buildingNumber: buildingNumber,
          customerDescription: customerDescription,
        },
        connection: {
          port: port || 0,
        },
        protocol: this.connectorProtocol.toLowerCase(),
        hidden: false
      };;
      if (this.deviceType === DEVICE_TYPE_ENERGY_METER) {
        payload['metadata'] = {
          ...payload['metadata'],
          energyBreakerCapacity: parseFloat(energyBreakerCapacity) || null
        };
        payload['connection'] = {
          ...payload['connection'],
          host: ipAddress,
          password: meterPassword,
        };
      }
      if (this.deviceType === DEVICE_TYPE_WATER_METER || this.deviceType === DEVICE_TYPE_COOLING_METER) {
        payload = {
          ...payload,
          gatewayName,
        };
        if (this.deviceType === DEVICE_TYPE_WATER_METER) {
          payload['metadata'] = {
            ...payload['metadata'],
            waterDiameter: waterDiameter || null
          }
        }
      }

      payloadRecords.push(payload);
    }

    try {
      await this.deviceService.bulkRegistrationOfDevices(payloadRecords).then((data: Array<string>) => {
        if (data?.every(device => device)) {
          this.loading = false;
          this.dialogRef.close("true");
          this.toastr.success(bulkRegistrationMessages.success);
        } else if (!(data?.every(device => device)) && data?.some(device => device)) {
          this.loading = false;
          this.dialogRef.close("true");
          this.toastr.error(bulkRegistrationMessages.partialFailure);
        } else {
          this.loading = false;
          this.dialogRef.close("true");
          this.toastr.error(bulkRegistrationMessages.failure);
        }
      }).catch((error: Error) => {
        this.loading = false;
        this.dialogRef.close("true");
        this.toastr.error(bulkRegistrationMessages.failure);
      })
    }
    catch (err) {
      this.loading = false;
      this.toastr.warning(bulkRegistrationMessages.failure);
      this.dialogRef.close();
    }
  }

  downloadSample() {
    if (this.profile) {
      const headerValues = this.addCustomHeaders();
      const header = JSON.parse(JSON.stringify(headerValues));
      let csv = [];
      csv.unshift(header.join(','));
      const csvArray = csv.join('\r\n');
      const profileName = this.profiles?.find(profile => profile.id === this.profile)?.name;
      const filenamelatest = `${profileName}-${Date.now()}.csv`;
      const blob = new Blob([csvArray], { 'type': 'text/csv' });
      saveAs(blob, filenamelatest);
    } else {
      this.toastr.warning(bulkRegistrationMessages.profileValidation);
    }
  }

  async profileChange() {
    const profile = this.profile;
    await this.deviceProfileService.getDeviceProfileById(profile).then((data: Device) => {
      const { deviceType, connectorProtocol } = data;
      this.deviceType = deviceType;
      this.connectorProtocol = connectorProtocol;
    }).catch((error: Error) => {
      console.log(error);
    });
  }

  deleteFile() {
    this.records = [];
    this.file = null;
    this.files = [];
  }

}
