import { Component, EventEmitter, OnInit } from '@angular/core';
import { TelemetryService } from 'src/app/reusable/services/telemetry.service';
import * as moment from 'moment';
import { DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START } from 'src/app/reusable/constant';
import { AlertsManagementService } from 'src/app/reusable/services/alerts-management.service';
import { initializeDateRange } from 'src/app/reusable/date-util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-cpaa',
  templateUrl: './common-cpaa.component.html',
  styleUrls: ['./common-cpaa.component.css']
})
export class CommonCPAAComponent implements OnInit {
  duration = 30;
  dates;
  energyData;
  waterData;
  percentageChange;
  waterProduced=0;
  alertCount
  constructor(
    private telemetryService: TelemetryService,
    private alertsManagementService: AlertsManagementService,
  ) { }

  ngOnInit(): void {
    this.dates = [moment().startOf('month').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)];
    this.initialize();
  }

  async initialize() {
    this.telemetryService.getPercentageChangesForConsumption(this.dates).subscribe(data => {
      this.percentageChange=data
    })
    let payLoad={
      deviceType:"DubaPlantScada",
      deviceId:"NSDB1000104"
    }
    this.telemetryService.getLatestTelemetryData(payLoad).subscribe(data => {
      this.waterProduced= data?.[0]?.['content.rawValue']
    })
    const payloadForDevices = {
      dates: this.dates,
      filter: {'NEOM Community 1': ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Zone 5']}
    };
    this.telemetryService.getConsumptionPerDevice(payloadForDevices, 'daily', 'Energy Meter').subscribe((resp: any) => {
      this.energyData = resp

    })

    this.telemetryService.getConsumptionPerDevice(payloadForDevices, 'daily', 'Water Meter').subscribe((resp: any) => {
      this.waterData = resp
    })
    let payload = {
      dates : [moment().format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)],
      status : ['active']
    }
    const response: any = await this.alertsManagementService.getAllAlerts(payload).toPromise();
    if(response && response.data.length > 0) {
      this.alertCount = response.data.length;
      let dates = {
        startDate : response.data[response.data.length - 1].createdOn,
        endDate : response.data[0].createdOn
      }
      // localStorage.setItem('alertCountDates', JSON.stringify(dates));
    }
  }

  convertToK(number) {
    let result = number / 1000;
    // return result.toFixed(2) + ' K';
    return result;
  }

  roundingOfAndFixedNumber(number) {
    return Math.round(number)
  }
}
