import { NC1_BASE_CAMP, SINDALAH_ISLAND } from "src/app/reusable/siteConstants";

export const ZONES = {
    [NC1_BASE_CAMP]: {
       center: [35.110818, 28.11297],
       data: {
         "Zone 1": {
           coordinates: [
             [35.107288, 28.11497],
             [35.108135, 28.114993],
             [35.108153, 28.11438],
             [35.107309, 28.114352],
             [35.107288, 28.11497]
           ],
           labelLocation: [35.107634999999995, 28.114628000000003],
           fillColor: 'rgba(254, 202, 57, 0.1)',
           borderColor: '#e4bf32',
           labelText: 'Zone 1'
         }
         ,
         "Zone 2": {
           coordinates: [
             [35.107312, 28.114205],
             [35.10816, 28.114231],
             [35.108187, 28.113613],
             [35.107335, 28.113590000000002],
             [35.107312, 28.114205],
           ],
           labelLocation: [35.107657, 28.113953000000002],
           fillColor: 'rgba(254, 202, 57, 0.1)',
           borderColor: '#e4bf32',
           labelText: 'Zone 2'
         }
         ,
         "Zone 3": {
           coordinates: [
             [35.107338, 28.113453999999997],
             [35.107507, 28.113459999999996],
             [35.107535999999996, 28.112845],
             [35.107358000000005, 28.112835999999998],
             [35.107338, 28.113453999999997]
           ],
           labelLocation: [35.107347, 28.113101],
           fillColor: 'rgba(254, 202, 57, 0.1)',
           borderColor: '#e4bf32',
           labelText: 'Zone 3'
         },
         "Zone 4": {
           coordinates: [
             [35.107364000000004, 28.112628000000004],
             [35.10822, 28.112655],
             [35.108249, 28.111862],
             [35.107397999999996, 28.111842],
             [35.107364000000004, 28.112628000000004]
           ],
           labelLocation: [35.10779, 28.1124],
           fillColor: 'rgba(254, 202, 57, 0.1)',
           borderColor: '#e4bf32',
           labelText: 'Zone 4'
         },
         "Zone 5": {
           coordinates: [
             [35.1074, 28.111694],
             [35.108253000000005, 28.111720000000002],
             [35.108270000000005, 28.110931],
             [35.107438, 28.110908000000002],
             [35.1074, 28.111694]
           ],
           labelLocation: [35.10779, 28.111441],
           fillColor: 'rgba(254, 202, 57, 0.1)',
           borderColor: '#e4bf32',
           labelText: 'Zone 5'
         }
       }
    },
    [SINDALAH_ISLAND]: {
     center: [34.71213489952531, 28.046840062739353]
    }
   }

   export const DEVICES = 'devices';
   export const GATEWAYS = 'gateways';