import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './modules/login/login.component';
import { SelectSectorComponent } from '../app/modules/select-sector/select-sector.component';
import { RouteGaurd } from './guards/route.guard';
import { SessionGuard } from "./guards/session.guard";
import { SearchResultComponent } from './modules/search-result/search-result.component';
import { AssetResultsComponent } from './modules/asset-results/asset-results.component';
import { Error404Component } from './reusable/error404/error404.component';
import { DevelopersPageComponent } from './modules/developers-page/developers-page.component';
import { AdminGuard } from './guards/admin.guard';
import { ModuleGuard } from './guards/module.guard';
import { AccessForbiddenComponent } from './reusable/access-forbidden/access-forbidden.component';
import { SectorHomeComponent } from './modules/select-sector/sector-home/sector-home.component';
import { SectorKpicardsComponent } from './modules/select-sector/sector-kpicards/sector-kpicards.component';
import { LeakageInsightsComponent } from './modules/watermeters/leakage-insights/leakage-insights.component';

const routes: Routes = [
  { 'path': '', 'redirectTo': '/home', 'pathMatch': 'full' },
  { 'path': 'forbidden', 'component': AccessForbiddenComponent },
  { 'path': 'home', 'component': SelectSectorComponent, 'canActivate': [AuthGuard],children:[ 
    { 'path': '', 'component': SectorHomeComponent },
    { 'path': 'watermeters/:type', 'component': SectorKpicardsComponent },
    { 'path': 'energymeters/:type', 'component': SectorKpicardsComponent },
  ] },
  { 'path': 'user', 'component': LoginComponent },
  { 'path': 'sso', 'loadChildren': () => import('./modules/sso/sso.module').then(m => m.SsoModule)},
  { 'path': 'developers-page', 'component': DevelopersPageComponent },
  { 'path': '', 'loadChildren': () => import('./modules/watermeters/watermeters.module').then(m => m.WatermetersModule),  'canLoad': [ModuleGuard] },
  { 'path': 'watermeters-industrial', 'loadChildren': () => import('./modules/watermeters-industrial/watermeters-industrial.module').then(m => m.WatermetersIndustrialModule),  'canLoad': [ModuleGuard]},
  { 'path': 'watermeters-customer', 'loadChildren': () => import('./modules/watermeters-customer/watermeters-customer.module').then(m => m.WatermetersCustomerModule),  'canLoad': [ModuleGuard]},
  { 'path': '', 'loadChildren': () => import('./modules/energymeters/energymeters.module').then(m => m.EnergymetersModule),  'canLoad': [ModuleGuard] },
  { 'path': '', 'loadChildren': () => import('./modules/coolingmeters/coolingmeters.module').then(m => m.CoolingMetersModule),  'canLoad': [ModuleGuard] },
  { 'path': 'admin', 'loadChildren': () => import('./modules/admin/admin.module').then(m => m.AdminModule),  'canLoad': [ModuleGuard] },
  { 'path': 'alert-management', 'loadChildren': () => import('./modules/threshold-alert/threshold-alert.module').then(m => m.ThresholdAlertModule),  'canLoad': [ModuleGuard] },
  { 'path': 'access-management', 'loadChildren': () => import('./modules/access-management/access-management.module').then(m => m.AccessManagementModule), 'canLoad': [ModuleGuard]},
  { 'path': 'arcgis-map', 'loadChildren': () => import('./modules/arcgis-map/arcgis-map.module').then(m => m.ArcgisMapModule),  'canLoad': [ModuleGuard] },
  { 'path': 'custom-dashboard', 'loadChildren': () => import('./modules/custom-dashboard/custom-dashboard.module').then(m => m.CustomDashboardModule),  'canLoad': [ModuleGuard] },
  { 'path': 'device-tracker', 'loadChildren': () => import('./modules/device-tracker/device-tracker.module').then(m => m.DeviceTrackerModule),  'canLoad': [ModuleGuard]},
  { 'path': 'search-result', 'component': SearchResultComponent, 'canActivate': [AuthGuard] },
  { 'path': 'asset-result', 'component': AssetResultsComponent, 'canActivate': [AuthGuard] },
  { 'path': 'asset-result/:id', 'component': AssetResultsComponent, 'canActivate': [AuthGuard] },

  { 'path': 'connectioncanvas', 'loadChildren': () => import('./modules/connection-canvas/connection-canvas.module').then(m => m.ConnectionCanvasModule),  'canLoad': [ModuleGuard] },
  { 'path': 'download-tn-panel', 'loadChildren': () => import('./modules/download-tn-panel/download-tn-panel.module').then(m => m.DownloadTNPanelModule),  'canLoad': [ModuleGuard] },
  // { 'path': 'connectioncanvas', 'component': ConnectionCanvasComponent },

  { 'path': 'leakage-insights', 'component': LeakageInsightsComponent },

  { 'path': '**', 'component': Error404Component },


  
];

@NgModule({
  'imports': [RouterModule.forRoot(routes, { 'relativeLinkResolution': 'legacy', 'onSameUrlNavigation': 'reload' })],
  'exports': [RouterModule]
})
export class AppRoutingModule {
}
