import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDashboardService } from 'src/app/services/CustomDashboard/custom-dashboard.service';
import { WidgetService } from 'src/app/services/CustomDashboard/widget.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CustomDashboardLogsService } from 'src/app/services/CustomDashboard/custom-dashboard-logs.service';
import { NotificationPopupComponent } from '../notification-popup/notification-popup.component';
import { Router } from '@angular/router';
import { getLoginUser } from '../user-util';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';

@Component({
  selector: 'app-save-project',
  templateUrl: './save-project.component.html',
  styleUrls: ['./save-project.component.css', 
              "../../../assets/Reusable-CSS/ng-select.scss",
              '../../../assets/Reusable-CSS/buttons.scss', 
              '../../../assets/Reusable-CSS/popup.scss',
              '../../../assets/styles/form.css',
              "../../../assets/Reusable-CSS/form.scss",
  ],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'customDropdownClass' }
    }
  ],
})
export class SaveProjectComponent implements OnInit {

  public widget: any = {
    dashboard: '',
    name: '',
    dashboardId: ''
  };
  public customDashboard = [];
  public isNewDashboardAllow = false;
  filteredDashboard: Observable<any[]>;
  dashboardCtrl = new FormControl('');

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    private dialogRef: MatDialogRef<SaveProjectComponent>,
    public customDashboardService: CustomDashboardService,
    public widgetService: WidgetService,
    public customDashboardLogsService: CustomDashboardLogsService,
    public toastrService: ToastService,
    private dialog: MatDialog) {

  }

  ngOnInit() {
    this.widget = { ...this.widget, ...this.data };
    if (this.widget.widgetData) {
      this.widget = this.widget.widgetData;
      this.dashboardCtrl.disable();
    }
    this.customDashboardService.getAllCustomDashboard(getLoginUser()).then((data: any) => {
      this.customDashboard = data.dashboards.filter((x) => {
        return x.accesType.access !== "Read";
      });
      const dashboard = this.customDashboard?.find(x => x._id === this.widget.dashboardId);
      if (dashboard) {
        this.widget.dashboard = dashboard.name;
      }
      this.filteredDashboard = this.dashboardCtrl.valueChanges.pipe(
        startWith(''),
        map(state => (state ? this._filterCustomDashboard(state) : this.customDashboard.slice())),
      );
    })
  }

  private _filterCustomDashboard(value: string): any[] {
    const filterValue = value;
    const data = this.customDashboard.filter(state => state.name.includes(filterValue.trim()));
    if (!this.customDashboard?.find(x => x.name === filterValue.trim())) {
      this.isNewDashboardAllow = true;
    }
    else {
      this.isNewDashboardAllow = false;
    }
    return data;
  }

  async saveProject(){
    try {
      if(this.data.type === 'SaveDrawing'){
        const existing = this.customDashboard.find(dashboard => dashboard.name === this.widget.dashboard);
        if(!existing){
          this.dialogRef.close({isSaveDrawing:true, dashboardName: this.widget.dashboard});
        }
      }else{
        this.addWidget();
      }
    } catch (error) {
      throw new Error("Save Project Component : saveProject() : " + error);      
    }
  }

  async addWidget() {
    const userName = getLoginUser();
    if (this.data.widgetData) {
      this.widgetService.updateWidget(this.widget).then((data: any) => {
        this.dialogRef.close(true);
        this.dialog.open(NotificationPopupComponent, {
          data: {
            title: 'Saved successfully',
            dashboardId: this.widget.dashboardId,
            subTitle: `${this.widget.name} chart updated successfully.`,
            // openDashboard: this.openDashboard
          }
        });
      })
    }
    else {
      const dashboard = this.customDashboard?.find(x => x.name === this.widget.dashboard);
      const widgetData =
      {
        name: this.widget.name,
        type: this.widget.widgetType,
        createdBy: userName,
        dashboardId: '',
        requestPayload: this.widget.requestPayload,
        requestMethod: this.widget.requestMethod,
        requestUrl: this.widget.requestUrl,
        xaxis: this.widget.xaxis,
        yaxis: this.widget.yaxis,
        attributeType: this.widget.attributeType,
        isOperationalRange: this.widget.isOperationalRange,
        pageSize: this.widget?.pageSize || 5,
        x: 0,
        y: 1000,
        h: 6,
        w: 6,
        adaptive: this.widget.adaptive,
        cardType: this.widget.cardType,
        splitYAxis: this.widget.splitYAxis
      }

      if (!dashboard) {
        const dashboardData = {
          "name": this.widget.dashboard,
          "createdBy": userName,
          "accessType": [{ "Id": userName, "access": 'Owner' }]
        }
        await this.customDashboardService.saveCustomDashboard(dashboardData).then((data: any) => {
          widgetData.dashboardId = data.insertedId;
          const dashboardLog = {
            "dashboardId": widgetData.dashboardId,
            "event": "add",
            "doneBy": userName
          }
          this.customDashboardLogsService.saveCustomDashboardLog(dashboardLog).then((data: any) => { })
        })
      }
      else {
        widgetData.dashboardId = dashboard._id
      }

      this.widgetService.saveWidget([widgetData]).then((data: any) => {
        this.dialogRef.close(true);
        this.dialog.open(NotificationPopupComponent, {
          data: {
            title: 'Saved successfully',
            dashboardId: widgetData.dashboardId,
            subTitle: `${this.widget.name} widget save successfully`,
            // openDashboard: this.openDashboard
          }
        });
      })
    }
  }

  openDashboard(dashboardId) {
    this.router.navigate(['/custom-dashboard/' + dashboardId]);
  }
}