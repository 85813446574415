import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
@Component({
  selector: 'app-table-select-filter',
  templateUrl: './table-select-filter.component.html',
  styleUrls: ['./table-select-filter.component.css']
})
export class TableSelectFilterComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() name: string;
  @Input() key: string;
  @Input() displaySelectAll: boolean = false;

  @Input() filters = [];
  @Output() applySorting = new EventEmitter<any>();
  @Output() handleAppliedFilter = new EventEmitter<any>();
  @Output() handleAppliedBulkFilter = new EventEmitter<any>();

  searchedText: string = "";
  filteredData: any = [];
  sortingInfo: any;
  allSelected: boolean;

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges() {
    this.filteredData = this.data;
  }

  emitAppliedFilterEvent(item) {
    const checkAllDevices = this.filteredData?.every(filter => filter?.selected);
    if(checkAllDevices){
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    this.handleAppliedFilter.emit(item);
  }

  sort(trend) {
    this.sortingInfo = {
      column: this.data[0]['key'],
      sort: trend
    }
    this.applySorting.emit(this.sortingInfo)
  }

  onChangeInput() {
    this.filteredData = this.data.filter((item) => item.name.toLowerCase().includes(this.searchedText.toLowerCase()))
  }

  clearInputFilter() {
    this.searchedText = "";
    this.filteredData = this.data;
  }

  filterCount() {
    const filter = this.filters?.filter((data: any) => data.key === this.key);
    return filter?.length;
  }

  selectAllItems() {
    const filteredData = this.filteredData?.map(element => {
      element.selected = this.allSelected;
      return element;
    });

    this.handleAppliedBulkFilter.emit(filteredData);
  }

  checkAllItems(){
    return this.filteredData?.every(filter => filter?.selected);
  }
}
