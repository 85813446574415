import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaginationComponent } from 'src/app/reusable/custom-table/pagination/pagination.component';
import { TableAppliedFiltersComponent } from 'src/app/reusable/custom-table/table-applied-filters/table-applied-filters.component';
import { TableSearchComponent } from 'src/app/reusable/custom-table/table-search/table-search.component';
import { COLUMN_NAMES, FILTER_COLUMNS, SORT_FILTER } from '../declaration';
import { IAlertDataModel } from 'src/app/modules/alerts/alerts.interface';

@Component({
  selector: 'app-trigger-history',
  templateUrl: './trigger-history.component.html',
  styleUrls: ['./trigger-history.component.css',
  '../../../assets/Reusable-CSS/table.scss'
  ]
})
export class TriggerHistoryComponent implements OnInit {

  filterColumns: FILTER_COLUMNS;
  sortFilter: any;
  columnNames: any;
  loading: boolean = false;
  @Input() allAlerts: IAlertDataModel[] = [];

  @ViewChild('tableAppliedFilter', { static: false }) tableAppliedFilter: TableAppliedFiltersComponent;
  @ViewChild('tableSearchBar', { static: false }) tableSearchBar: TableSearchComponent;
  @ViewChild('tablePagination', { static: false }) tablePagination: PaginationComponent;

  public appliedFilters = [];
  public paginatedData: any = []; // this will store the paginated data based on item per page, like 10 if itemPerPage = 10, this variable used on the ui
  public filterAlerts = [];
  //  pagination related variable
  public currentPage: number = 1;
  public itemPerPage: number = 5;
  public pageSizeOption = [5,10,15,20,25]

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private router: Router) {
    
   }

  ngOnInit(): void {
      this.paginatedData = this.allAlerts;
      this.filterColumns = new FILTER_COLUMNS;

      this.sortFilter = SORT_FILTER;
      this.columnNames = COLUMN_NAMES;
  }

  
  

  handleAppliedFilter(item) {
    if(item.selected) {
      this.appliedFilters.push(item);
    }
    else {
      const idx = this.appliedFilters.findIndex(filter => filter.name === item.name);
      this.appliedFilters.splice(idx, 1);
    }
    this.tablePagination.handleAppliedFilters();
  }

  handleRemoveFilter(item) {
    const idx = this.appliedFilters.findIndex(filter => filter.name === item.name);
    this.appliedFilters.splice(idx, 1);
    this.tablePagination.handleAppliedFilters();
  }

  sortTable(data) {
    this.tablePagination.handleSort(data);
  }

  handlePaginationDone(data) {
    this.filterAlerts = data.filteredData;
    this.paginatedData = data.paginatedData;
  }

  getStatusClass(status) {
    return status === 'Active' ? 'statusActive' : 'statusResolved';
  }

  getAlertType(alertTypes) {
    return alertTypes.split(',').map((alertType) => alertType.charAt(0).toUpperCase() + alertType.substring(1)).toString()
  }

  getAlertClassification(alertClassification) {
    return alertClassification || '';
  }

  goToManageAlert(alert: any) {
    return;
    this.router.navigate(
      [`/alert-management/alert/${alert.alertID}`]
    );
  }

}
