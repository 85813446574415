import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThresholdAlertChartsService {

  constructor() { }
  public donutChart(data: any, colors?: any, radius?:any) {
    const option = {
      tooltip: {
        trigger: "item",
        formatter: "{a} {b}: {c} ({d}%)",
      },
      color: colors?colors:["#00c39c", "#e4bf32", "#5a7fca"],
      series: [
        {
          name: "",
          type: "pie",
          radius: radius?radius:["40%", "70%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center"
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold"
              }
            }
          },
          labelLine: {
            normal: {
              show: false
            }
            },
          "data": data
        }
      ]
    };
    return option;
  }

  public donutChartForLeakage(data: any) {
    const option = {
      tooltip: {
        trigger: "item",
        formatter: "{a} {b}: {c} ({d}%)",
      },
      color: ["#188353", "#ffd806","#fcab53","#e36748"],
      series: [
        {
          name: "",
          type: "pie",
          radius: ["40%", "70%"],
          center: ["50%", "50%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center"
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold"
              }
            }
          },
          labelLine: {
            normal: {
              show: false
            }
            },
          "data": data
        }
      ]
    };
    return option;
  }


  public trendsStackedLineChartMarkArea(legend: any, xAxis: any, graphData: any[], title?: any,  dateOption?:any[],zoomvariable?:any) {
    const option = {
      title: {
        text: title,
        textStyle: {
          // fontFamily: "Open Sans, sans-serif",
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: "400",
          color: '#888',


        }
      },
      tooltip: {
        trigger: "axis",
        formatter: "<br/> Time : {b} <br/> " +
            "Value: {c}"

      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "50",
        containLabel: true
      },
      toolbox: {
        feature: {}
      },
      xAxis: {
        type: "category",
        min: 7,
        boundaryGap: false,
        data: xAxis,
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      dataZoom: [
        {
          show: true,
          start: 0,
          end: 100,
          fillerColor: "rgba(168, 235, 229, 0.6)"
        },
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: "empty",
          width: 0,
          height: "0",
          showDataShadow: false,
          left: "0"
        }
      ],
      series: [
        {
          name: "Flow",
          type: "line",
          stack: "1",
          data: graphData[0],
          color: "#41a6d3",
          symbol: "none",
          markLine: {
            symbol: ['none', 'none'],
            name: 'Report By Device',
             label: {show:false},
            lineStyle: {
                type:'solid',
              color:'#00a363',

              width:2
            },
            data: [

              {
                  xAxis: dateOption[2]},
              // {
              //   xAxis: dateOption[3],
              //   lineStyle: {
              //     type:'solid',
              //     color:'#e0403f',
              //     width:4
              //   },
              //
              // },


            ]
          },


          markArea: {
            itemStyle: {
              color: '#FBE5D6'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1]
              }]]
          }
        },

        {
          name: "Moisture",
          type: "line",
          stack: "2",
          data: graphData[1],
          color: "#68a94d",
          symbol: "none",
          markLine: {
            symbol: ['none', 'none'],
            name: 'Report By Device',
              label: {show:false},
            lineStyle: {
                type:'solid',
              color:'#00a363',
              width:2
            },
            data: [
              {xAxis: dateOption[2]},
              // {
              //   xAxis: dateOption[3],
              //   lineStyle: {
              //     type:'solid',
              //     color:'#e0403f',
              //     width:4
              //   },
              // },


            ]
          },
          markArea: {
            itemStyle: {
              color: '#FBE5D6'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1],
              }]]
          }
        },
        {
         name: "Temperature",
          type: "line",
          stack: "3",
          data: graphData[2],
          color: "#dd5026",
          symbol: "none",

          markLine: {
            symbol: ['none', 'none'],
            name:'Report By Device',
             label: {show:false},
            lineStyle: {
                type:'solid',
              color:'#00a363',
              width:2
            },
            data: [
              {xAxis: dateOption[2]},
              // {
              //   xAxis: dateOption[3],
              //
              //   lineStyle: {
              //     type:'solid',
              //     color:'#e0403f',
              //     width:4
              //   },
              // },


            ]
          },
          markArea: {
            itemStyle: {
              color: '#FBE5D6'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1]
              }]]
          }
        },
        // {
        //   name: "Volume",
        //   type: "line",
        //   stack: "4",
        //   data: graphData[2],
        //   color: "#f2bb1f",
        //   symbol: "none"
        // }

      ]
    };
    return option;
  }

  public trendsStackedLineChartMarkAreaDoubleLine(legend: any, xAxis: any, graphData: any[], title?: any,  dateOption?:any[],zoomvariable?:any) {

    const option = {
      title: {
        text: title,
        textStyle: {
          // fontFamily: "Open Sans, sans-serif",
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: "400",
          color: '#888',


        }
      },
      tooltip: {
        trigger: "axis",
        formatter: "<br/> Time : {b} <br/> " +
            "Value: {c}"

      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "50",
        containLabel: true
      },
      toolbox: {
        feature: {}
      },
      xAxis: {
        type: "category",
        min: 7,
        boundaryGap: false,
        data: xAxis,
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      dataZoom: [
        {
          show: true,
          start: 0,
          end: 100,
          fillerColor: "rgba(168, 235, 229, 0.6)"
        },
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: "empty",
          width: 0,
          height: "0",
          showDataShadow: false,
          left: "0"
        }
      ],
      series: [
        {
          name: "Flow",
          type: "line",
          stack: "1",
          data: graphData[0],
          color: "#41a6d3",
          symbol: "none",
          markLine: {
            symbol: ['none', 'none'],
            name: 'Report By Device',
            label: {show:false},
            lineStyle: {
              type:'solid',
              color:'#70AD47',

              width:2
            },
            data: [

              {
                xAxis: dateOption[2]},
              {
                xAxis: dateOption[3],
                lineStyle: {
                  type:'solid',
                  color:'#843C0C',
                  width:2
                },

              },


            ]
          },


          markArea: {
            itemStyle: {
              color: '#FDF2EB'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1]
              }]]
          }
        },

        {
          name: "Moisture",
          type: "line",
          stack: "2",
          data: graphData[1],
          color: "#68a94d",
          symbol: "none",
          markLine: {
            symbol: ['none', 'none'],
            name: 'Report By Device',
            label: {show:false},
            lineStyle: {
              type:'solid',
              color:'#70AD47',
              width:2
            },
            data: [
              {xAxis: dateOption[2]},
              {
                xAxis: dateOption[3],
                lineStyle: {
                  type:'solid',
                  color:'#843C0C',
                  width:2
                },
              },


            ]
          },
          markArea: {
            itemStyle: {
              color: '#FDF2EB'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1],
              }]]
          }
        },
        {
          name: "Temperature",
          type: "line",
          stack: "3",
          data: graphData[2],
          color: "#dd5026",
          symbol: "none",

          markLine: {
            symbol: ['none', 'none'],
            name:'Report By Device',
            label: {show:false},
            lineStyle: {
              type:'solid',
              color:'#70AD47',
              width:2
            },
            data: [
              {xAxis: dateOption[2]},
              {
                xAxis: dateOption[3],

                lineStyle: {
                  type:'solid',
                  color:'#843C0C',
                  width:2
                },
              },


            ]
          },
          markArea: {
            itemStyle: {
              color: '#FBE5D6'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1]
              }]]
          }
        },
        // {
        //   name: "Volume",
        //   type: "line",
        //   stack: "4",
        //   data: graphData[2],
        //   color: "#f2bb1f",
        //   symbol: "none"
        // }

      ]
    };
    return option;
  }
}
