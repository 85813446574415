<div class="main-Div">
  <div style="position: relative;">
    <app-breadcrumb [data]="breadcrumb"></app-breadcrumb>
    <!-- <app-navigation></app-navigation>
    <div class="watermeterDatepicker">
      <div class="dateFunction">
        <app-common-datepicker (dateChange)="datepicker($event)"
          [dateSelected]="[startDate,endDate]"></app-common-datepicker>
      </div>
    </div> -->
  </div>

  <div class="homeContainer leakageInsightsMainBlk">
    <div class="leakageInsightsTitleBlk">
      <h1>Leakage Insights</h1>
      <div class="dateLocation">
        <div class="dateLeakage">
          <app-common-datepicker (dateChange)="datepicker($event)"
            [dateSelected]="[startDate,endDate]"></app-common-datepicker>
        </div>
        <div class="locationName">
          <!-- <span class="material-symbols-outlined">location_on</span> Sindalah -->
          <span class="material-symbols-outlined">location_on</span>
          <ng-select [items]="locations" name="selectedLocation" 
          bindLabel="name"
          bindValue="name"
          (change)="loadDashboardData(true)"
          [virtualScroll]="true" placeholder="Select Locations"
            [(ngModel)]="selectedLocation" [clearable]="false" [searchable]="false">
            </ng-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="insightsCol">
          <div class="iconTime">
            <div class="iconBlk">
              <span class="material-symbols-outlined">alarm</span>
            </div>
            <div class="insightsContent">
              <div class="insightsHeading">
                <div *ngIf="!alertDetectionTimeData" class="loading-chart">
                  <div class="loader"></div>
                </div>
                <div *ngIf="alertDetectionTimeData">
                  {{(alertDetectionTimeData?.averageDifferenceTime | number: '1.2-2')}} <span>hrs</span>
                </div>
              </div>
              <div class="subHeading">Alert Detection Time</div>
            </div>
          </div>
          <div class="insightsFooterContent"><span class="material-symbols-outlined">info</span> Alerts raised 80%
            faster and are down to 3 -4 hours from 22 - 24 hours</div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="insightsCol">
          <div class="iconTime">
            <div class="iconBlk waterSaving">
              <span class="material-symbols-outlined">opacity</span>
            </div>
            <div class="insightsContent">
              <div class="insightsHeading">
                <div *ngIf="!potentialWaterSavingData" class="loading-chart">
                  <div class="loader"></div>
                </div>
                <div *ngIf="potentialWaterSavingData">
                  {{potentialWaterSavingData?.potentialSaving | number: '1.2-2'}} <span>m<sup>3</sup></span>
                </div>
              </div>
              <div class="subHeading">Potential Water Savings</div>
            </div>
          </div>
          <div class="insightsFooterContent"><span class="material-symbols-outlined">info</span> The amount of water you
            can potentially save by acting on the smart alerts</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="leakageSummaryBlk min-height560">
          <div class="leakageSummaryHeadBlk">
            <h3>
              Leakage vs Potential Water Savings
              <span>Comparison of leakage vs potential savings for each incident over time</span>
            </h3>
            <div class="graphContent">
              <div class="graphContentHeading">
                <div class="graphIcons">
                  <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.8375 0V1.16944H14.3086C14.7633 1.16944 15.218 1.03654 15.5924 0.797342C16.0204 0.504983 16.5553 0.345515 17.117 0.425249C18.0799 0.55814 18.8555 1.3289 18.9625 2.28571C19.0962 3.58804 18.1066 4.67774 16.8228 4.67774C16.3681 4.67774 15.9401 4.54485 15.5924 4.27907C15.218 4.01329 14.79 3.90698 14.3353 3.90698H12.8643V5.07641H10.5908V3.90698H8.95927C8.50457 3.90698 8.04988 4.01329 7.70218 4.27907C7.35447 4.51827 6.92653 4.67774 6.47183 4.67774C5.21474 4.67774 4.19837 3.58804 4.33211 2.28571C4.43909 1.3289 5.21474 0.531562 6.17762 0.425249C6.7393 0.345515 7.27423 0.504983 7.70218 0.797342C8.07663 1.06312 8.53132 1.16944 8.98601 1.16944H10.5908V0H12.8375Z"
                      fill="#0D90FB" />
                    <path
                      d="M29.207 18.2592V23.2293H23.0018V20.4918C23.0018 18.3124 21.2098 16.5316 19.0166 16.5316V9.80737H20.6749C25.409 9.80737 29.207 13.5815 29.207 18.2592Z"
                      fill="#0D90FB" />
                    <path d="M8.15672 5.63452H15.2446V7.89366H18.5879V17.9136H-0.000982285V7.89366H8.15672V5.63452Z"
                      fill="#0D90FB" />
                    <path
                      d="M28.484 28.6247C28.8584 29.3423 28.8049 30.1928 28.377 30.8838C27.949 31.5749 27.2001 32.0001 26.3977 32.0001C25.5686 32.0001 24.8197 31.5749 24.3917 30.8572C23.9638 30.1396 23.9638 29.2891 24.365 28.5449L26.5047 24.6646L28.484 28.6247Z"
                      fill="#0D90FB" />
                  </svg>
                </div>
                <div class="graphContentNo">
                  <div *ngIf="!totalLeakageData" class="loading-chart">
                    <div class="loader"></div>
                  </div>
                  <div *ngIf="totalLeakageData">
                    {{totalLeakageData?.waterLeaked | number: '1.2-2'}} m<sup>3</sup>
                  </div>
                </div>
              </div>
              <p>Leakage of water recorded in the selected site</p>
            </div>
          </div>
          <div class="potentialWaterSaving">
            <div class="graphBlk">
              <div class="graphInfoText">
                <div class="graphInfoTextLeft">Potential Water Saving</div>
                <div class="graphInfoTextRight">Water Leaked</div>
              </div>
              <div class="main-chart whiteMinHeight leakageVsPotentialWaterSaving" [hidden]="leakageVsPotentialSavingDataLoader">
                <!-- Graph placeholder -->
                <div class="pad-10" *ngIf="leakageVsPotentialSavingNoDataFound">
                  <app-chart-data-not-found-template></app-chart-data-not-found-template>
                 </div>
              </div>
              <div [hidden]="!leakageVsPotentialSavingDataLoader"class="loaderImg chartLoader pad-10">
                <div class="loader"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2-4">
      <div class="col-lg-9">
        <div class="leakageSummaryBlk min-height482">
          <h3>
            Leakage Detection Time Comparison (Past 6 months)
            <span>Comparison of leakage vs potential savings for each incident over time</span>
          </h3>
          <div class="graphInfoText">
            <div class="graphInfoTextLeft">Detection time by AI</div>
            <div class="graphInfoTextRight">Detection time by device</div>
          </div>
          <div class="main-chart whiteMinHeight leakageDetectionTimeComparison" [hidden]="leakageDetectionTimeComparisonDataLoader">
            <!-- Graph placeholder -->
            <div class="pad-10" *ngIf="leakageDetectionTimeNoDataFound">
              <app-chart-data-not-found-template></app-chart-data-not-found-template>
            </div>
          </div>
          <div [hidden]="!leakageDetectionTimeComparisonDataLoader" class="pad-10 loaderImg chartLoader">
            <div class="loader"></div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="leakageSummaryBlk">
          <h3>
            Alert Categorization
            <span>Based on severity</span>
          </h3>
          <div class="alertGraph">
            <!-- <svg width="241" height="242" viewBox="0 0 241 242" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M241 120.5C241 100.203 235.873 80.235 226.095 62.4487C216.317 44.6624 202.204 29.6341 185.067 18.7585C167.93 7.88285 148.323 1.51223 128.066 0.237779C107.809 -1.03667 87.5588 2.82633 69.1936 11.4683L83.5594 41.9972C96.7823 35.775 111.363 32.9936 125.948 33.9112C140.533 34.8288 154.65 39.4156 166.988 47.2461C179.327 55.0766 189.488 65.8969 196.528 78.703C203.569 91.5092 207.26 105.886 207.26 120.5H241Z" fill="#915EFD"/>
              <path d="M67.6763 12.1953C51.7337 19.971 37.7043 31.1705 26.5898 44.9942L52.8847 66.1358C60.8871 56.1828 70.9883 48.1191 82.4669 42.5206L67.6763 12.1953Z" fill="#FFCC4A"/>
              <path d="M25.5447 46.3128C9.08053 67.386 0.0940852 93.3373 0.000734176 120.079L33.7405 120.197C33.8077 100.943 40.278 82.2579 52.1322 67.0852L25.5447 46.3128Z" fill="#FE6F5A"/>
              <path d="M1.05344e-05 121.5C7.74054e-06 153.459 12.6955 184.108 35.2936 206.706C57.8918 229.304 88.5414 242 120.5 242C152.459 242 183.108 229.304 205.706 206.706C228.304 184.108 241 153.459 241 121.5L207.26 121.5C207.26 144.51 198.119 166.578 181.849 182.849C165.578 199.119 143.51 208.26 120.5 208.26C97.4898 208.26 75.4221 199.119 59.1514 182.849C42.8808 166.578 33.74 144.51 33.74 121.5L1.05344e-05 121.5Z" fill="#326FFE"/>
            </svg> -->

            <div id="canvasSeverity" class="donutChart" echarts [options]="severityDonutChartOption">
            </div>

            <div class="graphAlertCount">
              {{totalSeverityAlertCount}} Alerts
            </div>
          </div>
          <ul class="alertPriority">
            <li><span class="priorityCritical"></span> Critical</li>
            <li><span class="priorityHigh"></span> High</li>
            <li><span class="priorityMedium"></span> Medium</li>
            <li><span class="priorityLow"></span> Low</li>
          </ul>
        </div>
        <a routerLink="/alert-management" target="_blank" class="goToAlert">
          <!-- <div > -->
            <div class="alertCont">
              <span class="material-symbols-outlined">warning</span> Go to all Alerts
            </div>
            <span class="material-symbols-outlined">arrow_outward</span>
          <!-- </div> -->
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="insightsCol">
          <div class="iconTime">
            <div class="iconBlk">
              <span class="material-symbols-outlined">warning</span>
            </div>
            <div class="insightsContent">
              <div class="insightsHeading">
                <div *ngIf="!missedAlertCountData" class="loading-chart">
                  <div class="loader"></div>
                </div>
                <div *ngIf="missedAlertCountData">
                  {{missedAlertCountData?.missedAlertCount | number: '1.0'}}
                </div>
              </div>
              <div class="subHeading">Leakage Occurences Missed By Device</div>
            </div>
          </div>
          <div class="insightsFooterContent"><span class="material-symbols-outlined">info</span> Count of alerts missed
            by the devices but were detected by the AI model</div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="insightsCol">
          <div class="iconTime">
            <div class="iconBlk">
              <span class="material-symbols-outlined">salinity</span>
            </div>
            <div class="insightsContent">
              <div class="insightsHeading">
                <div *ngIf="!missedAlertCountData" class="loading-chart">
                  <div class="loader"></div>
                </div>
                <div *ngIf="missedAlertCountData">
                  {{missedAlertCountData?.missedAlertTotalLeakage | number: '1.2-2'}} <span>m<sup>3</sup></span>
                </div>
              </div>
              <div class="subHeading">Leakage Undetected By Device</div>
            </div>
          </div>
          <div class="insightsFooterContent"><span class="material-symbols-outlined">info</span> The amount of water
            leaked due to the alerts that were missed by the device</div>
        </div>
      </div>
    </div>

    <!-- Previous Ui code -->
    <!--------------------------Total Number of Leakage Alerts Cards section----------------------->
    <!-- <section id="Alerts" *ngIf="meterType==='water'" style="margin-top: 2.4rem;">
      <div class="dashboardPage" id="totalCardsDiv">
        <div class="statusInfo2">
          <div class="status-card rounded bg-white">
            <img src="assets/images/alert-b.svg" class="w22">
            <p class="status-heading status-heading2">Total Number of Leakage Alerts</p>
            <p class="status-data text-big"> {{leakageData?.totalCount}}</p>
          </div>
        </div>
        <div class="statusInfo2">
          <div class="status-card rounded bg-white">
            <div>
              <img src="assets/images/mtd-b.svg" class="">
              <p class="status-heading status-heading2">Water Leaked (in m3)</p>
              <p class="status-data text-big">{{leakageData?.totalLeakage?.toFixed(2)}}</p>
            </div>
          </div>
        </div>
        <div class="statusInfo2" style="padding-right: 0;">
          <div class="status-card rounded bg-white">
            <div>
              <span>
                <img src="assets/images/leakage_new.svg" class="">
              </span>
              <p class="status-heading status-heading2">Potential Water Saving (in m3)</p>
              <p class="status-data text-big">{{leakageData?.totalVolumeSaved?.toFixed(2)}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboardPage" id="totalCardsDiv">
        <span class="notes">Alerts raised 80% faster and are down to 3-4 Hour from 22-24 Hours.</span>
        <span class="notes">Leakage of water in the household units.</span>
        <span class="notes">The amount of water that can potentially be saved by acting on smart alerts.</span>
      </div>
    </section> -->
    <!--------------------------End Total Number of Leakage Alerts Cards section----------------------->

    <!--------------------------Leakage Alert Count section----------------------->
    <!-- <div class="leakedWrapper" style="margin-bottom: 0;">
      <div class="leakagealertInfo" *ngIf="meterType==='water'">
        <div class="bg-white rounded countDiv leakedGraph">
          <div class="hourActive leakedInfo">
            <div class="textBlack">Leakage Alert Count</div>
              <div class="leakedtab" *ngIf="!(breakPoints.isMobilePortrait || breakPoints.isMobileLandscape)">
                <a (click)="createLeakageAlertsDataAggregate('daily')"
                  [ngClass]="leakageAlertAggregate==='daily'? 'active' :'unactive' "> Daily</a>
                <a (click)="createLeakageAlertsDataAggregate('weekly')"
                  [ngClass]="leakageAlertAggregate==='weekly'? 'active' :'unactive' "> Weekly</a>
                <a (click)="createLeakageAlertsDataAggregate('monthly')"
                  [ngClass]="leakageAlertAggregate==='monthly'? 'active' :'unactive' "> Monthly</a>
              </div>
          </div>
          <div class="leakageIngo">
            <div class="alertcountDiv">
              <div class="leakedtab mt-2 mb-2" *ngIf="(breakPoints.isMobilePortrait || breakPoints.isMobileLandscape)">
                <a (click)="createLeakageAlertsDataAggregate('daily')"
                  [ngClass]="leakageAlertAggregate==='daily'? 'active' :'unactive' "> Daily</a>
                <a (click)="createLeakageAlertsDataAggregate('weekly')"
                  [ngClass]="leakageAlertAggregate==='weekly'? 'active' :'unactive' "> Weekly</a>
                <a (click)="createLeakageAlertsDataAggregate('monthly')"
                  [ngClass]="leakageAlertAggregate==='monthly'? 'active' :'unactive' "> Monthly</a>
              </div>
              <div class="chart-container">
                <div class="main-chart whiteMinHeight leakage-alert-count" [hidden]="leakageDataAggregateLoader">
                  <div class="pad-10" *ngIf="leakageWaterLeakedNoDataFound">
                    <app-chart-data-not-found-template></app-chart-data-not-found-template>
                  </div>
                </div>
                <div class="loaderImg">
                  <div [hidden]="!leakageDataAggregateLoader" class="loader loaderChart"></div>
                                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="waterInfo" *ngIf="meterType==='water'">
        <div class="bg-white rounded countDiv leakedGraph">
          <div class="hourActive leakedInfo">
            <div class="textBlack waterleakedHeader">Water Leaked</div>
            <div class="leakedtab" *ngIf="!(breakPoints.isMobilePortrait || breakPoints.isMobileLandscape)">
              <a [ngClass]="leakageWaterLeakedAggregate==='daily'? 'active' :'unactive' "
                (click)="createLeakageLeaked('daily')">Daily</a>
              <a [ngClass]="leakageWaterLeakedAggregate==='weekly'? 'active' :'unactive' "
                (click)="createLeakageLeaked('weekly')">Weekly</a>
              <a [ngClass]="leakageWaterLeakedAggregate==='monthly'? 'active' :'unactive' "
                (click)="createLeakageLeaked('monthly')">Monthly</a>
            </div>
          </div>
          <div class="">
            <div class="leakedtab mt-2 mb-2" *ngIf="(breakPoints.isMobilePortrait || breakPoints.isMobileLandscape)">
              <a [ngClass]="leakageWaterLeakedAggregate==='daily'? 'active' :'unactive' "
                (click)="createLeakageLeaked('daily')">Daily</a>
              <a [ngClass]="leakageWaterLeakedAggregate==='weekly'? 'active' :'unactive' "
                (click)="createLeakageLeaked('weekly')">Weekly</a>
              <a [ngClass]="leakageWaterLeakedAggregate==='monthly'? 'active' :'unactive' "
                (click)="createLeakageLeaked('monthly')">Monthly</a>
            </div>
            <div class="chart-container">
              <div class="main-chart whiteMinHeight leakage-alert-leaked" [hidden]="leakageWaterLeakedLoader">
                <div class="pad-10" *ngIf="leakageWaterLeakedNoDataFound">
                  <app-chart-data-not-found-template></app-chart-data-not-found-template>
                </div>
              </div>
              <div  class="loaderImg">
                <div [hidden]="!leakageWaterLeakedLoader" class="loader loaderChart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--------------------------End Leakage Alert Count section----------------------->

    <!--------------------------Top 10 Leakage Alerts section----------------------->
    <!-- <div class="tableZone" *ngIf="meterType==='water'" style="margin-top: 2.4rem;">
      <div class="tableZoneSection">
        <div class="main-table bg-white rounded" style="padding: 1.5rem 0.8rem 1.5rem 1.5rem;">
          <div class="d-flex justify-content-between align-items-center ">
            <div>
              <span class="textBlack headingPd">Top 10 Leakage Alerts</span>
            </div>
            <div>
              <div class="deviceBtn">
                  <button routerLink="/alert-management" target="_blank">
                    <span *ngIf="!breakPoints.isMobilePortrait"> Device Alerts </span>
                    <img src="assets/images/share-icon-blue.svg">
                  </button>
              </div>
            </div>
          </div>

          <div class="tableScroll">
            <table class="table zonetableSpace" style="margin-top: 0;">
              <thead>
                <tr>
                  <th width="15%">Event Start Date</th>
                  <th width="15%">Meter ID</th>
                  <th width="20%">Volume Leaked</th>
                  <th width="20%">Measurable Location</th>
                  <th width="15%">Zone</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="!leakageData?.topSeverityAlerts?.length">
                  <tr id="noResult">
                    <td colspan="12" class="noResult">
                      No alerts avaliable
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="leakageData?.topSeverityAlerts">
                  <tr *ngFor="let leakageData of leakageData?.topSeverityAlerts">
                    <td>{{leakageData.eventStart | date: 'MMM d, y, hh:mm:ss'}}</td>
                    <td>{{leakageData.deviceSerialNumber}}</td>
                    <td>{{leakageData.metadata.waterLeaked?.toFixed(3)}}</td>
                    <td>{{leakageData.location.measuredLocation}}</td>
                    <td>{{leakageData.location.zone}}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>