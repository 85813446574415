import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { breakPoints } from '../../constant';
import * as _ from 'underscore';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.css']
})
export class TableSearchComponent implements OnInit {
  @Input() placeHolder = 'Search';
  @Output() closeMobileSearchBar = new EventEmitter<any>();
  @Output() handleSearch = new EventEmitter<any>();
  breakPoints = breakPoints;
  searchedText = "";

  constructor( ) { }

  
  ngOnInit(): void {
  }

  emitHandleSearchEvent() {
    this.handleSearch.emit(this.searchedText.trim());
  }

  clearInputFilter() {
    this.searchedText = "";
    this.emitHandleSearchEvent();
  }

  handleCloseMobileSearchBar() {
    this.closeMobileSearchBar.emit();
  }
}

