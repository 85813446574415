import { Component, Input, OnInit } from '@angular/core';
import { title } from 'process';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input() data = [];
  constructor() { }

  ngOnInit(): void {
    if(this.data?.[1].title == 'Device management'  || this.data?.[1].title == 'User Management' || this.data?.[1].title == 'Alert Management' || this.data?.[1].title == 'Leakage Insights' || this.data?.[1].title == 'Data Export Manager'){
      // delete this.data?.[1].route
      return
    }
    this.data?.splice(1,1)
  }

}
