import { Component, OnInit, Input, ViewChild, } from '@angular/core';
import * as moment from 'moment';
import { DEVICE_TYPE_WATER_METER, DATE_TIME_FORMAT } from 'src/app/reusable/constant';
import { setDateRange } from 'src/app/reusable/date-util';
import { TelemetryService } from 'src/app/reusable/services/telemetry.service';
import { LandingPageEsriMapComponent } from 'src/app/reusable/landing-page-map/landing-page-esri-map/landing-page-esri-map.component';
import { MapInfo } from 'src/app/services/arcgis-map/interfaces';
import { RegistrationService } from 'src/app/reusable/services/registration.service';

@Component({
  selector: 'app-device-listing-map',
  templateUrl: './device-listing-map.component.html',
  styleUrls: ['./device-listing-map.component.css']
})
export class DeviceListingMapComponent implements OnInit {
  @Input() filteredDevices;
  @Input() searchedMapText;
  @Input() devices;
  @Input() centerOfMap;
  @Input() isConsumptionsExist:boolean;
  @Input() groupedDevices;
  @Input() mapAllData;
  loading: boolean = false;
  heatMapEnabled: boolean = false;
  displayDevices: boolean = false;
  isOptionsAddedToMap:boolean = false;
  todayStart:string;
  todayEnd:string;
  // date picker variables
  startDate: string;
  endDate: string;
  selectedMap: string = "devices";
  @ViewChild(LandingPageEsriMapComponent) map : LandingPageEsriMapComponent;
  mapInfo:MapInfo;
  mapInitialized = false;
  isConsumptionAddedToDevices:boolean = false; // this helps to add consumption data on realtime on map without load
  isMap: boolean = true;
  isHeatMapRendered = false;
  isConsumptionsExist_ = true;
  username:string='';
  meters = ['Water Meter', 'Energy Meter', 'Cooling Meter'];
  constructor(
    private telemetryService: TelemetryService,
    private registrationService: RegistrationService,
  ) { }

  ngOnInit(): void {
    this.username = localStorage.getItem("useremail");
    this.mapInfo = {
      center: this.centerOfMap,
      zoom: 16
    }
    this.startDate = moment().subtract(29, 'days').startOf('day').format(DATE_TIME_FORMAT);
    this.endDate =  moment().endOf('day').format(DATE_TIME_FORMAT);
    this.isConsumptionsExist_ = this.isConsumptionsExist;
  }

  ngOnChanges() {
      if(this.mapInitialized) {
        this.addDevicesToMap();
      }
    
  }

  esriMapInitialized(event) {
    // this.addDevicesToMap();
    this.mapInitialized = event;
  }

  async addDevicesToMap() {
    this.loading = true;
    this.todayStart = moment().subtract(29, 'days').startOf('day').format(DATE_TIME_FORMAT);
    this.todayEnd = moment().endOf('day').format(DATE_TIME_FORMAT);
    if(!(this.devices?.length && this.mapAllData?.length)) return;
    const deviceType = this.devices[0]?.deviceType;
    const data = this.mapAllData[0]?.data;
    this.mapInfo = {
      zoom: 9,
      center: data.locations[0].center
    }
    if (this.searchedMapText == "") {
      this.filteredDevices = data.devices;
    }
    if (this.filteredDevices && this.filteredDevices.length > 0) {
      if (this.filteredDevices[0].consumptionFor30Days == undefined && !this.filteredDevices[0].consumptionFor30Days == undefined) {
        this.getConsumptionsOfDevices(data.devices[0].deviceType, data?.consumptionParameter, data?.consumptionUnit);
      }
      this.getTheParametersAndConsumptionData(this.mapAllData[0]?.payload);
      this.addUniqueIdAndOverlappedDeviceDetails();
    }
    this.map.addDevicesToMap(this.filteredDevices, this.mapInfo, deviceType, this.getClusterAndLabelInfos(), this.groupedDevices);
    this.loading = false;
  }

  
  getTheParametersAndConsumptionData(payload) {
    try {
        this.registrationService.getFacilitiesParametersData(payload).subscribe(res => {
          if(!this.meters.includes(this.filteredDevices[0]?.deviceType)) {
            Object.keys(res[this.filteredDevices[0]?.deviceType]).forEach(description => {
              const device = this.filteredDevices.find((meterOrFacility) => meterOrFacility.uplinkReferenceKey === description);
              Object.keys(res[this.filteredDevices[0]?.deviceType][description]).forEach(parameter => device[parameter] = res[this.filteredDevices[0]?.deviceType][description][parameter]);
            })
          }
          else {
            Object.keys(res[this.filteredDevices[0]?.deviceType] || {}).forEach((uplinkReferenceKey) => {
              const device = this.filteredDevices.find((meterOrFacility) => meterOrFacility.uplinkReferenceKey === uplinkReferenceKey);
              if (device && res[this.filteredDevices[0]?.deviceType][uplinkReferenceKey]) {
                const percentageDataFetched = res[this.filteredDevices[0]?.deviceType][uplinkReferenceKey]?.percentageDataFetched;
                if (percentageDataFetched !== undefined) {
                  device.percentageData = percentageDataFetched;
                } 
              } 
            });
          }
          this.map.updateData();
        })
    }
    catch(error) {
      this.loading = false;
    }
  }
  getConsumptionsOfDevices(deviceType, consumptionParameter, consumptionUnit) {
    const payload = {
      dateRange: [this.todayStart, this.todayEnd],
      deviceType: deviceType,
      parameter: consumptionParameter
    }
    this.telemetryService.getAllDevicesConsumptions(payload).subscribe((devicesConsumptions ) => {
        this.mapConsumptionToTheDevicesDetails(devicesConsumptions, consumptionUnit);
    });
  }

  mapConsumptionToTheDevicesDetails(devicesConsumptions, consumptionUnit) {
    this.filteredDevices?.forEach(device => {
      const d = devicesConsumptions?.find(consumptionDetails => consumptionDetails?.deviceID === device?.deviceID)
      device['consumptionFor30Days'] = d?.total ? Number(d.total).toFixed(4) : 0;
      device['consumptionUnit'] = consumptionUnit;
    })
    this.map?.updateData();
  }
  
  addUniqueIdAndOverlappedDeviceDetails() {
    this.groupedDevices = {}
    this.filteredDevices?.forEach((device,idx) => {
      device['oid'] = idx
      const key = `${device.location?.longitude},${device.location?.latitude}`;
      if (!this.groupedDevices[key]) {
        this.groupedDevices[key] = [];
      }
      this.groupedDevices[key].push(device);
    });

  }

  getClusterAndLabelInfos() {
    return {
      showPointLabel : !this.meters?.includes(this.filteredDevices[0]?.deviceType),
      showCluster: this.filteredDevices?.length > 1 ? true : false,
      clusterText: this.filteredDevices[0]?.deviceType,
      hideClusterOnZoom: this.meters?.includes(this.filteredDevices[0]?.deviceType),
      isItMeter: this.meters?.includes(this.filteredDevices[0]?.deviceType)
    }
  }

  getLabelInfos() {
    return {
      showPointLabel : false,
      showCluster: this.filteredDevices?.length > 1 ? true : false,
      clusterText: this.filteredDevices[0]?.deviceType,
      hideClusterOnZoom: true,
      isItMeter: true
    }

  }

  datepicker(value: string[]) {
    const dateArr = value;
    const date = setDateRange(dateArr, false);
    this.startDate = moment(new Date(date.startDate)).format(DATE_TIME_FORMAT);
    this.endDate = moment(new Date(date.endDate)).format(DATE_TIME_FORMAT);
    const payload = {
      dateRange: [this.startDate, this.endDate],
      deviceType: this.devices[0]?.deviceType,
      parameter: this.devices[0]?.deviceType === DEVICE_TYPE_WATER_METER ? "content.VOLUME" : "content.activeEnergyImportTotal"
    };    
    this.getAllDeviceConsumptions(payload);
  }

   getAllDeviceConsumptions(payload) {
    this.loading = true;
    this.displayDevices = false;
    this.isHeatMapRendered = false;
    this.map.removeAllLayer();
    
    const consumptionUnit = this.devices[0]?.deviceType === DEVICE_TYPE_WATER_METER ? 'm³': 'kWh';
    this.telemetryService.getAllDevicesConsumptions(payload).subscribe(devicesConsumptions => {
      this.devices.forEach((device) => {
        const d = devicesConsumptions.find(consumptionDetails => consumptionDetails.deviceID === device.deviceID)
        device['consumptionFor30Days'] = d?.total ? Number(d.total).toFixed(4) : 0;
        device['consumptionUnit'] = consumptionUnit;

      })
      this.map.updateData();
      this.map.addHeatMap(this.devices);
      this.isHeatMapRendered = true;
      this.isConsumptionsExist_ = devicesConsumptions.length > 0;
      this.loading = false;
    })
  }

  handleShowDevicesToggle(event) {
    if(event.checked) {
      this.map.addDevicePointLayer();
    }
    else {
      this.map.removeDevicePointLayer();
    }
  }

  selectMapType(value){
    if(value === "consumption") {
      if(!this.isConsumptionAddedToDevices) this.map.updateData();
      this.map.removeDevicePointLayer();
      this.map.addHeatMap(this.devices);
      this.heatMapEnabled = true;
    }
    else {
      this.map.removeHeatMapLayer();
      this.map.addDevicePointLayer();
      this.heatMapEnabled = false;
    }
  }
  

  public displayDate() {
    return  moment().startOf('month').format('Do MMM') + ' - ' + moment().format('Do MMM');
  }

}
