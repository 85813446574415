import { Component, OnInit, ViewChild } from '@angular/core';
import { ArcgisMapApiService } from 'src/app/modules/arcgis-map/services/arcgis-map-api.service';
import { RegistrationService } from '../services/registration.service';
import { LandingPageEsriMapComponent } from './landing-page-esri-map/landing-page-esri-map.component';
import * as moment from 'moment';
import { DATE_TIME_FORMAT, CONSUMER, INDUSTRIAL, DEVICE_TYPE_WATER_METER, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_COOLING_METER } from '../constant';
import { TelemetryService } from '../services/telemetry.service';
import { MapInfo } from 'src/app/services/arcgis-map/interfaces';


@Component({
  selector: 'app-landing-page-map',
  templateUrl: './landing-page-map.component.html',
  styleUrls: ['./landing-page-map.component.css']
})
export class LandingPageMapComponent implements OnInit {
  @ViewChild(LandingPageEsriMapComponent) map : LandingPageEsriMapComponent;
  accessingFrom="deviceManagement";
  CONSUMER = CONSUMER;
  INDUSTRIAL = INDUSTRIAL;
  consumerList;
  industrialList;
  selectedMeterOrFacility;
  devicesOfSelectedMeterOrFacility = [];
  loading = false
  todayStart;
  todayEnd;
  noConsumptionData = false;
  mapInfo:MapInfo;
  selectedHeatMap = false;
  // This will help to identify is selected meter or facility has consumption and heatMap
  meters = [DEVICE_TYPE_WATER_METER, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_COOLING_METER];
  showHeatMapCheckbox = false;
  showCluster = false;
  isOptionsAddedToMap = false;
  username;
  groupedDevices;
  constructor(
    private arcgisMapApiService: ArcgisMapApiService, 
    private registrationService: RegistrationService,
    private telemetryService: TelemetryService
  ) { }

  ngOnInit(): void {
    this.username = localStorage.getItem("useremail");
    this.getSectorDivisions();
  }

  esriMapInitialized(event) {
    this.map.addOptionsToMap();
    this.handleSelectionOfMeterOrFacility('Water Meter');
  }


  getSectorDivisions() {
    this.loading = true;
    this.registrationService.getSectorDivisions().subscribe(res => {
        this.consumerList = res[CONSUMER];
        this.industrialList = res[INDUSTRIAL];
    })
  }

  optionAddedToMap(isAdded) {
      this.isOptionsAddedToMap = isAdded;
  }

  handleSelectionOfMeterOrFacility(item) {
    this.loading = true;
    this.selectedHeatMap = false;
    this.selectedMeterOrFacility = item;
    this.showCluster = false;
    this.showHeatMapCheckbox = this.meters.includes(this.selectedMeterOrFacility);

    this.todayStart = moment().subtract(29, 'days').startOf('day').format(DATE_TIME_FORMAT);
    this.todayEnd = moment().endOf('day').format(DATE_TIME_FORMAT);
    const dates = [this.todayStart, this.todayEnd];
    const payload = {
      dates,
      divisionTypes: [this.selectedMeterOrFacility],
    }

    this.registrationService.getDevicesLocationDetails(payload, this.username).subscribe((res) => {
      const listOfMeterOrFacilities = res[this.selectedMeterOrFacility];
      if(this.meters.includes(this.selectedMeterOrFacility)) {
        const data = res[this.selectedMeterOrFacility][0];
        this.mapInfo = {
          zoom: 9,
          center: data.locations[0]. center
        }
        this.devicesOfSelectedMeterOrFacility = data.devices;
        this.getConsumptionsOfDevices(data.devices[0].deviceType, data.consumptionParameter, data.consumptionUnit);
      }
      else {
        const center = listOfMeterOrFacilities[0].locations[0]['center'];
        const devices = [];
        this.mapInfo = {
          zoom: this.selectedMeterOrFacility === 'SCADA Systems' ? 8: 10,
          center
        }
        listOfMeterOrFacilities.forEach((meterOrFacility => meterOrFacility.devices.forEach(device => devices.push(device))));
        this.devicesOfSelectedMeterOrFacility = devices;
      }
      this.getTheParametersAndConsumptionData(payload);
      this.addUniqueIdAndOverlappedDeviceDetails();
      this.map.addDevicesToMap(this.devicesOfSelectedMeterOrFacility, this.mapInfo, this.selectedMeterOrFacility, this.getClusterAndLabelInfos(), this.groupedDevices);
      this.loading = false;
    })
  }

  addUniqueIdAndOverlappedDeviceDetails() {
    this.groupedDevices = {}
    this.devicesOfSelectedMeterOrFacility.forEach((device,idx) => {
      device['oid'] = idx
      const key = `${device.location?.longitude},${device.location?.latitude}`;
      if (!this.groupedDevices[key]) {
        this.groupedDevices[key] = [];
      }
      this.groupedDevices[key].push(device);
    });

  }

  getTheParametersAndConsumptionData(payload) {
    try {
        this.registrationService.getFacilitiesParametersData(payload).subscribe(res => {
          if(!this.meters.includes(this.selectedMeterOrFacility)) {
            Object.keys(res[this.selectedMeterOrFacility]).forEach(description => {
              const device = this.devicesOfSelectedMeterOrFacility.find((meterOrFacility) => meterOrFacility.description === description);
              Object.keys(res[this.selectedMeterOrFacility][description]).forEach(parameter => device[parameter] = res[this.selectedMeterOrFacility][description][parameter]);
            })
          }
          else {
            Object.keys(res[this.selectedMeterOrFacility]).forEach((uplinkReferenceKey) => {
              const device = this.devicesOfSelectedMeterOrFacility.find((meterOrFacility) => meterOrFacility.uplinkReferenceKey === uplinkReferenceKey);
              device['percentageData'] = res[this.selectedMeterOrFacility][uplinkReferenceKey].percentageDataFetched
            })
          }
          this.map.updateData();
        })
    }
    catch(error) {

    }
  }
  getClusterAndLabelInfos() {
    return {
      showPointLabel : !this.meters.includes(this.selectedMeterOrFacility),
      showCluster: this.devicesOfSelectedMeterOrFacility.length > 1 ? true : false,
      clusterText: this.selectedMeterOrFacility,
      hideClusterOnZoom: this.meters.includes(this.selectedMeterOrFacility),
      isItMeter: this.meters.includes(this.selectedMeterOrFacility)
    }
  }

  getConsumptionsOfDevices(deviceType, consumptionParameter, consumptionUnit) {
    const payload = {
      dateRange: [this.todayStart, this.todayEnd],
      deviceType: deviceType,
      parameter: consumptionParameter
    }
    this.telemetryService.getAllDevicesConsumptions(payload).subscribe((devicesConsumptions ) => {
        this.mapConsumptionToTheDevicesDetails(devicesConsumptions, consumptionUnit);
    });
  }



  clearSelectedMeterOrFacility() {
    this.selectedMeterOrFacility = "";
    this.devicesOfSelectedMeterOrFacility = [];
    this.map.removeAllLayer();
  }

  mapConsumptionToTheDevicesDetails(devicesConsumptions, consumptionUnit) {
    this.devicesOfSelectedMeterOrFacility.forEach(device => {
      const d = devicesConsumptions.find(consumptionDetails => consumptionDetails.deviceID === device.deviceID)
      device['consumptionFor30Days'] = d?.total ? Number(d.total).toFixed(4) : 0;
      device['consumptionUnit'] = consumptionUnit;
    })
    this.map.updateData();
  }

  handleHeatMapToggle(event) {
    if(event.checked) {
      this.map.addHeatMap(this.devicesOfSelectedMeterOrFacility); 
    }
    else {
      this.map.removeHeatMapLayer();
    }
  }


}
