import * as saveAs from "file-saver";
import { axisBottomTextColor, axisConfig, axisGridColor, axisGridWidth, axisTextColor, dailyFormat, handleWidth, handleWidthVP, hourlyFormat, margin, monthlyFormat, nativeFormat, viewportWidth, weeklyFormat, yearlyFormat } from "./chart-config";
import * as moment from "moment";
import { ALBADA_DEVICE_TYPE, DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START, DUBA_DEVICE_TYPE, PUMP_STATION_DEVICE_TYPE, waterIndustrials } from "../constant";


export const formatAxisConfig = (element, chartConfig, splitYAxis = false) => {
  element.selectAll('.y-axis-grid .tick line')
    .attr('stroke', axisGridColor)
    .attr('stroke-width',axisGridWidth );

  element.selectAll('.y-axis-grid path.domain')
    .style('visibility', 'hidden');

  if (!chartConfig?.axis?.yAxis?.showTick) {
    element.selectAll('.y-axis .tick line')
      .style('visibility', 'hidden')

    element.selectAll('.y-axis path.domain')
    .attr('stroke-width','2px')
    .style('stroke', axisGridColor);
  }
  element.selectAll('.y-axis .tick text')
    .style('color', chartConfig?.axis?.yAxis?.axisTextColor || axisTextColor);

  element.selectAll('.x-axis .tick text')
    .style('color', chartConfig?.axis?.xAxis?.axisTextColor || axisTextColor);

  element.selectAll('.x-axis .tick line')
    .style('stroke', chartConfig?.axis?.xAxis?.axisTextColor || axisTextColor);

  element.selectAll('.x-axis  path.domain')
    .style('stroke', axisGridColor)// chartConfig?.axis?.xAxis?.axisTextColor || axisTextColor

  // element.selectAll('.x-axis path.domain')
  //   .style('visibility', 'hidden')

  if (!chartConfig?.axis?.xAxis?.showTick) {
    element.selectAll('.x-axis .tick line')
      .style('visibility', 'hidden');
  }
  if (splitYAxis) {
    element.selectAll('.x-axis path.domain')
      .style('visibility', 'visible')
    element.selectAll('.x-axis path.domain')
      .style('stroke', chartConfig?.axis?.yAxis?.tickColor || axisGridColor);
  }
}

export const updateAxisConfig = (yAxisGrid, yScale, svg, element = ".y-axis-grid") => {
  yAxisGrid.scale(yScale);
  svg
    .select(element)
    .call(yAxisGrid);
}

export const setChartConfig = (config) => {
  config = {
    axis: {
      yAxis: { ...axisConfig.yAxis, ...config?.axis?.yAxis },
      xAxis: { ...axisConfig.xAxis, ...config?.axis?.xAxis }
    },
    margin: { ...margin, ...config?.margin }
  }
  return config;
}

const roundValue = (n, dp) => {
  const h = +('1'.padEnd(dp + 1, '0')) // 10 or 100 or 1000 or etc
  return Math.round(n * h) / h
}

export const createSubGroups = (attributeList) => {
  const subgroups = [];
  for (const attribute of attributeList) {

    if (typeof (attribute) === "string") {
      subgroups.push({ name: attribute });
    }
    else {
      const group = {
        ...attribute,
      }
      const attr = attribute?.name ? attribute?.name?.split('.') : attribute?.description?.split('.');
      group.displayName = attr[attr.length - 1] || attribute.name;
      group.displayLegend = `${waterIndustrials[attribute.deviceType]?.abbr || ''} ${waterIndustrials[attribute.deviceType]?.deviceId ? attribute?.deviceID || attribute?.deviceId : ''} ${attr[attr.length - 1] || attribute.name}`;
      group.srNo = attribute?.deviceID || attribute?.deviceId;
      if (attribute?.unit === "cubic meter") {
        group.unit = 'm<sup>3</sup>';
      }
      else if (attribute?.unit === "volt") {
        group.unit = 'V';
      }
      else if (attribute?.unit === "cubic meter per hour") {
        group.unit = 'm<sup>3</sup>/h';
      }
      else if (attribute?.unit === "degree") {
        group.unit = '℃';
      }
      subgroups.push(group);
    }
  }
  return subgroups;
}

export const processDataForBarChart = (responseData: any, subgroups: any) => {
  const groups = [];
  const data = [];
  const stackedData = [];
  const aggregatedValue = responseData[0]?.aggregation;
  for (const obj in aggregatedValue) {
    groups.push({ key: obj, date: convertDate(aggregatedValue[obj].dateTime || aggregatedValue[obj]?.dateRange[0]) });
  }
  for (const interval of groups) {
    let attributeObj = {};
    attributeObj['group'] = interval.key;
    attributeObj['date'] = interval.date;
    for (const attribute of subgroups) {
      if (attribute.display || attribute?.display === undefined) {
        const element = responseData.find((x) => x.name === attribute.name || x.description === attribute.name)
        const value = element.aggregation[`${interval.key}`]?.value //|| Math.floor(Math.random() * 100);
        attributeObj[`${attribute.name || attribute.description}`] = {
          value: roundValue(Number(value), 3),
          dateRange: element.aggregation[`${interval.key}`]?.dateRange
        };
        stackedData.push(roundValue(Number(value), 3));
      }
    }
    data.push(attributeObj);
  }
  return {
    groups, data, stackedData
  };
}

export const processDataForLineChart = (responseData: any, subgroups: any, forecast?: string) => {
  const groups = [];
  let data = [];
  const stackedData = [];
  let  aggregatedValue;
  if(forecast == "forecast"){
    aggregatedValue = responseData[1]?.aggregation;

  }else{
    aggregatedValue = responseData[0]?.aggregation;
  }
  for (const obj in aggregatedValue) {
    groups.push({ key: obj, date: convertDate(aggregatedValue[obj].dateTime || aggregatedValue[obj]?.dateRange[0]) });
  }
  responseData = responseData?.filter(res => {
    const rowConfig = subgroups.find(x => x.name === res.name || res.description)
    if (rowConfig.display || rowConfig?.display === undefined) {
      return res
    }
  })
  data = responseData?.map((res: any) => {
    const { name, aggregation, description } = res;
    const row = subgroups?.find(x => x.name === name || description)
    if (row.display || row.display === undefined) {
      const groupObj = {};
      groupObj[`name`] = name || description;
      groupObj[`unit`] = subgroups?.find(x => x.name === name || description)?.unit;
      const values = [];
      for (const key in aggregation) {
        const valueObj = {};
        const { value } = aggregation[key];
        const dataValue = roundValue(Number(value), 3); //|| (Math.floor(Math.random() * 100));
        valueObj['time'] = key;
        valueObj['value'] = dataValue;
        valueObj['date'] = convertDate(aggregation[key].dateTime || aggregation[key].dateRange[0]);
        valueObj['dateRange'] = aggregation[key].dateRange || null;
        values.push(valueObj);
        stackedData.push(dataValue);
      }
      groupObj[`values`] = values;
      return groupObj;
    }

  })
  return {
    groups, data, stackedData
  };
}

export const updateBrushHandle = (context) => {
  let vwhandleWidth = (parseFloat(handleWidthVP) / 100) * viewportWidth;
  context.selectAll(".x_brush .handle")
    .attr('width', vwhandleWidth)
}

export const chartCustomScrollhandler = (context: any, brush: any, ploatFilterHeight: number, svg = null) => {
  let vwhandleWidth = (parseFloat(handleWidthVP) / 100) * viewportWidth;
  const gBrush = context?.append("g")
    .attr("class", "x_brush")
    .style("fill-opacity", 0.2)
    .call(brush);

  gBrush.selectAll(".x_brush .handle")
    .attr('width', vwhandleWidth)

  const handle = gBrush.selectAll(".handle--custom")
    .data([{ type: "w" }, { type: "e" }])
    .enter().append("rect")
    .attr("class", "handle--custom")
    .attr("height", ploatFilterHeight / 2)
    .attr("width", vwhandleWidth * 2)
    .attr("x", (d) => {
      const x = parseFloat(gBrush.select(`.handle--${d.type}`).style('x'));
      return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
    })
    .attr("y", ploatFilterHeight / 4);

  svg.selectAll(".x-axis-filter .tick line").attr('y2', -ploatFilterHeight)
  return handle;
}

export const formatDataAggregation = (responseData, width, x, subgroups) => {
  const dataPoints = [];
  let range0 = x.invert(0)
  let range1 = x.invert(width)

  range0 = responseData[0]?.aggregation[range0]?.dateRange[0];
  range1 = responseData[0]?.aggregation[range1]?.dateRange[0];
  const groups = [];
  const aggregatedValue = responseData[0]?.aggregation;
  for (const obj in aggregatedValue) {
    groups.push({ key: obj, date: convertDate(aggregatedValue[obj].dateTime || aggregatedValue[obj]?.dateRange[0]) });
  }
  for (const interval of groups) {
    for (const attribute of subgroups) {
      if (attribute.display || attribute?.display === undefined) {
        const value = responseData?.find((x) => x.name === attribute.name || x.description === attribute.name)?.aggregation[`${interval.key}`];
        if (value && value?.dateRange[0] >= range0 && value?.dateRange[0] <= range1) {
          dataPoints.push(roundValue(Number(value.value), 3));
        }
      }
    }
  }
  return dataPoints;
}

export const getAxisTickWidth = (svg, x) => {
  const textWidth = svg.select('.axis-bottom .tick text').node().getComputedTextLength();
  const range = x.range()[1];
  const totalTick = range / (textWidth + 10);
  return Math.floor(totalTick) - 2;
}


export const getStartDateEndDate = (date) => {
  if (date !== "Custom Range") {
    const getDate = date.split(" ")
    const endDate = new Date()
    let startDate
    let yearDate = new Date(new Date(new Date().getFullYear(), 0, 2))
    // @ts-ignore
    let days = Math.ceil((new Date() - yearDate) / 86400000);
    // @ts-ignore
    let monthdays = Math.ceil((new Date() - new Date(new Date().getFullYear(), new Date().getMonth(), 2)) / 86400000)

    if (date === "YTD") {
      startDate = moment(startDate).subtract(days, 'days')
    } else if (date === "MTD") {
      startDate = moment(startDate).subtract(monthdays, 'days')
    } else {
      startDate = moment(startDate).subtract(Number(getDate[0]), getDate[1]);
    }

    let dateRang = { startDate: null, endDate: null }
    if (date === "24 hours") {
      dateRang["endDate"] = moment(endDate).format(DATE_TIME_FORMAT);
      dateRang["startDate"] = moment(startDate).format(DATE_TIME_FORMAT);
    }
    else {
      dateRang["startDate"] = moment(startDate).format(DATE_TIME_FORMAT_DAY_START);
      dateRang["endDate"] = moment(endDate).format(DATE_TIME_FORMAT_DAY_END);
    }
    return dateRang
  }

}

export const convertValues = (value) => {
  if (value < 1e3) return value;
  if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(3) + "K";
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(3) + "M";
  if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(3) + "B";
  if (value >= 1e12) return +(value / 1e12).toFixed(3) + "T";
  return value;
}

export const convertValuesAlertManagement = (value) => {
  if (value < 1e3) return value;
  if (value >= 1e3 && value < 1e6) return +(value / 1e3).toFixed(2) + "K";
  if (value >= 1e6 && value < 1e9) return +(value / 1e6).toFixed(2) + "M";
  if (value >= 1e9 && value < 1e12) return +(value / 1e9).toFixed(2) + "B";
  if (value >= 1e12) return +(value / 1e12).toFixed(2) + "T";
  return value;
}

export const downloadData = (responseData, config, forecast?:string) => {
  if (config.aggregationInterval === 'native') {
    let header = ['Timestamp', 'Value', 'Attribute'];
    let csv = [];
    
      if (config.oneDeviceData) {
        const { name, nativeList, description } = responseData[0];
        header = JSON.parse(JSON.stringify(['Time']));
        config.subgroups.forEach((subgroup: any) => {
          let name;
          if (subgroup.deviceType === DUBA_DEVICE_TYPE || subgroup.deviceType === PUMP_STATION_DEVICE_TYPE || subgroup.deviceType === ALBADA_DEVICE_TYPE) {
            name = `${subgroup?.displayName}-${config.aggregationType}`;
          } else {
            name = `${subgroup?.displayName}`;
          }
          header.push(name)
        });
        nativeList?.forEach((nativeData: any, index) => {
          let csvObj = [];
          const dateFormatted = getFormatedDate(typeof (nativeData.dateTime) === "object" ? nativeData.dateTime : convertDate(nativeData.dateTime), config.aggregationInterval);
          csvObj.push(dateFormatted);
          responseData.forEach((dataValue: any) => {
            const value = dataValue?.nativeList[index]?.value ? dataValue?.nativeList[index]?.value : 'null';
            csvObj.push(value);
          })
          csv.push(csvObj);
        });
      }
      else {
        responseData?.forEach((response: any) => {
        const { name, nativeList, description } = response;
        let sortData = nativeList.sort((a, b) => {
          return b.dateTime - a.dateTime;
        })
        sortData?.forEach((nativeData: any) => {
          const csvObj = [];
          const { dateTime, value } = nativeData;
          const timeFormatted = getFormatedDate(typeof (dateTime) === "object" ? dateTime : convertDate(dateTime), config.aggregationInterval);
          csvObj.push(timeFormatted);
          csvObj.push(value);
          csvObj.push(name || description);
          csv.push(csvObj);
         });
        });
      }


    csv = csv?.sort((a, b) => {
      return a[0] - b[0];
    });

    csv.unshift(header.join(','));
    const csconstray = csv.join('\r\n');
    const blob = new Blob([csconstray], { 'type': 'text/csv' });
    saveAs(blob, config.searchTerm);
  } else {
    const header = JSON.parse(JSON.stringify(['Time']));
    if (config.oneDeviceData) {
      config.subgroups.forEach((subgroup: any) => {
        let name;
        if (subgroup.deviceType === DUBA_DEVICE_TYPE || subgroup.deviceType === PUMP_STATION_DEVICE_TYPE || subgroup.deviceType === ALBADA_DEVICE_TYPE) {
          name = `${subgroup?.displayName}-${config.aggregationType}`;
        } else {
          name = `${subgroup?.displayName}-${config.aggregationType}`;
        }
        header.push(name)
      });
    }
    else {
      config.subgroups.forEach((subgroup: any) => {
        let name;
        if (subgroup.deviceType === DUBA_DEVICE_TYPE || subgroup.deviceType === PUMP_STATION_DEVICE_TYPE || subgroup.deviceType === ALBADA_DEVICE_TYPE) {
          name = `${subgroup?.name ? subgroup?.name : subgroup?.deviceType + '.' + subgroup?.displayName}-${config.aggregationType}`;
        } else {
          name = `${subgroup?.deviceType}.${subgroup?.deviceId}.${subgroup?.displayName}-${config.aggregationType}`;
        }
        header.push(name)
      });
    }
    const { groups, data } = processDataForChart(responseData, config.widgetType, config.subgroups, forecast);
    let sortData = groups.sort((a, b) => {
      return b.date - a.date;
    })
    const csv = sortData.map((group: any) => {
      let csvObj = [];
      const dateFormatted = getFormatedDate(group?.date, config.aggregationInterval);
      csvObj.push(dateFormatted);
      responseData.forEach((dataValue: any) => {
        const value = dataValue?.aggregation[group.key]?.value || 0;
        csvObj.push(value);
      })
      return csvObj;
    })
    csv.unshift(header.join(','));
    const csconstray = csv.join('\r\n');
    const blob = new Blob([csconstray], { 'type': 'text/csv' });
    saveAs(blob, config.searchTerm);
  }
  return;
}

export const downloadDataForMinMax = (responseData, config) => {
  const header = JSON.parse(JSON.stringify(['Time']));
  config.subgroups.forEach((subgroup: any) => {
    let name;
    let timestamp;
    if (config.oneDeviceData) {
      if (subgroup.deviceType === DUBA_DEVICE_TYPE || subgroup.deviceType === PUMP_STATION_DEVICE_TYPE || subgroup.deviceType === ALBADA_DEVICE_TYPE) {
        name = `${subgroup?.name ? subgroup?.name : subgroup?.deviceType + '.' + subgroup?.displayName}-${config.aggregationType}`;
        timestamp = `${subgroup?.name ? subgroup?.name : subgroup?.deviceType + '.' + subgroup?.displayName}-timestamp-${config.aggregationType}`;
      } else {
        name = `${subgroup?.displayName}-${config.aggregationType}`;
        timestamp = `${subgroup?.displayName}-timestamp-${config.aggregationType}`;
      }
    }
    else {
      if (subgroup.deviceType === DUBA_DEVICE_TYPE || subgroup.deviceType === PUMP_STATION_DEVICE_TYPE || subgroup.deviceType === ALBADA_DEVICE_TYPE) {
        name = `${subgroup?.name ? subgroup?.name : subgroup?.deviceType + '.' + subgroup?.displayName}-${config.aggregationType}`;
        timestamp = `${subgroup?.name ? subgroup?.name : subgroup?.deviceType + '.' + subgroup?.displayName}-timestamp-${config.aggregationType}`;
      } else {
        name = `${subgroup?.deviceType}.${subgroup?.deviceId}.${subgroup?.displayName}-${config.aggregationType}`;
        timestamp = `${subgroup?.deviceType}.${subgroup?.deviceId}.${subgroup?.displayName}-timestamp-${config.aggregationType}`;
      }
    }
    header.push(name)
    header.push(timestamp)
  });

  const { groups, data } = processDataForChart(responseData, config.widgetType, config.subgroups);
  const csv = groups.map((group: any) => {
    let csvObj = [];
    const dateFormatted = getFormatedDate(group?.date, config.aggregationInterval);
    csvObj.push(dateFormatted);
    responseData.forEach((dataValue: any) => {
      const value = dataValue?.aggregation[group.key]?.value || 0;
      csvObj.push(value);
      const timestamp = moment(dataValue?.aggregation[group.key]?.timestamp).format('DD/MM/YYYY HH:mm:ss ');
      csvObj.push(timestamp);
    })
    return csvObj;
  })
  csv.unshift(header.join(','));
  const csconstray = csv.join('\r\n');
  const blob = new Blob([csconstray], { 'type': 'text/csv' });
  saveAs(blob, config.searchTerm);
}

export const getTimeStamp = (data, config, element) => {
  const payload =
  {
    "attributes": [
      {
        "deviceId": element.deviceId,
        "deviceType": element.deviceType,
        "path": element.path,
        "value": data.value.toString()
      }
    ],
    "aggregationInterval": config.aggregationInterval,
    "aggregationType": config.aggregationType,
    "dateRange": [data.dateRange[0], data.dateRange[1]]
  }

  const promise = new Promise(async function (resolve, reject) {
    config.elasticSearchservice.getTimestamp(payload).subscribe((data: any) => {
      resolve(data);
    })
  });
  return promise
}

export const hideTooltip = (svg, tooltip) => {
  svg.selectAll('.circle-tooltip')
    .style('opacity', 0)

  tooltip
    .style("opacity", 0)

  svg.selectAll('.line-tooltip')
    .style('opacity', 0)
}

export const processDataForChart = (responseData, widgetType, subgroups, forecast?:string) => {
  let data;
  if (widgetType === "linechart" || widgetType === "areachart") {
    data = processDataForLineChart(responseData, subgroups, forecast)
  }
  else {
    data = processDataForBarChart(responseData, subgroups)
  }
  return data;
}

export const formatData = (data, range) => {
  /* Format Data */
  const dateList = [];
  const dataPoints = [];
  data.forEach(function (d) {
    d.nativeList.forEach(function (d) {
      if (d.dateTime >= range[0] && d.dateTime <= range[1]) {
        dateList.push(d.dateTime);
        dataPoints.push(parseFloat(d.value));
      }
    });
  });
  return dataPoints;
}

export const formatDataForDevice = (data, range) => {
  /* Format Data */
  const allDataPoints = [];
  let dataPoints = [];
  data.nativeList.forEach(function (d) {
    allDataPoints.push(parseFloat(d.value))
    if (d.dateTime >= range[0] && d.dateTime <= range[1]) {
      dataPoints.push(parseFloat(d.value));
    }
  });
  return { allDataPoints, dataPoints };
}

export const convertDate = (date, timeZone = 'Asia/Riyadh') => {
  // const _date = new Date(
  //   (typeof date === 'string' ? new Date(date) : date).toLocaleString(
  //     'en-US',
  //     { timeZone: timeZone }
  //   )
  // );
  // return _date;
  return typeof date === 'string' ? new Date(date) : date
}

export const getFormatedDate = (date, aggregationInterval) => {
  if (aggregationInterval === "monthly") {
    return monthlyFormat(date);
  }
  else if (aggregationInterval === "weekly") {
    return weeklyFormat(date);
  }
  else if (aggregationInterval === 'native') {
    return nativeFormat(date);
  }
  else if (aggregationInterval === 'hourly') {
    return hourlyFormat(date);
  }
  else if (aggregationInterval === 'yearly') {
    return yearlyFormat(date);
  }
  else if (aggregationInterval === 'quarter_hourly') {
    return hourlyFormat(date);
  }
  else {
    return dailyFormat(date);
  }
}

export const getFilterPosition = (responseData, x) => {
  // if ((Object.keys(responseData[0].aggregation)).length > 50) {
  //   const xData = x((Object.keys(responseData[0].aggregation))[(Object.keys(responseData[0].aggregation)).length - 50]);
  //   return [xData, x.range()[1]];
  // }
  return x.range();
}

export const getFilterPositionNative = (responseData, x) => {
  // if (Object.keys(responseData[0].nativeList).length > 300) {
  //   const xData = x(responseData[0].nativeList[responseData[0].nativeList.length - 300].dateTime);
  //   return [xData, x.range()[1]];
  // }
  return x.range();
}