export const statusList = {
    'pending': { show: false, icon: 'downloading', text: '', showRipple:true},
    'requested': { show: true, icon: 'downloading', text: 'File is being prepared for download', showRipple:true},
    'success': {show: true, icon: 'download_for_offline', text: "File is ready for download", showRipple:false},
    'none': {show: false, icon: '', text: '', showRipple:false}
  }
export const FILES_IN_PROGRESS = 'Files In Progress';
export const READY_FILES = 'Ready Files';
export const POLLING_STATUS_RUNNING = 'Running';
export const POLLING_STATUS_STOPPED = 'Stopped';
export const SUCCESS = 'success';
export const REQUESTED = 'requested'
export const PENDING = 'pending';
