<div class="popup-Header">  
    <h2 class="popup-Title">Save Project</h2>
    <div mat-dialog-close>
        <img class="popup-CloseImg">
    </div>
</div>

<mat-dialog-content class="mat-typography">
    <div *ngIf="data.type!=='SaveDrawing'" class="form-group labelSpace">
        <span class="label-text" for="name">Widget Name</span>
        <input type="text" class="input-text input-area form-control" [(ngModel)]="widget.name" name="widget.name">
    </div>
    <div class="form-group">
        <span class="label-text" for="name">Dashboard Name</span>
        <div class="example-full-width input-area input-text">
            <input [(ngModel)]="widget.dashboard" placeholder="Enter a dashboard name or select an existing dashboard" matInput  [matAutocomplete]="auto"
                [formControl]="dashboardCtrl">
                <mat-autocomplete  #auto="matAutocomplete" panelClass="savePanel">
                        <ng-container *ngIf="!data.type">
                            <mat-option *ngFor="let dashboard of filteredDashboard | async" [value]="dashboard.name">
                                <span>{{dashboard.name}}</span>
                            </mat-option>
                        </ng-container>
                            <mat-option  *ngIf="!data.type && isNewDashboardAllow" [value]="dashboardCtrl.value">
                                <img alt="" class="option-img" src="assets/images/add.png" height="25" />
                                <span class="">Create 
                                </span> <span>{{dashboardCtrl.value}}</span>
                            </mat-option>
                    </mat-autocomplete>
            <!-- <p *ngIf="data.type==='SaveDrawing'">Can't save to an existing</p> -->
        </div>
    </div>
</mat-dialog-content>
<section class="saveBtn">
    <button  [disabled]="dashboardCtrl.value === ''" (click)="saveProject()" class="button-Primary-N btnHeight">Save</button>
    <button mat-dialog-close class="button-Tertiary-N cancelBtn">Cancel</button>
</section>