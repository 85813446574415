// device registration dropdown options

const serviceTypeOptions = {
    "Water Meter": [
        "Potable water",
        "Mineralized water",
        "Hot Water",
        "Recycled water"
    ],
    "Cooling Meter": [
        "Chilled Water BTU Meter",
        "Low Temperature BTU Meter"
    ],
    "Energy Meter": [
        "Energy"
    ]
};

const customerMeterTypeOptions = ['Main Meter', 'Sub-Meter'];

const customerTypeOptions = ['Residential', 'Commercial', 'Industrial', 'Government'];

const waterDiameterOptions = [15, 20, 25, 32, 40, 50, 65, 80, 100, 150, 200];

const billableOptions = [{
    label: 'Yes',
    value: true
}, {
    label: 'No',
    value: false
}]

const clusterOptions = ['Cluster 1', 'Cluster 2', 'Cluster 3', 'Cluster 4', 'Cluster 5', 'Cluster 6'];

const regionOptions = ['Sindalah Island', 'Neom Community 1', 'Neom Community 2', 'ISKRA Dubai Lab', 'Sample Device', 'Test Site', 'Oxagon', 'Tojena', 'Magna'];

const energyBreakerCapacityOptions = ['100 AMP', '100*3 AMP', '200/5', '400/5', '800/5', '1600/5'];

export const dropdownOptions = {
    customerType: customerTypeOptions,
    cluster: clusterOptions,
    energyBreakerCapacity: energyBreakerCapacityOptions,
    site: regionOptions,
    isBillable: billableOptions,
    waterDiameter: waterDiameterOptions,
    customerMeterType: customerMeterTypeOptions,
    serviceType: serviceTypeOptions
}

export const errorMessages = {
    'required': 'This field is required.',
    'pattern': 'Invalid Input.',
    'error': 'Failure in registering the device. Please try again'
};

export const alphaNumericPattern = "^[a-zA-Z0-9.@_ -]*$";
export const ipAddressPattern = '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
export const numericPattern = '^\\d{1,4}$';

export const validationPatterns = {
    alphaNumeric: alphaNumericPattern,
    numeric: numericPattern,
    ipAddress: ipAddressPattern
}

export const DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const deviceOnboardingMessages = {
    manufacturingDateValidation: 'Manufacturing date cannot be after commissioning date'
}

export const fieldToDisableOnEdit = ['useGateway', 'ipAddress', 'port', 'meterPassword', 'gatewayName'];

export const validationKeys = {
    water: ['gatewayName'],
    energy: ['ipAddress', 'port', 'meterPassword']
}

// bulk registration

export const bulkRegistrationCsvHeaders = {
    commonHeader: ['Service Point ID', 'Parent Meter Service Point ID', 'Serial Number *', 'Utility Service Type', 'Customer Meter Type', 'Customer Type', 'Region', 'Location Group', 'Measurable Location', 'Customer Description', 'Building Number', 'Latitude *', 'Longitude *', 'Commissioning Date (DD-MM-YYYY)*', 'Is Gateway(True/false)', 'Batch', 'Manufacturing Date(DD-MM-YYYY)', 'Firmware Version', 'Is Billable(Yes/No)'],
    waterMeterHeader: ['Gateway Name *', 'Meter Diameter'],
    coolingMeterHeader: ['Gateway Name *'],
    energyMeterHeader: ['IP Address *', 'Port *', 'Meter Password *', 'Energy Breaker Capacity']
}


export const bulkRegistrationMessages = {
    success: "Device Registered Successfully",
    failure: 'Failure in registering the devices. Please try again.',
    partialFailure: 'Partial registry of the device list successful. Failed to register some of the devices. Please try registering them again.',
    unsupportedFormat: 'The uploaded CSV format is not supported. Please try again',
    noValidData: 'The uploaded CSV format is not supported. Please try again',
    profileValidation: "Select a Profile",
    fileValidation: "Selected file is not a csv file",
    mantatoryFieldValidation: "Please select all mandatory fields"
}

export const DATE_FORMAT = 'DD-MM-YYYY';