import { v4 as uuidv4 } from 'uuid';
import { lineGraphForAggregationSplitYAxis } from "./line-graph-aggregation-split-yaxis";
import { tooltipLineColor, tooltipLineDasharray, tooltipLineWidth, filterScrollTextPadding, lineWidth, lineOpacity, handleWidth, tooltipCircleRadius, tooltipCircleStrokeWidth, tooltipCircleStrokeOpacity, areaOpacity, COLOR, viewportWidth, viewportHeight, VPmargin, handleWidthVP, axisBottomTextColor, tickSize } from "./chart-config";
import { chartCustomScrollhandler, convertDate, convertValues, createSubGroups, formatAxisConfig, formatDataAggregation, getAxisTickWidth, getFilterPosition, getFormatedDate, getTimeStamp, hideTooltip, processDataForChart, setChartConfig, updateAxisConfig, updateBrushHandle } from "./chart-helper";
import * as d3 from 'd3';
import * as moment from 'moment';
import { DAILY, DATE_FORMAT_FOR_DAILY_AGGREGATION, DATE_FORMAT_FOR_MONTHLY_AGGREGATION, DATE_FORMAT_FOR_YEARLY_AGGREGATION, EN_GB_LOCAL_STRING, MONTHLY, WEEKLY, YEARLY } from '../constant';

export const renderChart = (element, widgetData: any[], config) => {

  if (config.splitYAxis && config.widgetType === "linechart") {
    lineGraphForAggregationSplitYAxis(element, widgetData, config);
  }
  else {
    let subgroups;
    const mainElement = d3.select(element);
    const brush = d3.brushX();
    let x, xSubgroup, y, yAxisGrid, color, _x;
    let handle, context, zoom, line, area;
    if (typeof (config.attributes) === "string") {
      subgroups = config.attributes;
    }
    else {
      subgroups = createSubGroups(config.attributes);
    }

    // let width = mainElement.node()["clientWidth"];
    // let height = mainElement.node()["clientHeight"];

    let width = config.width ? config.width : mainElement.node()["clientWidth"];
    let height = config.height ? config.height : mainElement.node()["clientHeight"];
    let vwheight = (parseFloat(VPmargin.ploatfilterheight) / 100) * viewportWidth;
    let vhmarginleft = (parseFloat(VPmargin.yAxisMarginleft) / 100) * viewportHeight;
    let vwmarginTop = (parseFloat(VPmargin.yAxisMarginTop) / 100) * viewportWidth;
    let vwhandleWidth = (parseFloat(handleWidthVP) / 100) * viewportWidth;
    let vwmarginBottom = (parseFloat(VPmargin.xAxisMarginBottom) / 100) * viewportWidth;
    let ploatFilterHeight = config.ploatFilterHeight || vwheight
    const reactData = [];
    const guid = uuidv4();
    if (config.showScrollbar) {
      height = height - ploatFilterHeight;
    }
    if (config.removeExisting) {
      d3.selectAll(`${element} svg`).remove();
      d3.selectAll(`${element} .chart-tooltip`).remove();
    }
    config.chartConfig = setChartConfig(config.chartConfig);

    const svg = mainElement.append("svg")
      .attr("class", 'chart-plot')
      .attr("width", width)
      .attr("height", height)

    const main = svg.append('g')
      .attr("transform", `translate(${vhmarginleft},${vwmarginTop})`);

    width = width - vhmarginleft - config.chartConfig.margin.right;
    height = height - vwmarginTop - vwmarginBottom;

    main.append("defs").append("clipPath")
      .attr("id", `clip-${guid}`)
      .append("rect")
      .attr("x", 0)
      .attr("width", width)
      .attr("height", height);

    const tooltip = d3.select(element)
      .append("div")
      .classed('chart-tooltip', true);

    const _data = processDataForChart(widgetData, config.widgetType, subgroups)
    const groups = _data.groups;
    const data = _data.data;
    const stackedData = _data.stackedData;


    let ploatFiltersvg = d3.select(element).select('.filter-plot');
    const formatXAxisFilter = () => {
      ploatFiltersvg.selectAll(".x-axis-filter").call(d3.axisTop(_x)
        .tickFormat((d, i) => {
          const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data[i].date;
          return getFormatedDate(tickFormat, config.aggregationInterval);
        })
      );

      const totalTick = getAxisTickWidth(svg, x);
      let selectPoint = Math.floor(_x.domain().length / totalTick);
      selectPoint = selectPoint === 1 ? 2 : selectPoint;

      ploatFiltersvg.selectAll(".x-axis-filter").call(d3.axisTop(_x)
        .tickFormat((d, i) => {
          if (i % selectPoint === 0 || selectPoint === 0) {
            const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data[i].date;
            return getFormatedDate(tickFormat, config.aggregationInterval);
          }
          else {
            return '';
          }
        })
      );
    }

    const formatXAxis = () => {

      svg.selectAll(".x-axis").call(d3.axisBottom(x)
        .tickFormat((d, i) => {
          const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data[i].date;
          return getFormatedDate(tickFormat, config.aggregationInterval);
        })
      );

      const totalTick = getAxisTickWidth(svg, x);
      let selectPoint = Math.floor(x.domain().length / totalTick);
      selectPoint = selectPoint === 1 ? 2 : selectPoint;

      svg.selectAll(".x-axis").call(d3.axisBottom(x)
        .tickFormat((d, i) => {
          if (i % selectPoint === 0 || selectPoint === 0) {
            const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data[i].date;
            return getFormatedDate(tickFormat, config.aggregationInterval);
          }
          else {
            return '';
          }
        })
      );

      svg.selectAll(".x-axis .tick line").each(function (d, i) {
        if (i % selectPoint === 0 || selectPoint === 0) {
          d3.select(this).style('opacity', 1)
        }
        else {
          d3.select(this).style('opacity', 0)
        }
      });

      //svg.select('.y-axis-grid .tick line').attr("visibility", "hidden")
    }
    setChartAxis();

    const alertRangeElement = main.append("g").attr("id", "alertRange").attr("clip-path", `url(#clip-${guid})`);
    alertRangeElement.append("rect").attr("id", "react-alertRange");;
    alertRangeElement.append("line").attr("id", "line-alertRange");

    const operationalRange = () => {
      if (config.operationalRange && config.operationalRange.minValue && config.operationalRange.maxValue) {

        if (config.operationalRange && y.domain()[0] > config.operationalRange.minValue) {
          y.domain([config.operationalRange.minValue, y.domain()[1]]).nice();
        }

        if (config.operationalRange && y.domain()[1] < config.operationalRange.maxValue) {
          y.domain([y.domain()[0], config.operationalRange.maxValue]).nice();
        }

        const pointMin = y(config.operationalRange.minValue);
        const pointMax = y(config.operationalRange.maxValue);

        alertRangeElement.select("#react-alertRange")
          .attr("fill", "#1f77b4")
          .attr("opacity", 0.1)
          .attr("x", 0)
          .attr("y", pointMax)
          .attr("width", width)
          .attr("height", pointMin - pointMax)

        alertRangeElement.selectAll(".targetgoal").remove();
        const target = alertRangeElement.selectAll(".targetgoal")
          .data([config.operationalRange.minValue, config.operationalRange.maxValue])
          .enter()
          .append("g")
          .attr("transform", function (d) {
            return "translate(0, " + y(d) + ")"
          })

        target.append("line")
          .attr("class", "targetgoal")
          .attr("x1", 0)
          .attr("x2", width)
          .attr("y1", 0)  //these can be omitted
          .attr("y2", 0)
          .style("stroke-width", 1)
          .style("stroke", "#1f77b4");
      }

    }

    operationalRange();

    const plotFilter = () => {

      ploatFiltersvg = d3.select(element)
        .append("svg")
        .attr("class", 'filter-plot')
        .attr("width", width + vhmarginleft + config.chartConfig.margin.right)
        .attr("height", ploatFilterHeight)

      _x = d3.scaleBand()
        .range([0, width])
        .padding(0.2)
        .domain(groups.map(x => x.key));

      const maxValue = d3.max(stackedData) || 10;
      const minValue = d3.min(stackedData) || 0;
      const filterMain = ploatFiltersvg.append('g')
        .attr("transform", `translate(${vhmarginleft},${0})`);

      const _y = d3.scaleLinear()
        .range([ploatFilterHeight, 0])
        .domain([+minValue, +maxValue]);

      const xAxisFilter = d3.axisTop(_x).tickSize(0).tickPadding(filterScrollTextPadding);

      if (config.widgetType === "linechart" || config.widgetType === "areachart") {

        brush.extent([[0, 0], [width, ploatFilterHeight]])
          .on("brush", (d) => {
            if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom

            const extentX = d3.event.selection;
            const selected = _x
              .domain()
              .filter(d => (extentX[0] - _x.bandwidth() + 1e-2 <= _x(d)) && (_x(d) <= extentX[1] - 1e-2));

            x.domain(selected)
              .range([0, width]);

            svg.selectAll(".x-axis").call(d3.axisBottom(x)
              .tickFormat((d, i) => {
                const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data.find(x => x.group === d).date;
                return getFormatedDate(tickFormat, config.aggregationInterval);
              })
            );

            formatXAxis();

            const point = formatDataAggregation(widgetData, width, x, subgroups);
            y.domain([d3.min(point), d3.max(point)]).nice();

            svg.selectAll(".line-group .line").attr('d', (d: any) => line(d.values))
            if (config.widgetType === "areachart") {
              svg.selectAll(".area-group .area").attr('d', (d: any) => area(d.values))
            }

            svg.selectAll(".y-axis").call(d3.axisLeft(y).ticks(tickSize)
              .tickFormat((d: any) => {
                return convertValues(d);
              })
            )

            svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
              .scale(width / (extentX[1] - extentX[0]))
              .translate(-extentX[0], 0));

            updateAxisConfig(yAxisGrid, y, svg, '.y-axis-grid')
            formatAxisConfig(svg, config.chartConfig);
            operationalRange();
            updateBrushHandle(context);
            handle.attr("x", (d) => {
              const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
              return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
            })
          });

        const filterLinePoitnt = d3.line()
          .x((d: any) => _x(d.time) + x.bandwidth() / 2)
          .y((d: any) => _y(d.value));

        const filterLine = filterMain.append('g')
          .attr('class', 'filter-lines');

        filterLine.selectAll('.filter-line-group')
          .data(data).enter()
          .append('g')
          .attr('class', 'filter-line-group')
          .append('path')
          .attr('class', 'filter-lines')
          .attr('d', (d: any) => filterLinePoitnt(d.values))
          .style('stroke', (d: any, i: any) => color(d.name || d.description))
          .attr('stroke-width', lineWidth)
          .style('opacity', lineOpacity)
          .style('fill', 'none');

        filterLine.append("g")
          .attr("class", "x-axis-filter")
          .attr("transform", `translate(0, ${ploatFilterHeight})`)
          .call(xAxisFilter);

        formatXAxisFilter();

        context = filterMain.append("g")
          .attr("class", "context")
          .attr('transform', 'translate(' + 0 + ', ' + 0 + ')');

        handle = chartCustomScrollhandler(context, brush, ploatFilterHeight, ploatFiltersvg);
        ploatFiltersvg.select('.x_brush').call(brush.move, getFilterPosition(widgetData, _x));
      }
      else {

        filterMain.append("g")
          .classed('chart-main-filter', true)
          .selectAll("g")
          .data(data)
          .join("g")
          .attr("transform", (d: any) => `translate(${x(d.group)}, 0)`)
          .selectAll("rect")
          .data(reactData)
          .join("rect")
          .attr("x", (d: any) => {
            return xSubgroup(d.key.name)
          })
          .attr("y", (d: any) => {
            return _y(d.value.value)
          })
          .attr("width", xSubgroup.bandwidth())
          .attr("height", (d: any) => {
            return ploatFilterHeight - _y(d.value.value)
          })
          .attr("fill", (d: any) => color(d.key.name || d.key.description));

        filterMain.append("g")
          .attr("class", "x-axis-filter")
          .attr("transform", `translate(0, ${ploatFilterHeight})`)
          .call(xAxisFilter);

        formatXAxisFilter()

        brush.extent([[0, 0], [width, ploatFilterHeight]])
          .on("brush", (d) => {
            // if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom

            const extentX = d3.event.selection;
            const selected = _x
              .domain()
              .filter(d => (extentX[0] - _x.bandwidth() + 1e-2 <= _x(d)) && (_x(d) <= extentX[1] - 1e-2));

            x.domain(selected)
              .range([0, width])
            xSubgroup.range([0, x.bandwidth()])

            svg.selectAll(".x-axis").call(d3.axisBottom(x)
              .tickFormat((d, i) => {
                const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data.find(x => x.group === d).date;
                return getFormatedDate(tickFormat, config.aggregationInterval);
              })
            );
            formatXAxis();

            const point = formatDataAggregation(widgetData, width, x, subgroups);
            y.domain([d3.min(point), d3.max(point)]).nice();

            svg.selectAll(".group-ract-main")
              .attr("transform", (d: any) => {
                return `translate(${Math.abs(x(d.group)) || 0}, 0)`;
              })
              .style("opacity", function (d: any) {
                return x.domain().indexOf(d.group) === -1 ? 0 : 100;
              })
              .selectAll("rect")
              .attr("width", xSubgroup.bandwidth())
              .attr("height", (d: any) => {
                return height - y(d.value.value)
              })
              .attr("x", (d: any) => {
                return xSubgroup(d.key.name)
              })
              .attr("y", (d: any) => {
                return y(d.value.value)
              })
              .attr("fill", (d: any) => color(d.key.name || d.key.description))

            svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
              .scale(width / (extentX[1] - extentX[0]))
              .translate(-extentX[0], 0));

            svg.selectAll(".y-axis").call(d3.axisLeft(y).ticks(tickSize)
              .tickFormat((d: any) => {
                return convertValues(d);
              })
            )

            updateAxisConfig(yAxisGrid, y, svg, '.y-axis-grid')
            formatAxisConfig(svg, config.chartConfig);

            updateBrushHandle(context);
            handle.attr("x", (d) => {
              const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
              return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
            })
          });

        context = filterMain.append("g")
          .attr("class", "context")
          .attr('transform', 'translate(' + 0 + ', ' + 0 + ')');

        handle = chartCustomScrollhandler(context, brush, ploatFilterHeight, ploatFiltersvg);
        ploatFiltersvg.select('.x_brush').call(brush.move, getFilterPosition(widgetData, _x));
      }
    }

    const createLineChart = () => {

      line = d3.line()
        .x((d: any) => {
          return (x(d.time)) + x.bandwidth() / 2
        })
        .y((d: any) => y(d.value))
        .defined((d: any, i) => {
          return x(d.time) !== undefined;
        });

      area = d3.area()
        .x((d: any) => {
          return (x(d.time)) + x.bandwidth() / 2
        })
        .y0(height)
        .y1((d: any) => y(d.value))
        .defined((d: any, i) => {
          return x(d.time) !== undefined;
        });

      const title = main.append('text')
        .attr('class', 'title-text')
        .style('visibility', 'hidden')

      const lines = main.append('g')
        .attr('class', 'lines');

      const areas = main.append('g')
        .attr('class', 'areas');

      if (config.widgetType === 'areachart') {

        const gradient = main.append('g')
          .attr('class', 'gradient');

        //Creating the gradient-color for the area  
        gradient.selectAll('.gradient-group')
          .data(data).enter()
          .append('g')
          .attr('class', 'gradient-group')
          .append('linearGradient')
          .attr("id", (d, i) => `gradient-${i}-${guid}`)
          .attr('x1', '0%')
          .attr('y1', '100%')
          .attr('x2', '0%')
          .attr('y2', '0%')
          .selectAll("stop")
          .data((d: any, i) => {
            return [{
              offset: "0%",
              color: color(d.name || d.description),
              opacity: 0.05
            },
            {
              offset: "100%",
              color: color(d.name || d.description),
              opacity: 0.5
            }]
          })
          .enter().append("stop")
          .attr("offset", function (d) {
            return d.offset;
          })
          .attr("stop-opacity", function (d) {
            return d.opacity;
          })
          .attr("stop-color", function (d) {
            return d.color;
          });
        //Creating a path for area-gradient
        areas.selectAll('.area-group')
          .data(data).enter()
          .append('g')
          .attr('class', 'area-group')
          .append('path')
          .attr('class', 'area')
          .attr('fill', (d: any, i: any) => `url(#gradient-${i}-${guid})`)
          .attr("clip-path", `url(#clip-${guid})`)
          .attr('d', (d: any) => area(d.values))
          .style('opacity', areaOpacity)
      }

      lines.selectAll('.line-group')
        .data(data).enter()
        .append('g')
        .attr('class', 'line-group')
        .append('path')
        .attr('class', 'line')
        .attr("clip-path", `url(#clip-${guid})`)
        .attr('d', (d: any) => line(d.values))
        .style('stroke', (d: any, i: any) => color(d.name || d.description))
        .attr('stroke-width', lineWidth)
        .style('opacity', lineOpacity)
        .style('fill', 'none')
        .on("mouseover", (d: any) => {
          title.text(d.name)
            .style('fill', color(d.name))
            .style('visibility', 'visible ')
            .attr("transform", `translate(${(width / 2 - title.node().getComputedTextLength() / 2)},${vwmarginTop})`)
        })
        .on("mousemove", (d: any) => {
          title.text(d.name)
            .style('fill', color(d.name))
            .style('visibility', 'visible ')
            .attr("transform", `translate(${(width / 2 - title.node().getComputedTextLength() / 2)},${vwmarginTop})`)
        })
        .on("mouseout", (d: any) => {
          title.text(d.name).text('')
        });

      main.selectAll('.circle-tooltip')
        .data(data).enter()
        .append("circle")
        .attr('class', (d: any, i: any) => `circle-tooltip circle-tooltip-${i}`)
        .attr("r", tooltipCircleRadius)
        .style('fill', (d: any, i: any) => color(d.name || d.description))
        .style('stroke', (d: any, i: any) => color(d.name || d.description))
        .style('opacity', 0)
        .style('stroke-width', tooltipCircleStrokeWidth)
        .style('stroke-opacity', tooltipCircleStrokeOpacity)

      zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]])
        .on("zoom", (d) => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
          hideTooltip(svg, tooltip)
          const t = d3.event.transform;
          x.range([0, width].map(d => {
            return d3.event.transform.applyX(d)
          }));
          x.domain(_x.domain());
          svg.selectAll(".x-axis").call(d3.axisBottom(x).tickFormat((d, i) => {
            const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data[i].date;
            return getFormatedDate(tickFormat, config.aggregationInterval);
          }));

          formatXAxis();

          const point = formatDataAggregation(widgetData, width, x, subgroups);
          y.domain([d3.min(point), d3.max(point)]).nice();

          svg.selectAll(".line-group .line").attr('d', (d: any) => line(d.values))
          if (config.widgetType === "areachart") {
            svg.selectAll(".area-group .area").attr('d', (d: any) => area(d.values))
          }

          svg.selectAll(".y-axis").call(d3.axisLeft(y).ticks(tickSize)
            .tickFormat((d: any) => {
              return convertValues(d);
            })
          )
          ploatFiltersvg.select(".x_brush").call(brush.move, _x.range().map(t.invertX, t));
          updateAxisConfig(yAxisGrid, y, svg, '.y-axis-grid')
          formatAxisConfig(svg, config.chartConfig);

          operationalRange();
          updateBrushHandle(context);
          handle.attr("x", (d) => {
            const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
            return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
          })
        });

      const tipMouseMove = () => {
        let toolTipText = '';
        const x0: any = x.invert(d3.event.offsetX - vhmarginleft);
        let d = data[0]?.values.findIndex(x => x.time === x0);
        if (config.aggregationType === "min" || config.aggregationType === "max") {
          toolTipText = `<p>${data[0]?.values[d].date.toLocaleString('en-GB')} </p>`;
          data.forEach((element, index) => {
            const device = config.attributes.find(x => x.name === element.name || x.description === element.name)
            if (element.values[d].timestamp) {
              toolTipText += `<p><span class='legend-box'  style='background-color:${color(element.name)}'></span> <span>${' '}${element.name} : ${element.values[d]?.value?.toFixed(3)}, </span> <span id='timestamp_${index}'>${element.values[d].timestamp.toLocaleString('en-GB')}</span> </p>`
            }
            else {
              toolTipText += `<div> <span class='legend-box'  style='background-color:${color(element.name)}'></span> <span>${' '}${element.name} : ${element.values[d]?.value?.toFixed(3)}, </span><span id='timestamp_${index}'> <div class="loader"></div></span></div>`
              if (!element.values[d].isLoading) {
                element.values[d].isLoading = true;
                getTimeStamp(element.values[d], config, device).then((res: any) => {
                  element.values[d].timestamp = convertDate(res.data);
                  const currentPosition = parseFloat(main.select('.line-tooltip').attr("x1")).toFixed(3)
                  const requestPosition = (x(data[0]?.values[d].time) + x.bandwidth() / 2).toFixed(3)
                  console.log(currentPosition, requestPosition);
                  if (currentPosition === requestPosition) {
                    tooltip.select(`#timestamp_${index}`).html(element.values[d].timestamp.toLocaleString('en-GB'));
                  }
                })
              }
            }
          });
        }
        else {
          let weekDayNumber = moment(data[0]?.values[d]?.date).week();
          // Creating tooltip according to the aggregation
          toolTipText = '' + getToolTipContent(config, data[0]?.values[d]?.date, weekDayNumber);

          data.forEach(element => {
              toolTipText += `<p><span class='legend-box' style='background-color:${color(element?.name)}'></span>${' '} ${element?.name} : ${element?.values[d]?.value?.toFixed(3)}</p>`
          });
        }

        main.selectAll('.line-tooltip')
          .attr("x1", x(data[0]?.values[d]?.time) + x.bandwidth() / 2)
          .attr("y1", 0)
          .attr("x2", x(data[0]?.values[d]?.time) + x.bandwidth() / 2)
          .attr("y2", height)
          .style('opacity', 1);

        main.selectAll(`.circle-tooltip`)
          .attr("cx", (_d, i) => {
            return x(data[0]?.values[d].time) + x.bandwidth() / 2
          })
          .attr("cy", (_d: any, i) => {
            return y(data[i].values[d]?.value || 0)
          })
          .style("opacity", (_d: any, i) => {
            if (y(data[i].values[d]?.value)) {
              return 1;
            }
            return 0;
          })

        tooltip
          .style("opacity", 0.89)
          .html(toolTipText)

        tooltip.node().style.right = 'auto';
        tooltip.node().style.top = 'auto';
        tooltip.node().style.left = 'auto';

        let width = svg.node().clientWidth;

        let tipWidth = tooltip.node().offsetWidth;
        let tipHeight = tooltip.node().offsetHeight;

        if (width - (x(data[0]?.values[d].time) + x.bandwidth() / 2) < tipWidth) {
          tooltip.node().style.right = (width - (x(data[0]?.values[d].time) + x.bandwidth() / 2)) - vhmarginleft + 10 + 'px';
          tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
          tooltip.node().style.left = 'auto';
          tooltip.classed('chart-tooltip-right', true);
          tooltip.classed('chart-tooltip-left', false);
        }
        else {
          tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
          tooltip.node().style.left = (x(data[0]?.values[d].time) + x.bandwidth() / 2) + vhmarginleft + 10 + 'px';
          tooltip.node().style.right = 'auto';
          tooltip.classed('chart-tooltip-right', false);
          tooltip.classed('chart-tooltip-left', true);
        }
      };

      main.append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .style("fill", 'none')
        .style("cursor", 'move')
        .style("pointer-events", 'all')
        .attr("transform", `translate(${0}, ${0})`)
        .call(zoom)
        .on("mouseover", tipMouseMove)
        .on("mouseout", () => {
          hideTooltip(svg, tooltip)
        })
        .on("mousemove", tipMouseMove);
    }

    const createBarChart = () => {
      data.map((d) => {
        subgroups.filter((key: any) => {
          if (key.display || key?.display === undefined) {
            return reactData.push({ key: key, value: d[key.name || key.description], time: d.group, date: d.date });
          }
        });
      })

      main.append("g")
        .classed('chart-main', true)
        .selectAll("g")
        .data(data)
        .join("g")
        .attr("class", 'group-ract-main')
        .attr("clip-path", `url(#clip-${guid})`)
        .attr("transform", (d: any) => `translate(${x(d.group)}, 0)`)
        .selectAll("rect")
        .data((d: any) => {
          return subgroups.map((key: any) => {
            return { key: key, value: d[key.name || key.description], time: d.group, date: d.date };
          })
        })
        .join("rect")
        .attr("x", (d: any) => {
          return xSubgroup(d.key.name)
        })
        .attr("y", (d: any) => {
          return y(d.value.value)
        })
        .attr("width", xSubgroup.bandwidth())
        .attr("class", 'group-ract')
        .attr("height", (d: any) => {
          return height - y(d.value.value)
        })
        .attr("fill", (d: any) => color(d.key.name || d.key.description));

      zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]])
        .on("zoom", (d) => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
          hideTooltip(svg, tooltip)
          const t = d3.event.transform;
          x.range([0, width].map(d => {
            return d3.event.transform.applyX(d)
          }));
          x.domain(_x.domain());
          xSubgroup.range([0, x.bandwidth()])
          svg.selectAll(".x-axis").call(d3.axisBottom(x).tickFormat((d, i) => {
            const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data[i].date;
            return getFormatedDate(tickFormat, config.aggregationInterval);
          }));
          formatXAxis();
          const point = formatDataAggregation(widgetData, width, x, subgroups);
          y.domain([d3.min(point), d3.max(point)]).nice();

          svg.selectAll(".group-ract-main")
            .style("opacity", 100)
            .attr("transform", (d: any) => `translate(${x(d.group)}, 0)`)
            .selectAll("rect")
            .attr("width", xSubgroup.bandwidth())
            .attr("height", (d: any) => {
              return height - y(d.value.value)
            })
            .attr("x", (d: any) => {
              return xSubgroup(d.key.name)
            })
            .attr("y", (d: any) => {
              return y(d.value.value)
            })
            .attr("fill", (d: any) => color(d.key.name || d.key.description))

          ploatFiltersvg.select(".x_brush").call(brush.move, _x.range().map(t.invertX, t));
          svg.selectAll(".y-axis").call(d3.axisLeft(y).ticks(tickSize)
            .tickFormat((d: any) => {
              return convertValues(d);
            })
          )
          updateAxisConfig(yAxisGrid, y, svg, '.y-axis-grid')
          formatAxisConfig(svg, config.chartConfig);
          updateBrushHandle(context);
          handle.attr("x", (d) => {
            const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
            return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
          })
        });

      const tipMouseMove = () => {
        let toolTipText = '';
        const x0: any = x.invert(d3.event.offsetX - vhmarginleft);
        let d = data.findIndex(x => x.group === x0);
        if (config.aggregationType === "min" || config.aggregationType === "max") {
          toolTipText = `<p>${data[d].date.toLocaleString('en-GB')} </p>`;
          Object.keys(data[d]).forEach(element => {
            if (!(element === 'date' || element === 'group')) {
              const device = config.attributes.find(x => x.name === element || x.description === element);
              if (data[d][element].timestamp) {
                toolTipText += `<p><span class='legend-box' style='background-color:${color(element)}'></span> <span>${' '} ${element}: ${data[d][element].value.toFixed(3)}, </span> <span id='timestamp_${device.deviceId}'>${data[d][element].timestamp.toLocaleString('en-GB')}</span> </p>`
              }
              else {
                toolTipText += `<div><span class='legend-box' style='background-color:${color(element)}'></span> <span>${' '} ${element}: ${data[d][element].value.toFixed(3)}, </span><span id='timestamp_${device.deviceId}'> <div class="loader"></div></span></div>`
                if (!data[d][element].isLoading) {
                  data[d][element].isLoading = true;

                  getTimeStamp(data[d][element], config, device).then((res: any) => {
                    data[d][element].timestamp = convertDate(res.data);
                    const currentPosition = parseFloat(main.select('.line-tooltip').attr("x1")).toFixed(3)
                    const requestPosition = (x(data[d].group) + x.bandwidth() / 2).toFixed(3)
                    console.log(currentPosition, requestPosition);
                    if (currentPosition === requestPosition) {
                      tooltip.select(`#timestamp_${device.deviceId}`).html(data[d][element].timestamp.toLocaleString('en-GB'));
                    }
                  })
                }
              }
            }
          });
        }
        else {
          let weekDayNumber = moment(data[d]?.date).week();
          // Creating tooltip according to the aggregation
          toolTipText = '' + getToolTipContent(config, data[d]?.date, weekDayNumber) + toolTipText
          Object.keys(data[d]).forEach(element => {
            if (!(element === 'date' || element === 'group')) {
              toolTipText += `<p> <span class='legend-box' style='background-color:${color(element)}'></span> ${' '} ${element} : ${data[d][element].value.toFixed(3)}<p>`
            }
          });
        }

        main.selectAll('.line-tooltip')
          .attr("x1", x(data[d].group) + x.bandwidth() / 2)
          .attr("y1", 0)
          .attr("x2", x(data[d].group) + x.bandwidth() / 2)
          .attr("y2", height)
          .style('opacity', 1);

        tooltip
          .style("opacity", "0.89")
          .html(toolTipText)

        tooltip.node().style.right = 'auto';
        tooltip.node().style.top = 'auto';
        tooltip.node().style.left = 'auto';

        let width = svg.node().clientWidth;

        let tipWidth = tooltip.node().offsetWidth;
        let tipHeight = tooltip.node().offsetHeight;

        if (width - (x(data[d].group) + x.bandwidth() / 2) < tipWidth) {
          tooltip.node().style.right = (width - (x(data[d].group) + x.bandwidth() / 2)) - vhmarginleft + 10 + 'px';
          tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
          tooltip.node().style.left = 'auto';
          tooltip.classed('chart-tooltip-right', true);
          tooltip.classed('chart-tooltip-left', false);
        }
        else {
          tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
          tooltip.node().style.left = (x(data[d].group) + x.bandwidth() / 2) + vhmarginleft + 10 + 'px';
          tooltip.node().style.right = 'auto';
          tooltip.classed('chart-tooltip-right', false);
          tooltip.classed('chart-tooltip-left', true);
        }
      }

      main.append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .style("fill", 'none')
        .style("cursor", 'move')
        .style("pointer-events", 'all')
        .attr("transform", `translate(${0}, ${0})`)
        .call(zoom)
        .on("mouseover", tipMouseMove)
        .on("mouseout", () => {
          hideTooltip(svg, tooltip);
        })
        .on("mousemove", tipMouseMove);
    }

    if (config.widgetType === "linechart" || config.widgetType === "areachart") {
      createLineChart();
    }
    else {
      createBarChart();
    }
    main
      .append("line")
      .attr("class", "line-tooltip")
      .style("stroke", tooltipLineColor)
      .style("stroke-width", tooltipLineWidth)
      .style("stroke-dasharray", tooltipLineDasharray)
      .style('opacity', 0)

    if (config.showScrollbar) {
      plotFilter()
    }

    function setChartAxis() {

      x = d3.scaleBand()
        .range([0, width])
        .padding(0.2);

      x.invert = function (x) {
        const domain = this.domain();
        const range = this.range()
        const scale = d3.scaleQuantize().domain(range).range(domain)
        return scale(x)
      }

      y = d3.scaleLinear()
        .range([height, 0])

      yAxisGrid = d3.axisLeft(y).ticks(tickSize).tickSize(-width).tickFormat((d) => { return '' });

      color = d3.scaleOrdinal()
        .domain(subgroups.map(x => x.name || x.description))
        .range(COLOR);

      if (stackedData.length === 1) {
        stackedData.push(0);
      }
      const maxValue = d3.max(stackedData) || 10;
      const minValue = d3.min(stackedData) || 0;

      x.domain(groups.map(x => x.key));
      y.domain([+minValue, +maxValue]).nice();

      _x = d3.scaleBand()
        .range([0, width])
        .padding(0.2)
        .domain(groups.map(x => x.key));

      if (config.chartConfig?.axis?.yAxis?.showGrid) {
        main.append('g')
          .attr('class', 'y-axis-grid')
          .call(yAxisGrid);
      }

      main.append("g")
        .attr("transform", `translate(0, ${height})`)
        .attr("class", `axis-bottom x-axis`)
        .attr("clip-path", `url(#clip-${guid})`)
        .call(d3.axisBottom(x)
          .tickFormat((d, i) => {
            const tickFormat = config.widgetType === "linechart" || config.widgetType === "areachart" ? data[0]?.values.find(x => x.time === d).date : data[i].date;
            return getFormatedDate(tickFormat, config.aggregationInterval);
          })
        )

      formatXAxis();

      main.append("g")
        .attr("class", `axis-left y-axis`)
        .call(d3.axisLeft(y).ticks(tickSize)
          .tickFormat((d: any) => {
            return convertValues(d);
          })
        )

      formatAxisConfig(svg, config.chartConfig);

      const xSubgroupDomain = [];
      subgroups.filter((x) => {
        if (x.display || x?.display === undefined) {
          xSubgroupDomain.push(x.name)
        }
      })

      xSubgroup = d3.scaleBand()
        .domain(xSubgroupDomain)
        .range([0, x.bandwidth()])
        .padding(0.05);
    }
  }
}

const getToolTipContent = (config, date, weekDayNumber) => {
  let toolTipText = '';

  switch (config.aggregationInterval) {
    case DAILY:
      toolTipText = `<p>${moment(date).format(DATE_FORMAT_FOR_DAILY_AGGREGATION)} </p>`
      break;
    case WEEKLY:
      toolTipText = `<p>Week ${weekDayNumber < 10 ? weekDayNumber.toString().padStart(2, '0') : weekDayNumber} </p>`
      break;
    case MONTHLY:
      toolTipText = `<p>${moment(date).format(DATE_FORMAT_FOR_MONTHLY_AGGREGATION)} </p>`
      break;
    case YEARLY:
      toolTipText = `<p>${moment(date).format(DATE_FORMAT_FOR_YEARLY_AGGREGATION)} </p>`
      break;
    default:
      toolTipText = `<p>${date.toLocaleString(EN_GB_LOCAL_STRING)} </p>`
  }

  return toolTipText;
}