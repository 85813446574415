import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { SharedDataService } from '../../modules/shared-module/shared-meter-type.service';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/services/socket.service';
import { INotification, INotificationData } from '../common.interfaces';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { searchAttributesRestricted } from '../dataSource';
import {
  THRESHOLD,
  SMART,
  NOTIFICATION_STATUS_CLICKED,
  NOTIFICATION_STATUS_NOT_SEEN,
  DEVICE,
  restrictedUsers,
  ALERT_NAMES
} from '../constant';

import { MatMenuTrigger } from '@angular/material/menu';
import { MatTabGroup } from '@angular/material/tabs';
import { ThresholdAlertService } from 'src/app/modules/threshold-alert/services/threshold-alert.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { breakPoints } from '../constant';
import { UserAuthService } from 'src/app/modules/sso/user-auth.service';
import { getLoginUser } from '../user-util';
import { deviceTypeTitle } from '../constant';
import { LoginService } from 'src/app/modules/login/login.service';
import { AlertsManagementService } from '../services/alerts-management.service';
import { ALERTS_NOTIFICATION, HEADER_AND_ICONS } from './constant';
import { MatDialog } from '@angular/material/dialog';
import { DownloadTnpSharedService } from 'src/app/services/download-tnp/download-tnp-shared.service';
export interface IAlertNotification {
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../../assets/Reusable-CSS/mat.scss', '../../../assets/Reusable-CSS/form.scss', '../../../assets/Reusable-CSS/buttons.scss']

})
export class HeaderComponent implements OnInit {
  @Input() activenewlink;
  @Input() newlink = 'false';
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('targetMenuTabGroup') targetMenuTabGroup: MatTabGroup;
 

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private router: Router,
    private sharedService: SharedServiceService,
    private sharedChange: SharedDataService,
    private thresholdAlertService: ThresholdAlertService,
    private io: SocketService,
    private elasticSearchservice: ElasticSearchService,
    private userAuthService: UserAuthService,
    private loginService: LoginService,
    public alertsManagementService: AlertsManagementService,
    private dialog: MatDialog,
    private downloadTnpSharedService: DownloadTnpSharedService
  ) {
    this.matIconRegistry.addSvgIcon("notification", this.domSanitizer
      .bypassSecurityTrustResourceUrl("assets/images/notification.svg"));
  }
  public openpage = false;

  public title: string = environment.title;
  public activeUserName = '';
  SwitchMeter: any

  public options: any;
  meterType: any;
  public notifications: any;
  public userName: string;
  public userRole: string;
  public newNotifications = 0;
  public notificationSeen = true;
  public searchTerm: string;
  public searchSuggestions = [];
  public severityClass = {
    "low": "textLow",
    "medium": "textMedium",
    "high": "textHigh",
    "critical": "textCritical",
  };
  public attributesRestricted = searchAttributesRestricted;
  public showMarkAllRead: boolean = false;
  public loading = false;
  public isRestrictedUser: boolean;
  public alertNames = ALERT_NAMES;

  alertsNotifications;
  breakPoints = breakPoints
  restrictedUsers = restrictedUsers


  ngOnInit() {
    this.userName = getLoginUser();
    this.isRestrictedUser = this.restrictedUsers.some(x => x=== this.userName);
    this.userRole = localStorage.getItem('userRole');
    if (this.userName) {
      this.activeUserName = this.userName.split('@')[0].split(".").join(' ');
    }
    this.meterType = sessionStorage.getItem('meterType');
    this.sharedChange.announceMeter(this.meterType);

    this.SwitchMeter = 'Energy';

    if (this.meterType === 'energy') {
      this.title = "Neom Sense - Energy"
      this.SwitchMeter = 'Water';
    }

    this.fetchNotifications();
    // this.io.socket.emit('userConnected', this.userName);
    this.io.listen('newNotification').subscribe((data: any) => {
      console.log('new notification event triggerggered = ', data);
      this.fetchNotifications();
      this.notificationSeen = true;
    });

    this.downloadTnpSharedService.fetchNotificationForReadyFiles();
    this.downloadTnpSharedService.shortPollingForDownloadStatusOfFiles();
    return;
  }

  async fetchNotifications() {
    try {
      this.loading = true;
      this.alertsNotifications = new ALERTS_NOTIFICATION;
      this.newNotifications = 0;
      this.notifications = await this.alertsManagementService.fetchNotifications();
      this.notifications.forEach((notification) => {
        /* set day and time, if createdOn date is not as today then show full date otherwise just time */
        if (moment().isSame(moment(notification["createdOn"]), 'day')) {
          notification['date'] = moment(notification["createdOn"]).format('h:mm A');
        } else {
          notification['date'] = moment(notification["createdOn"]).format("MMM Do YYYY");
        }

        // set logo and deviceType
        notification['logo'] = HEADER_AND_ICONS[notification['productType']].logo;
        notification['deviceType'] = HEADER_AND_ICONS[notification['productType']].deviceType

        // capitalize first word of alert name
        notification['alertName'] = this.capitalize(notification['alertName'])

        // total count of new unseen alerts
        if (notification.status === NOTIFICATION_STATUS_NOT_SEEN) {
          this.newNotifications++;;
        }

        // segregate the in threshold alert and smart alert
        if (notification.alertType === THRESHOLD) {
          this.alertsNotifications.thresholdAlerts.alerts.push(notification);
          // maintain the unviewed notifications count
          if (notification.status !== NOTIFICATION_STATUS_CLICKED) {
            this.alertsNotifications.thresholdAlerts.newAlertsCount++;
          }
        }
        else if (notification.alertType === SMART) {
          this.alertsNotifications.smartAlerts.alerts.push(notification);
          // maintain the unviewed notifications count
          if (notification.status !== NOTIFICATION_STATUS_CLICKED) {
            this.alertsNotifications.smartAlerts.newAlertsCount++;
          }
        }
        else if(notification.alertType === DEVICE) {
          this.alertsNotifications.deviceAlerts.alerts.push(notification);
          // maintain the unviewed notifications count
          if (notification.status !== NOTIFICATION_STATUS_CLICKED) {
            this.alertsNotifications.deviceAlerts.newAlertsCount++;
          }
        }

        // condition of showing mark all as read
        if(!this.showMarkAllRead && notification.status !== NOTIFICATION_STATUS_CLICKED) {
          this.showMarkAllRead = true;
        }

      })

      this.loading = false;
    }
    catch (error) {
      this.loading = false;
      this.notifications = [];
      console.log(error);
    }
  }

  capitalize(sentence) {
    return  sentence.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  filterActiveAndResolved(notifications: INotificationData[]): INotification[] {
    if (!notifications[0]?.data?.length) return [];
    return notifications[0].data.filter((item: INotification) => ['active', 'resolved'].indexOf(item.alertStatus.toLowerCase()) > -1);
  }

  public addlinksinHeader(draft) {
    this.newlink = 'true';
    this.activenewlink = draft;
  }

  public checkIfDateIsInLS() {
    if (!localStorage.getItem('dates')) {
      return false;
    } else {
      return true;
    }
  }

  public logout() {
    if (localStorage.getItem("sso-user") === "true") {
      this.dialog.closeAll();
      this.userAuthService.logout();
    }
    else {
      this.loginService.logout();
      this.dialog.closeAll();
      this.router.navigate(['/user']);
    }
    localStorage.clear();
    return;
  }

  public Switch() {
    this.sharedChange.announceMeter(this.meterType);
    this.router.navigate(['/home']);
    return;
  }

  public goToAlerts(alertId: any, deviceSerialNumber: string) {
    sessionStorage.setItem('meterType', 'water');
    localStorage.setItem('deviceSerial', deviceSerialNumber);
    localStorage.setItem('alertSerial', alertId);
    // this.router.navigate(['/alert-management', 'alert'], {queryParams:{"alertID":alertId}});
    this.router.navigate(
      [`/alert-management/alert/${alertId}`]
    );
  }

  public viewAll() {
    sessionStorage.setItem('meterType', 'water');
    this.router.navigate([`/alert-management`]);
  }

  changeNotificationStatus(notification: any, status: string) {
    const alertID = [notification.alertId];

    if (notification.status !== NOTIFICATION_STATUS_CLICKED) {
      if (notification.alertType === THRESHOLD) {
        this.alertsNotifications.thresholdAlerts.newAlertsCount--;
      }
      else if(notification.alertType === SMART){
        this.alertsNotifications.smartAlerts.newAlertsCount--;
      }
      else if(notification.alertType === DEVICE) {
        this.alertsNotifications.deviceAlerts.newAlertsCount--;
      }
      this.thresholdAlertService.markNotificationStatus(alertID, status).then((response) => {
        notification.status = status;
      });
    }

    this.goToAlerts(alertID, notification.deviceSerialNumber);


  }


  public markSeen() {
    if (this.newNotifications > 0) {
      const alertIds = ['all'];
      this.newNotifications = 0;
      this.thresholdAlertService.markNotificationStatus(alertIds, 'seen').then((response: { status: boolean, message: string }) => {
        if (response?.status) {
          this.notificationSeen = false;
        }
        return;
      });
    }

  }

  public markAllAsRead() {

    for (let i = 0; i < this.notifications.length; i++) {
      this.notifications[i].status = 'clicked';
    }
    this.alertsNotifications.thresholdAlerts.newAlertsCount = 0;
    this.alertsNotifications.smartAlerts.newAlertsCount = 0;
    this.alertsNotifications.deviceAlerts.newAlertsCount = 0;
    const alertIds = ['all'];
    this.thresholdAlertService.markNotificationStatus(alertIds, 'clicked')
      .then((data) => {
        this.ngOnInit();
      })
      .catch((error) => {
        console.log(error.message);
      })

  }

  public getSeverityClasses(severity: string) {
    return this.severityClass[`${severity}`];
  }

  public getNotificationTimeFormatted(notificationCreatedOn: Date) {
    return this.sharedService.returnDaysAgoFromTodayDate(notificationCreatedOn, true);
  }

  public onSearch(value: any) {
    if (value) {
      this.searchTerm = value.target.value
      if (value.keyCode === 13) {
        this.searchResult(this.searchTerm);
      }
      else {
        this.elasticSearchservice.searchSuggestion({ query: this.searchTerm }).toPromise()
          .then((data: any) => {
            this.searchSuggestions = data?.filter((suggestion: any) => {
              if (this.attributesRestricted.includes(suggestion?.keyword)) {
                return;
              }
              return suggestion;
            });
          })
      }

    } else {
      this.searchSuggestions = [];
    }
  }

  searchResult(param: string) {
    if (param) {
      this.sharedService.updateSearchTerm(param);
      this.searchTerm = null;
      this.router.navigateByUrl('/search-result');
    }
  }

  navigateTo(link) {
    this.router.navigate([link]);
  }

  closeMenu(): void {
    this.menuTrigger.closeMenu()
  }
  OnTargetMenuOpened(event) {
    // if no notifications found we don't have the targetMenuTabGroup that's why need to check it existence first
    this.targetMenuTabGroup?.realignInkBar();
  }

  showActiveDot(notification) {
    return notification.status !== 'clicked';
  }

  isItSmartOrDeviceAlert(notification) {
    return notification.alertType === SMART || notification.alertType === DEVICE;
  }

  isItThreshold(notification) {
    return notification.alertType === THRESHOLD
  }

  ngOnDestroy() {
    this.downloadTnpSharedService.stopPolling();
  }

}

