export const PENDING = 'pending';
export const REQUESTED = 'requested';
export const ERROR_MESSAGES = {
    SAME_SCHEME: 'The selected devices must have the same data schema for successful download',
    PERMANANENT_COLUMN: 'Permanent column cannot be removed',
    DURATION: 'Select a duration less than 30 days for Native granularity',
    DOWNLOAD: 'Failure in downloading the data. Please try again',
    NO_DATA: 'No Data Found',
};

export const SUCCESS_MESSAGES = {
    FILE_DOWNLOAD: 'File downloaded successfully'
};

export const DATE_FORMATS = {
    HOUR: 'YYYY-MM-DD HH:mm',
    DAY: 'YYYY-MM-DD',
    MONTH: 'YYYY-MM',
    TIMESTAMP: 'YYYY-MM-DD HH:mm:ss',
};

export const aggregationOptions = [
    {
        name: 'Sum',
        value: 'Sum',
    },
    {
        name: 'Difference',
        value: 'Diff',
    },
    {
        name: 'Average',
        value: 'avg',
    },
    {
        name: 'Maximum',
        value: 'Max',
    },
    {
        name: 'Minimum',
        value: 'Min',
    }
]


export const energyMeterDatatypes = [
    {
        title: 'Telemetry',
        value: 'telemetry',
    },
    {
        title: 'Load Profile 1',
        value: 'loadprofile1',
    },
    {
        title: 'Load Profile 2',
        value: 'loadprofile2',
    }
]

export const waterMeterDatatypes = [{
    title: 'Telemetry',
    value: 'telemetry',
}];

export const DATA_COLUMNS = {
    WATER: {
        DEFAULT: ['Time', 'Meter Reading', 'Volume Consumed', 'Max Flow Rate', 'Max Flow Temperature', 'Device Serial'],
        NATIVE: ['Timestamp', 'Volume', 'Flow Rate', 'Flow Temprature', 'Battery Life', 'Device Serial']
    },
    ENERGY: {
        DEFAULT: ['Time', 'Meter Reading', 'Volume Consumed', 'Max Flow Rate', 'Max Flow Temperature', 'Device Serial'],
        NATIVE: ['Time', 'Meter Reading', 'Max kW-Ins', 'Max avg-Power-Factor', 'Max Current-A-THD', 'Max Current-B-THD', 'Max Current-C-THD', 'Max Voltage-A-THD', 'Max Voltage-B-THD', 'Max Voltage-C-THD', 'Max Vln-an', 'Max Vln-bn', 'Max Vln-cn', 'Device Serial']
    }
};

export const DEVICE_PROFILES = ['telemetry', 'loadprofile1', 'loadprofile2'];

export const DEFAULT_HEADER_COLUMNS = ['Device Serial', 'Timestamp'];