<div class="pagination" *ngIf="filteredData?.length > 0"> 
    <div style="min-width: 3.66vw;" class="mr-2">
        <mat-select [(ngModel)]="itemPerPage"(selectionChange)="tablePageSizeChange($event)" [disableOptionCentering]="true">
            <mat-option *ngFor="let item of pageSizeOption" [value]="item">{{item}}</mat-option>
        </mat-select>
    </div>
    <span>{{(currentPage-1)*itemPerPage + 1 }} - {{this.currentPage === totalPageCount ? totalRecords : this.currentPage*itemPerPage}} of {{totalRecords}}</span>
    <button  class="actionButtons" [ngClass]="currentPage > 1 ? 'activePage':'nonActivePage'" (click)="handleSetPage(1)"><img src="assets/images/table-first-link.svg" class="imgW12" width="12px" title="First"></button>
    <button  class="actionButtons" [ngClass]="currentPage > 1 ? 'activePage':'nonActivePage'" (click)="handlePrev()"><img src="assets/images/table-back-icon.svg" width="7px" title="Previous"></button>
    <button  class="actionButtons reverseIcon" [ngClass]="currentPage < totalPageCount ? 'activePage':'nonActivePage'"   (click)="handleNext()"><img src="assets/images/table-back-icon.svg" width="7px" title="Next"></button>
    <button  class="actionButtons reverseIcon" [ngClass]="currentPage < totalPageCount ? 'activePage':'nonActivePage'"  (click)="handleSetPage(totalPageCount)"><img src="assets/images/table-first-link.svg" class="imgW12" width="12px" title="Last"></button>
</div> 