import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostListener, ElementRef } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export class TodoItemNode {
  children: TodoItemNode[];
  item: string;
}

export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.css']
})
export class SitesComponent implements OnInit, OnChanges {
  @Input() sites: any = [];
  @Output() siteChanges = new EventEmitter<any>();

  availableOptions = [];
  selectedSites = [];
  filter = {};

  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  treeVisible = false;

  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true);

  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  defaultSite = environment.production ? "Sindalah Island" : "NEOM Community 1";

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }
  constructor(private eRef: ElementRef,
    private router: Router
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit() {
    this.initialize();
    const savedSelectedSites = localStorage.getItem('selectedSites');
    if (savedSelectedSites) {
      this.filter = JSON.parse(savedSelectedSites);
      this.selectedSites = Object.keys(this.filter);
  
      for (let site in this.filter) {
        const siteNode = this.treeControl.dataNodes.find(node => node.item === site && node.level === 0);
        if (siteNode) {
          this.filter[site]?.forEach(zone => {
            const zoneNode = this.treeControl.dataNodes.find(node => node.item === zone && node.level === 1 && this.getParentNode(node) === siteNode);
            if (zoneNode) {
              this.checklistSelection.select(zoneNode);
            }
          });
          this.treeControl.expand(siteNode);
        }
      }
    }
    this.createSitesOptions();
  }

   ngOnChanges(changes: SimpleChanges) {
    if (changes['sites'] && !changes['sites'].firstChange) {
      this.initialize();
      this.createSitesOptions();
    }
  }

  initialize() {
    const data = this.buildFileTree(this.sites, 0);
    this.dataChange.next(data);
    this.dataSource.data = data;
    const savedSelectedSites = localStorage.getItem('selectedSites');
    if (savedSelectedSites) {
      this.filter = JSON.parse(savedSelectedSites);
      this.selectedSites = Object.keys(this.filter);
      for (let site in this.filter) {
        const siteNode = this.treeControl.dataNodes.find(node => node.item === site && node.level === 0);
        if (siteNode) {
          this.filter[site].forEach(zone => {
            const zoneNode = this.treeControl.dataNodes.find(node => node.item === zone && node.level === 1 && this.getParentNode(node) === siteNode);
            if (zoneNode) {
              this.checklistSelection.select(zoneNode);
            }
          });
          this.checklistSelection.toggle(siteNode);
          this.treeControl.expand(siteNode);
        }
      }
    }
  }
  
  
  buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          node.children = this.buildFileTree(value, level + 1);
        } else if (Array.isArray(value)) {
          node.children = value.map(v => {
            const childNode = new TodoItemNode();
            childNode.item = v;
            return childNode;
          });
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  createSitesOptions() {
    this.availableOptions = [];
    if(this.sites){
      for (let site in this.sites) {
        if (this.sites[site]) {
          this.sites[site].forEach(zone => {
            this.availableOptions.push({ site, zone, id: `${site}.${zone}` });
          });
        }
      }
      if(this.availableOptions && this.availableOptions.length > 0) {
        const selectionValue = localStorage.getItem('selectedSites');
        if (selectionValue) {
          let selectedSites = Object.keys(selectionValue);
          if (selectedSites && selectedSites.length > 0) {
            selectedSites.forEach(value => {
              const index = this.availableOptions.findIndex(x => x.site === value);
              if (index !== -1) {
                const site = this.availableOptions[index]?.site;
                if (site) {
                  const siteNode = this.treeControl.dataNodes.find(node => node.item === site && node.level === 0);
                  if (siteNode) {
                    this.selectNodeAndDescendants(siteNode);
                    this.treeControl.expand(siteNode);
                  }
                }
              }
            });
          }
        } else {
          const index = this.availableOptions.findIndex(x => x.site === this.defaultSite) !== -1 ? this.availableOptions.findIndex(x => x.site === this.defaultSite) : 0;
          const firstSite = this.availableOptions[index]?.site;
          if (firstSite) {
            const firstSiteNode = this.treeControl.dataNodes.find(node => node.item === firstSite && node.level === 0);
            if (firstSiteNode) {
              this.selectNodeAndDescendants(firstSiteNode);
              this.checklistSelection.toggle(firstSiteNode);
              this.treeControl.expand(firstSiteNode);
            }
          }
        }
      }else{
        this.handleSiteChange();
      }
    }
  }

  handleSiteChange() {
    this.siteChanges.emit(this.filter);
  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item === '' ? 'NA' : node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }


  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => this.checklistSelection.isSelected(child));
    return descAllSelected;
  }

  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    // if (this.checklistSelection.isSelected(node) && this.selectedSites.length === 1) {
    //   const parentNode = this.getParentNode(node);
    //   if (!parentNode || (parentNode && this.filter[parentNode.item] && this.filter[parentNode.item].length === 1)) {
    //     // Prevent deselection if only one site or one zone in a site is selected
    //     return;
    //   }
    // }
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node) ? this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.updateSelectedSites();
  }

  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    const parentNode = this.getParentNode(node);
    // if (this.checklistSelection.isSelected(node) && this.selectedSites.length === 1 && parentNode && this.filter[parentNode.item].length === 1) {
    //   // Prevent deselection if only one zone in a site is selected
    //   return;
    // }
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.updateSelectedSites();
  }

  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  isDisabled(node: TodoItemFlatNode): boolean {
    const parentNode = this.getParentNode(node);
    if (node.level === 0) {
      return this.checklistSelection.isSelected(node) && this.selectedSites.length === 1;
    } else if (node.level === 1 && parentNode) {
      return this.checklistSelection.isSelected(node) && this.filter[parentNode.item]?.length === 1;
    }
    return false;
  }

  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => this.checklistSelection.isSelected(child));
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  toggleTreeVisibility() {
    this.treeVisible = !this.treeVisible;
  }

  updateSelectedSites2() {
    this.filter = {};
    this.checklistSelection.selected.forEach((node) => {
      if (node.level === 0) {
        // If it's a site
        if (!this.filter[node.item]) {
          this.filter[node.item] = [];
        }
      } else {
        // If it's a zone
        const parent = this.getParentNode(node);
        if (parent) {
          if (!this.filter[parent.item]) {
            this.filter[parent.item] = [];
          }
          this.filter[parent.item].push(node.item);
        }
      }
    });
    this.handleSiteChange();
    this.selectedSites = Object.keys(this.filter);
  }

  selectNodeAndDescendants(node: TodoItemFlatNode): void {
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.select(...descendants);
    this.updateSelectedSites();
  }
  
  updateSelectedSites() {
    this.filter = {};
    this.checklistSelection.selected.forEach((node) => {
      const parent = this.getParentNode(node);
      if (parent) {
        if (!this.filter[parent.item]) {
          this.filter[parent.item] = [];
        }
        this.filter[parent.item].push(node.item);
      }
    });
    localStorage.setItem('selectedSites', JSON.stringify(this.filter));
    this.handleSiteChange();
    this.selectedSites = Object.keys(this.filter);
  }
  
  

  getSelectedItemsText() {
    const selectedSites = Object.keys(this.filter);
    return selectedSites.length === 0 ? 'Select Site' : selectedSites[0];
  }
  
  getBadgeCount(): number {
    const selectedSites = Object.keys(this.filter);
    return selectedSites.length > 1 ? selectedSites.length - 1 : 0;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.treeVisible = false;
    }
  }

  isSiteSelected() {
    return !!Object.keys(this.filter)?.length;
  }
}
