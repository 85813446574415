import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import {environment} from "../../../environments/environment";
import {SharedDataService} from '../../modules/shared-module/shared-meter-type.service';
import {SharedServiceService} from "../../services/shared-service.service";
import { CustomDashboardService } from 'src/app/services/CustomDashboard/custom-dashboard.service';
import { getLoginUser } from '../user-util';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { DownloadTnpSharedService } from 'src/app/services/download-tnp/download-tnp-shared.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public showSidebar: boolean = false;
  public meterType: string;
  public clickSubNav:boolean = false;
  pinnedDashboardLoader: number = -1;

  customerIndus = {
    Consumer : 'Consumer',
    industrial : 'industrial'
  }
  customerValues = {
    Water : [
      {
        description: 'Water Meters',
        location: '- NC1',
        route: '/watermeters/dashboard'
      },
      {
        description: 'Flow Meters',
        location: '- The Royal Palace',
        route: '/watermeters-customer/flow-meters'
      }
    ],
    Energy : [
      {
        description: 'Energy Meters',
        location: '- NC1',
        route: '/energymeters/dashboard'
      }
    ],
    Cooling: [
      {
        description: 'Cooling Meters',
        location: '- NC1',
        route: '/coolingmeters/dashboard'
      }
    ]
  }
  industrialValues = {
    Water : [
      {
        description: 'Desalination Plant',
        location: 'SCADA - Duba',
        route: '/watermeters-industrial/dubadesalplant'
      },
      {
        description: 'Pump Station & TFS',
        location: 'SCADA - Sharma',
        route: '/watermeters-industrial/sharmaPumpStation'
      },
      {
        description: 'High Point Reservoir',
        location: 'SCADA - Sharma',
        route: '/watermeters-industrial/hprstation'
      },
      {
        description: 'Water Recycling Plant',
        location: 'SCADA - Al Bada',
        route: '/watermeters-industrial/ALBADA'
      },
      {
        description: 'Logistics Park',
        location: '- Sharma',
        route: '/watermeters-industrial/logisticspark'
      },
      {
        description: 'NEOM Bay Airport',
        location: '- Sharma',
        route: '/watermeters-industrial/neombayairport'
      },
      {
        description: 'Experience Center',
        location: '- Sharma',
        route: '/watermeters-industrial/experiencecenter'
      }
    ],
    Energy : [
      // {
      //   description: 'Solar Plant',
      //   // route: '/energymeters/dashboard'
      // }
    ]
  }

  private downloadStatusSubscription:Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sharedChange: SharedDataService,
    private sharedService:SharedServiceService,
    public customDashboardService: CustomDashboardService,
    private dataService:DataService,
    private downloadTnpSharedService: DownloadTnpSharedService
    ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        // TODO: will break if we have another url that contains /user.
        if (event.url.includes("/user")) {
          this.showSidebar = false;
        } else {
          this.showSidebar = true;
        }
      }
    });
   }

  public title: string = environment.title;
  public menuVisible : boolean = false;
  selectedDashboard = 'Consumer';
  public customDashboard = [];
  defaultLoaderInfo = {show:false, icon:'', text: '', showRipple: false};
  statusLoaderInfo:{show:boolean, icon: string, text: string, showRipple:boolean} = this.defaultLoaderInfo;
  showRippleForDownload:boolean = false;
  ngOnInit() {
    // this.route.queryParams
    //   .subscribe(params => {
    //     this.meterType = params.type;
    //   }
    // );
    this.sharedChange.meterChanged$.subscribe(meter => {
      this.meterType=meter;
      return;
    });
   // this.meterType = this.sharedService.MeterType;
    this.meterType = sessionStorage.getItem('meterType');

    //this.meterType = localStorage.getItem('meterType');

    this.sharedChange.sectorChanged$.subscribe(() => {
      setTimeout(() => this.showSidebar = false, 0);
      return;
    });

    this.getMyDashboard();
    this.downloadStatusSubscription = this.downloadTnpSharedService.downloadStatusLoader.subscribe((statusInfo) => {
      this.statusLoaderInfo = statusInfo;
      this.showRippleForDownload = statusInfo.showRipple;
      setTimeout(() => {
        this.statusLoaderInfo = this.defaultLoaderInfo;
      }, 50000);
    })
  }

  showMenu(){
    this.menuVisible = !this.menuVisible;
  }

  getSelectedValue: any = this.customerValues;
  handleToggle(selectedToggle) {
    selectedToggle == 'Consumer' ? this.getSelectedValue = this.customerValues : this.getSelectedValue = this.industrialValues;
    this.selectedDashboard = selectedToggle;
  }

  getMyDashboard() {
    this.customDashboardService.getAllCustomDashboard(getLoginUser()).then((data: any) => {
      const sortedData = data.dashboards.sort((a, b) => {
        const aHasEmail = a.pinnedBy?.includes(getLoginUser()) ? 0 : 1;
        const bHasEmail = b.pinnedBy?.includes(getLoginUser()) ? 0 : 1;
        return aHasEmail - bHasEmail;
      });
      this.customDashboard = sortedData
    })
  }

  sidebardSubNavHover: boolean = false;
  over(value) {
    this.sidebardSubNavHover = value;
  }

  getRouterLink(dashboard) {
    if(dashboard.drawingData){
      return "connectioncanvas/" + dashboard._id;
    }else{
      return "custom-dashboard/" + dashboard._id;
    }
  }
  handleClick(): void {
    this.clickSubNav =true;
  }
  subNavigationClick():void {
    this.clickSubNav = false;
  }
  
  savePinnedDashboard(dashboard,event,i){
    this.pinnedDashboardLoader = i;
    let pinnedBy=dashboard.pinnedBy
    if (event==='pin') {
      pinnedBy=pinnedBy?.length ? [...pinnedBy,getLoginUser()]:[getLoginUser()]
    }else{
      pinnedBy=pinnedBy?.filter(email=>email.toLowerCase() !== getLoginUser().toLowerCase())
    }
    let dashboardData = {...dashboard,pinnedBy};
    this.customDashboardService.updateCustomDashboard(dashboardData).then((data: any) => {
      this.dataService.getPinnedDashboard(true)
      this.getMyDashboard();
      this.pinnedDashboardLoader = -1;
    })
  }

  // getPinnedDashboard() {
  //   this.customDashboardService.getPinnedDashboard(getLoginUser()).then((data: any) => {
  //     console.log('data', data)
  //     this.customDashboard = data.dashboards;
  //   })
  // }

  getPinnedUnPinnedInfo(dashboard){
    return dashboard.pinnedBy?.indexOf(getLoginUser()) > -1 ? 'unpin' : 'pin'
  }

  ngOnDestroy() {
    if(this.downloadStatusSubscription) {
      this.downloadStatusSubscription.unsubscribe();
    }
  }
}
