import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { IEntityHeader, lineOfBusiness } from './entity.interface';
import { DEVICE_TYPE_WATER_METER } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

  constructor(
    private apiService: ApiService
  ) { }

  public getSchema({ profileID, dataType }, deviceInfo?: any) {
    const entity: IEntityHeader = {
      deviceType: deviceInfo?.deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceInfo?.deviceType),
      site: deviceInfo?.site,
      manufacturerName: deviceInfo?.manufacturerName
    }
    return this.apiService.get(`/telemetry/schema?profileID=${profileID}&dataType=${dataType}`, entity);
  }

  public dataAvailabilityScoreByDatesSite(payload, deviceType) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType),
    }
    return this.apiService.post(`/telemetry/data-availability-score-by-dates-site-device-type`, payload, entity);
  }

  public dataAvailabilityScoreBydeviceIDs(payload, deviceType) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType),
    }
    return this.apiService.post(`/telemetry/data-availability-score-by-dates-deviceIDs`, payload, entity);
  }

  public dataAvailabilityScoreForDeviceDashboard(payload, deviceType) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType),
    }
    return this.apiService.post(`/telemetry/data-availability-score-for-device-dashboard`, payload, entity);
  }

  public downloadDataAvailabilityScore(payload, deviceType) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType),
    }
    return this.apiService.post(`/telemetry/download-data-availability-score`, payload, entity);
  }
  
  public percentageData(payload, deviceType) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType),
      // site: site
    }
    return this.apiService.post(`/telemetry/percentage-data?deviceType=${deviceType}`, payload, entity);
  }

 
  public getLatestTelemetryData(data) {
    const entity: IEntityHeader = {
      deviceType: data.deviceType,
      lineOfBusiness: lineOfBusiness.industrial,
      sector: 'Water' // //logic need to modify in future
    }
    return this.apiService.post('/telemetry/get-latest-telemetry-data', data, entity);
  }

  public getConsumptionByDeviceId(payload, aggregationType, deviceType, deviceID, site?) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType),
      site: site
    }
    return this.apiService.post(`/telemetry/get-consumptions-device-id?deviceType=${deviceType}&aggregationDuration=${aggregationType}&deviceID=${deviceID}&site=${site}`, payload, entity)
  }

  public getConsumptionPlotByDeviceIds(payload, aggregationType, deviceType) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType)
    }
    return this.apiService.post(`/telemetry/get-consumptions-plot-device-ids?aggregationDuration=${aggregationType}`, payload, entity)
  }

  public getConsumptionPerDevice(payload, aggregationType, deviceType) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      sector: this.getSectorByDeviceType(deviceType),
      // site: site

    }
    return this.apiService.post(`/telemetry/get-consumptions?deviceType=${deviceType}&aggregationDuration=${aggregationType}`, payload, entity)
  }

  public forecastingByDeviceId(deviceType) {
    return this.apiService.get(`/telemetry/get-forecasted-data?deviceType=${deviceType}`);
  }

  public attributeResult(postData) {
    const entity: IEntityHeader = {
      deviceType: postData.deviceType,
      lineOfBusiness: lineOfBusiness.industrial,
      sector: 'Water' //logic need to modify in future
    }
    return this.apiService.post('/telemetry/get-aggregate-asset-results', postData, entity);
  }
  public downloadResults(postData) {
    return this.apiService.post('/telemetry/get-aggregate-download-results', postData);
  }

  public getTimestamp(postData) {
    return this.apiService.post('/telemetry/get-aggregate-timestamp', postData);
  }

  public getSectorByDeviceType(deviceType) {
    return deviceType === DEVICE_TYPE_WATER_METER ? 'Water' : 'Energy';
  }

  public getTelemetryData(deviceIDs: Array<string>, dateRange: string[], aggregationDuration: string, deviceType: string){
    return this.apiService.post('/telemetry/get-telemetry-data', { deviceIDs, dateRange, aggregationDuration, deviceType });
  }

  public getAllDevicesConsumptions(payload) {
    return this.apiService.post('/telemetry/get-all-devices-consumptions', payload);
  }

  public getAllProfile(profileID) {
    return this.apiService.get(`/telemetry/get-all-profile?profileID=${profileID}`);
  }
  public getDefaultAttribute(payload) {
    return this.apiService.post('/telemetry/get-default-attribute', payload);
  }

  getPercentageChangesForConsumption(duration:any) {
    return this.apiService.post(`/telemetry/get-percentage-changes-for-consumptions`,duration);
  }

  getWaterProduced(payload) {
    return this.apiService.post('/telemetry/get-water-produced',payload);
  }

  getDeviceMissingData(deviceID : string, payload: any) {
    return this.apiService.post(`/telemetry/get-device-missing-data?deviceID=${deviceID}`,payload);
  }

  getConnectionHealthPerDevice(payload: any) {
    return this.apiService.post(`/telemetry/connection-health-score-per-device`, payload);
  }

  getFilesStatusByUsername(dates: string[]) {
    return this.apiService.post(`/telemetry/files-status-by-username`, dates);
  }

  getNotificationForReadyFiles() {
    return this.apiService.get(`/telemetry/notification-for-ready-files`);
  }

  downloadTelemetryData(payload) {
    return this.apiService.post(`/telemetry/download-telemetry-data`, payload);

  }
  deviceWiseConsumptionForSite(payload: any) {
    return this.apiService.post(`/telemetry/device-wise-consumption-for-site`, payload);
  }

  downloadDailyDataAvailabilityScoreForDevices(payload) {
    return this.apiService.post(`/telemetry/download-daily-data-availability-scores-for-devices`, payload);
  }

}
