<div id="alertsHeader" style="height:11%;padding: 10px 0px;border-top: 1px solid #f5f5f5;">
    <span id="titleLogs">Trigger History</span>
</div>
<div id="alertTable">
        <table>
            <thead>
                <tr>
                  
                    <th id="alertNameHeader">
                        <span>Alert Name</span>
                    </th>
                    <th class="tableCell tableHeaderCell">
                        <app-table-select-filter [data]="filterColumns['status']"
                            [name]="'Status'" (handleAppliedFilter)="handleAppliedFilter($event)"
                            (applySorting)="sortTable($event)">
                        </app-table-select-filter>
                    </th>
                    <th class="tableCell tableHeaderCell">
                        <app-table-select-filter [data]="filterColumns['alertClassification']"
                            [name]="'Alert Classification'" (handleAppliedFilter)="handleAppliedFilter($event)"
                            (applySorting)="sortTable($event)">
                        </app-table-select-filter>
                    </th>
                    <th class="tableCell tableHeaderCell">
                        <span>Event Start</span>
                        <app-table-sort-icon [data]="sortFilter['eventStart']"
                            (applySorting)="sortTable($event)" (handleAppliedFilter)="handleAppliedFilter($event)"
                            class="sortingIcon">
                        </app-table-sort-icon>
                    </th>
                    <th class="tableCell tableHeaderCell">
                        <span>Event End</span>
                        <app-table-sort-icon [data]="sortFilter['eventEnd']"
                            (applySorting)="sortTable($event)" (handleAppliedFilter)="handleAppliedFilter($event)"
                            class="sortingIcon">
                        </app-table-sort-icon>
                    </th>
                    <th class="tableCell tableHeaderCell">
                        <span>Created On</span>
                        <app-table-sort-icon [data]="sortFilter['createdOn']"
                            (applySorting)="sortTable($event)" (handleAppliedFilter)="handleAppliedFilter($event)"
                            class="sortingIcon">
                        </app-table-sort-icon>
                    </th>
                    <th class="tableCell tableHeaderCell">
                        <span>Active For</span>
                        <app-table-sort-icon [data]="sortFilter['activeFor']"
                            (applySorting)="sortTable($event)" (handleAppliedFilter)="handleAppliedFilter($event)"
                            class="sortingIcon">
                        </app-table-sort-icon>
                    </th>
                </tr>
            </thead>
            <tbody>
                <!-- if it is loading show loader -->
                <ng-container *ngIf="loading">
                    <tr class="loadingGif">
                        <td colspan="8" class="loading">
                            <div class="loader"></div>
                        </td>
                    </tr>
                </ng-container>
                <!-- if it is not loading and no data found show no data found -->
                <ng-container *ngIf="!loading && paginatedData?.length == 0">
                    <tr id="noResult">
                        <td colspan="8" class="noResult">
                                No alerts avaliable
                        </td>
                    </tr>
                </ng-container>
                <!-- otherwise show alerts -->
                <ng-container *ngIf="!loading && paginatedData?.length != 0">
                    <tr *ngFor="let alert of paginatedData">
                        <td id="alertNameHeader" [matTooltip]="alert.alertName" matTooltipPosition="above" matTooltipClass="above" (click)="goToManageAlert(alert)">
                            <div class="notificationType alertTd">
                                <span class="notificationName">{{alert.alertName|titlecase}}</span>
                            </div>
                        </td>
                        <td class="tableCell" (click)="goToManageAlert(alert)">
                            <span class="status" [ngClass]="getStatusClass(alert.status)">{{alert.status}}</span></td>
                        <td class="tableCell" (click)="goToManageAlert(alert)">
                            <span class="mutedValue">{{getAlertClassification(alert.alertClassification)}}</span>
                        </td>
                        <td class="tableCell" (click)="goToManageAlert(alert)">
                            <span class="mutedValue">{{alert.eventStart | date:"MMM dd, yyyy"}}
                                <div class="customTooltip">
                                    <span *ngIf="alert.eventStart">
                                        {{alert.eventStart | date:"dd/MM/YYYY, HH:mm:ss"}}
                                    </span>
                                </div>
                            </span>
                        </td>
                        <td class="tableCell" (click)="goToManageAlert(alert)">
                            <span class="mutedValue">{{alert.eventEnd | date:"MMM dd, yyyy"}}
                                <div class="customTooltip">
                                    <span *ngIf="alert.eventEnd">
                                        {{alert.eventEnd | date:"dd/MM/YYYY, HH:mm:ss"}}
                                    </span>
                                </div>
                            </span>
                        </td>
                        <td class="tableCell" (click)="goToManageAlert(alert)">
                            <span class="mutedValue">{{alert.createdOn | date:"MMM dd, yyyy"}}
                                <div class="customTooltip">
                                    <span *ngIf="alert.createdOn">
                                        {{alert.createdOn | date:"dd/MM/YYYY, HH:mm:ss"}}
                                    </span>
                                </div>
                            </span>
                            
                        </td>
                        <td class="tableCell" (click)="goToManageAlert(alert)">
                            <span class="mutedValue">{{alert.activeFor}}</span> <!--{{calculateDaysActive(alert.eventStart, alert.eventEnd)}}-->
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table> 
    </div>
    <app-pagination
    #tablePagination [currentPage]="currentPage" 
    [data]="allAlerts" [itemPerPage]="itemPerPage" (paginationDone)="handlePaginationDone($event)"
    [COLUMN_NAMES]="columnNames" [appliedFilters]="appliedFilters" [pageSizeOption]="pageSizeOption">
    </app-pagination>

