import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ALBADA_DEVICE_TYPE, DUBA_DEVICE_TYPE, PUMP_STATION_DEVICE_TYPE, waterIndustrials } from '../constant';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.css']
})
export class NotificationPopupComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    private dialogRef: MatDialogRef<NotificationPopupComponent>,) {

  }

  ngOnInit(): void {
    if (this.data["showNavigation"] === undefined) {
      this.data["showNavigation"] = true;
    }
  }

  openDashboard(dashboardId,isSavedFirstTime?) {
    this.close();
    if (this.data.usedBy === 'linkedDashboard') {
      if (this.data.deviceType.find(x => x === DUBA_DEVICE_TYPE)) {
        this.router.navigate([waterIndustrials['DubaPlantScada'].route]);
      }
      else if (this.data.deviceType.find(x => x === PUMP_STATION_DEVICE_TYPE)) {
        this.router.navigate([waterIndustrials['Pump Station'].route]);
      }
      else if (this.data.deviceType.find(x => x === ALBADA_DEVICE_TYPE)) {
        this.router.navigate([waterIndustrials['Water Recycling Plant'].route]);
      }
    }
    else {
      
      if (this.data.dashboardType && this.data.dashboardType === 'Drawing') {
        // not working when saving new Dashboard.
        this.router.navigate(['/home/']);
        setTimeout(()=>{
          this.router.navigate(['/connectioncanvas/' + dashboardId]);
        },100);
      
      }
 else {
        this.router.navigate(['/custom-dashboard/' + dashboardId]);
      }
    }
  }

  close(dashboardId?:string) {
    if(dashboardId){
      if(!this.data.subTitle.includes('widget save successfully')){
        this.router.navigate(['/home/']);
        setTimeout(()=>{
            this.router.navigate(
              ['/connectioncanvas' + dashboardId],
              { queryParams: { isEdit: true } }
            );
          },100);
        }
      }
      this.dialogRef.close(true);
      // window.location.href = `${window.location.origin}/connectioncanvas/${dashboardId}?isEdit=true`
  
  }

}
