import * as d3 from "d3";
import { chartCustomScrollhandler, convertDate, convertValues, createSubGroups, formatData, getFilterPositionNative, hideTooltip, updateAxisConfig, updateBrushHandle } from "./chart-helper";
import { v4 as uuidv4 } from 'uuid';
import * as moment from "moment";
import { lineGraphForNativeSplitYAxis } from "./line-graph-native-split-yaxis";
import { DATE_TIME_FORMAT_IGNORE_MILLISECONDS, EN_GB_LOCAL_STRING } from "../constant";
import { COLOR, alertEventColor, alertLineColor, tooltipCircleRadius, tooltipCircleStrokeOpacity, tooltipCircleStrokeWidth, filterScrollTextPadding, handleWidth, lineOpacity, tooltipLineColor, tooltipLineWidth, tooltipLineDasharray, alertEventOpacity, operationalRangeOpacity, operationalRangeColor, operationalRangeWidth, alertEventWidth, lineWidth, VPmargin, handleWidthVP, viewportWidth, viewportHeight, tickSize, alertColors } from "./chart-config";
import { formatAxisConfig, setChartConfig } from "./chart-helper";

export const lineGraphForNative = (element, data, config) => {

  if (config.splitYAxis) {
    lineGraphForNativeSplitYAxis(element, data, config);
  }
  else {
    const mainElement = d3.select(element);
    let width = mainElement.node()["clientWidth"];
    let height = mainElement.node()["clientHeight"];
    let vwheight = (parseFloat(VPmargin.ploatfilterheight) / 100) * viewportWidth;
    let vwhandleWidth = (parseFloat(handleWidthVP) / 100) * viewportWidth;
    let vhmarginleft = (parseFloat(VPmargin.yAxisMarginleft) / 100) * viewportHeight;
    let ploatFilterHeight = config.ploatFilterHeight || vwheight
    let vwmarginTop = (parseFloat(VPmargin.yAxisMarginTop) / 100) * viewportWidth;
    let vwmarginBottom = (parseFloat(VPmargin.xAxisMarginBottom) / 100) * viewportWidth;
    const guid = uuidv4();
    const brush = d3.brushX();
    let zoom;
    let handle, context, ploatFiltersvg;
    let subgroups, color;

    if (typeof (config.attributes) === "string") {
      subgroups = config.attributes;
    }
    else {
      subgroups = createSubGroups(config.attributes);
    }
    config.chartConfig = setChartConfig(config.chartConfig);

    width = width - vhmarginleft - config.chartConfig.margin.right;
    height = height - vwmarginTop - vwmarginBottom;

    if (config.showScrollbar) {
      height = height - ploatFilterHeight;
    }

    let dateList = [];
    const dataPoints = [];
    const bisectDate = d3.bisector(function (d: any) { return d; }).left;

    d3.selectAll(`${element} svg`).remove();
    d3.selectAll(`${element} .chart-tooltip`).remove();

    data = data.filter(item => {
      const row = subgroups.find(x => x.name === item.name)
      // if (row.display || row.display === undefined) {
        return item;
      // }
    })

    /* Format Data */
    data.forEach(function (d) {
      d.nativeList.forEach(function (d) {
        d.dateTime = convertDate(d.dateTime);
        d.value = +d.value;
        dateList.push(d.dateTime);
        dataPoints.push(d.value);
      });
    });

    dateList.sort(function (a, b) { return a - b });
    /* Scale */
    const xScale = d3.scaleTime()
      .domain(d3.extent(dateList))
      .range([0, width]);

    const maxValue = d3.max(dataPoints) || 10;
    const minValue = d3.min(dataPoints) || 0;

    const yScale = d3.scaleLinear()
      .domain([+minValue, +maxValue])
      .nice()
      .range([height, 0]);

    const yAxisGrid = d3.axisLeft(yScale).ticks(tickSize)
      .tickSize(-width)
      .tickFormat((d) => { return '' });

    const _x = d3.scaleTime()
      .domain(d3.extent(dateList))
      .range([0, width]);

    const _y = d3.scaleLinear()
      .range([ploatFilterHeight, 0])
      .domain([+minValue, +maxValue])
      .nice();

    color = d3.scaleOrdinal()
      .domain(subgroups.map(x => x.name || x.description))
      .range(COLOR);

    /* Add SVG */
    const svg = mainElement
      .append("svg")
      .attr('class', 'chart-plot')
      .attr("width", (width + vhmarginleft + config.chartConfig.margin.right) + "px")
      .attr("height", (height + vwmarginTop + vwmarginBottom) + "px");

    /* Add tooltip */
    const tooltip = d3.select(element)
      .append("div")
      .classed('chart-tooltip', true);

    const container = svg.append('g')
      .attr("transform", `translate(${vhmarginleft}, ${vwmarginTop})`);

    if (config.chartConfig?.axis?.yAxis?.showGrid) {
      container.append('g')
        .attr('class', 'y-axis-grid')
        .call(yAxisGrid);
    }

    const operationalRangeElement = container.append("g").attr("id", "operational").attr("clip-path", `url(#clip-${guid})`);
    operationalRangeElement.append("rect").attr("id", "react-operational");;
    operationalRangeElement.append("line").attr("id", "line-operational");

    const alertRangeElement = container.append("g").attr("id", "alertRange").attr("clip-path", `url(#clip-${guid})`);
    config.alertRange?.forEach((item) => {
      alertRangeElement.append("rect").attr("id", `react-alertRange${item.id}`);
      alertRangeElement.append("g").attr("id", "thresholdLine-alertRange")
        .selectAll('.thresholdLine')
        .data(item.thresholdValues || [])
        .enter()
        .append('line')
        .attr('class', 'thresholdLine');
      alertRangeElement.append("g").attr("id", "line-alertRange")
        .selectAll('.alertline')
        .data(item.alertDate || [])
        .enter()
        .append('line')
        .attr('class', 'alertline');
    })




    const operationalRange = () => {
      if (config.operationalRange && config.operationalRange.minValue && config.operationalRange.maxValue) {

        if (config.operationalRange && yScale.domain()[0] > config.operationalRange.minValue) {
          yScale.domain([config.operationalRange.minValue, yScale.domain()[1]]).nice();
        }

        if (config.operationalRange && yScale.domain()[1] < config.operationalRange.maxValue) {
          yScale.domain([yScale.domain()[0], config.operationalRange.maxValue]).nice();
        }

        const pointMin = yScale(config.operationalRange.minValue);
        const pointMax = yScale(config.operationalRange.maxValue);

        operationalRangeElement.select("#react-operational")
          .attr("fill", operationalRangeColor)
          .attr("opacity", operationalRangeOpacity)
          .attr("x", 0)
          .attr("y", pointMax)
          .attr("width", width)
          .attr("height", pointMin - pointMax)

        operationalRangeElement.selectAll(".targetgoal").remove();
        const target = operationalRangeElement.selectAll(".targetgoal")
          .data([config.operationalRange.minValue, config.operationalRange.maxValue])
          .enter()
          .append("g")
          .attr("transform", function (d) {
            return "translate(0, " + yScale(d) + ")"
          })

        target.append("line")
          .attr("class", "targetgoal")
          .attr("x1", 0)
          .attr("x2", width)
          .attr("y1", 0)  //these can be omitted
          .attr("y2", 0)
          .style("stroke-width", operationalRangeWidth)
          .style("stroke", operationalRangeColor);
      }

    }
    const renderAllAlertDataPoints = (config) => {
      config?.alertRange?.forEach(item => {
        alertRange(item);
      })
    };

    operationalRange();
    const alertRange = (item) => {
      if (item) {
        const pointMin = xScale(convertDate(item.startDate));
        const pointMax = xScale(convertDate(item.endDate));
        const subscribed = item?.subscibedUser;
        const color = subscribed ? alertColors['Subscribed'] : (item?.alertClassification ? alertColors[item.alertClassification] : alertEventColor);
        alertRangeElement.select(`rect#react-alertRange${item.id}`)
          .attr("fill", '#fbe5d6')
          // .style("fill-opacity", alertEventOpacity)
          .style("stroke-width", alertEventWidth)
          .style("stroke", '#fbe5d6' )
          .attr("x", pointMin)
          .attr("y", 0)
          .attr("width", pointMax - pointMin)
          .attr("height", height)

        if (item.thresholdValues && item.thresholdValues.length > 0) {
          alertRangeElement.selectAll(".thresholdLine")
            .attr("x1", 0)
            .attr("x2", width)
            .attr("y1", (d: any) => yScale(d.value))
            .attr("y2", (d: any) => yScale(d.value))
            .style("stroke-width", 2)
            .style("stroke", (d: any) => {
              return d.color || alertLineColor;
            });
        }

        alertRangeElement.selectAll(".alertline")
          .attr("x1", (d: any) => {
            return xScale(convertDate(d.alertDate))
          })
          .attr("x2", (d: any) => {
            return xScale(convertDate(d.alertDate))
          })
          .attr("y1", 0)
          .attr("y2", height)
          .style("stroke-width", 2)
          .style("stroke", (d: any) => {
            return d.color || alertLineColor;
          });
      }
    }
    renderAllAlertDataPoints(config);

    /* Add line into SVG */
    const line = d3.line()
      .x((d: any) => xScale(d.dateTime))
      .y((d: any) => yScale(d.value || 0));

    let lines = container.append('g')
      .attr('class', 'lines');

    container.append("defs").append("clipPath")
      .attr("id", `clip-${guid}`)
      .append("rect")
      .attr("x", 0)
      .attr("width", width)
      .attr("height", height);

    lines.selectAll('.line-group')
      .data(data).enter()
      .append('g')
      .attr('class', 'line-group')
      .append('path')
      .attr('class', 'line')
      .style('stroke-width', lineWidth)
      .style('fill', 'none')
      .attr("clip-path", `url(#clip-${guid})`)
      .attr('d', (d: any) => line(d.nativeList))
      .style('stroke', (d: any, i: any) => color(d.name || d.description))
      .style('opacity', lineOpacity)

    container.selectAll('.circle-tooltip')
      .data(data).enter()
      .append("circle")
      .attr('class', (d: any, i: any) => `circle-tooltip circle-tooltip-${i}`)
      .attr("r", tooltipCircleRadius)
      .style('fill', (d: any, i: any) => color(d.name || d.description))
      .style('stroke', (d: any, i: any) => color(d.name || d.description))
      .style('opacity', 0)
      .style('stroke-width', tooltipCircleStrokeWidth)
      .style('stroke-opacity', tooltipCircleStrokeOpacity)

    container
      .append("line")
      .attr("class", "line-tooltip")
      .style("stroke", tooltipLineColor)
      .style("stroke-width", tooltipLineWidth)
      .style("stroke-dasharray", tooltipLineDasharray)
      .style('opacity', 0);

    /* Add Axis into SVG */
    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    container.append("g")
      .attr("class", "axis-bottom x-axis")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis);

    container.append("g")
      .attr("class", "axis-left y-axis")
      .call(yAxis
        .ticks(tickSize)
        .tickFormat((d: any) => {
          return convertValues(d);
        }))
      .append('text')
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("fill", "#000")

    formatAxisConfig(svg, config.chartConfig);

    if (config.showScrollbar) {
      ploatFiltersvg = mainElement
        .append("svg")
        .attr("class", 'filter-plot')
        .attr("width", width + vhmarginleft + config.chartConfig.margin.right)
        .attr("height", ploatFilterHeight)
        .append('g')
        .attr("transform", `translate(${vhmarginleft},${0})`);

      brush.extent([[0, 0], [width, ploatFilterHeight]])
        .on("brush", (d) => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
          // get bounds of selection
          const s = d3.event.selection || _x.range();
          xScale.domain(s.map(_x.invert, _x));
          container.select(".x-axis").call(xAxis);
          const maxValue = formatData(data, xScale.domain());
          yScale.domain([d3.min(maxValue), d3.max(maxValue)]).nice();
          renderAllAlertDataPoints(config);
          operationalRange();
          lines.selectAll(".line")
            .attr('d', (d: any) => line(d.nativeList));
          container.select(".y-axis").call(yAxis);
          svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
            .scale(width / (s[1] - s[0]))
            .translate(-s[0], 0));

          updateAxisConfig(yAxisGrid, yScale, svg, '.y-axis-grid')
          formatAxisConfig(svg, config.chartConfig);

          updateBrushHandle(context);
          handle.attr("x", (d) => {
            const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
            return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
          })
        });

      /* Add line into SVG filter */
      const lineFilter = d3.line()
        .x((d: any) => _x(d.dateTime))
        .y((d: any) => _y(d.value));

      const xAxisFilter = d3.axisTop(_x).tickSize(0).tickPadding(filterScrollTextPadding);

      const linesFilter = ploatFiltersvg.append('g')
        .attr('class', 'lines');

      linesFilter.selectAll('.line-group')
        .data(data).enter()
        .append('g')
        .attr('class', 'line-group')
        .append('path')
        .attr('class', 'line')
        .style('stroke-width', 2)
        .style('fill', 'none')
        .attr('d', (d: any) => lineFilter(d.nativeList))
        .style('stroke', (d: any, i: any) => color(d.name || d.description))
        .style('opacity', lineOpacity)

      linesFilter.append("g")
        .attr("class", "x-axis-filter")
        .attr("transform", `translate(0, ${ploatFilterHeight})`)
        .call(xAxisFilter);

      context = ploatFiltersvg.append("g")
        .attr("class", "context")
        .attr('transform', 'translate(' + 0 + ', ' + 0 + ')');

      handle = chartCustomScrollhandler(context, brush, ploatFilterHeight, ploatFiltersvg);
    }

    zoom = d3.zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([[0, 0], [width, height]])
      .extent([[0, 0], [width, height]])
      .on("zoom", (d) => {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush

        hideTooltip(svg, tooltip);
        const t = d3.event.transform;
        xScale.domain(t.rescaleX(_x).domain());
        let diff = (xScale.domain()[1].getTime() - xScale.domain()[0].getTime()) / 1000;
        diff = diff / 60;
        if (diff > 3) {
          container.select(".x-axis").call(xAxis);
          const maxValue = formatData(data, xScale.domain());
          yScale.domain([d3.min(maxValue), d3.max(maxValue)]).nice();
          mainElement.select(".x_brush").call(brush.move, xScale.range().map(t.invertX, t));
          renderAllAlertDataPoints(config);
          operationalRange();
          lines.selectAll(".line")
            .attr('d', (d: any) => line(d.nativeList));
          container.select(".y-axis").call(yAxis);

          updateAxisConfig(yAxisGrid, yScale, svg, '.y-axis-grid')
          formatAxisConfig(svg, config.chartConfig);;

          updateBrushHandle(context);
          handle.attr("x", (d) => {
            const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
            return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
          })
        }
      });

    container.append("rect")
      .attr("class", "zoom")
      .attr("width", width)
      .attr("height", height)
      .style("fill", 'none')
      .style("cursor", 'move')
      .style("pointer-events", 'all')
      .attr("transform", `translate(${0}, ${0})`)
      .call(zoom)
      .on("mouseover", () => { })
      .on("mouseout", () => {
        hideTooltip(svg, tooltip);
      })
      .on("mousemove", () => {
        let toolTipText = '';
        const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft),
          i = bisectDate(dateList, x0, 1),
          d0 = dateList[i - 1],
          d1 = dateList[i];
        let dateTime = x0 - d0 > d1 - x0 ? d1 : d0;
        for (let index = 0; index < data.length; index++) {
          const d0 = data[index].nativeList.find(x => moment(x.dateTime).format(DATE_TIME_FORMAT_IGNORE_MILLISECONDS) === moment(dateTime).format(DATE_TIME_FORMAT_IGNORE_MILLISECONDS));
          container.select(`.circle-tooltip-${index}`)
            .attr("cx", (_d, _i) => {
              if (d0) {
                return xScale(d0.dateTime);
              }
            })
            .attr("cy", (_d: any, _i) => {
              if (d0) {
                toolTipText += `<p> ${dateTime.toLocaleString(EN_GB_LOCAL_STRING)} </p><p> <span class='legend-box' style='background-color:${color(_d.name || _d.description)}'></span> ${_d.name || _d.description} : ${d0.value.toFixed(3)} </p>`;
                return yScale(d0.value);
              }
            })
            .style("opacity", (_d: any, _i) => {
              if (d0) {
                return 1;
              }
              return 0;
            })
        }

        config.alertRange?.forEach((item) => {
          if(item.alertDate[0].alertDate || item.alertDate[1].alertDate){
              toolTipText += item.alertDate[1].alertDate? `<p>Reported by AI Alert : ${moment(item.alertDate[1].alertDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`:'';
              toolTipText += item.alertDate[0].alertDate ? `<p>Reported by Device : ${moment(item.alertDate[0].alertDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`:'';
              toolTipText += `<p>${item.subscibedUser ? 'Subscribed' : 'Created'} Alert Classification : ${item.alertClassification || 'N/A'} </p>`;
          }else  if (item && (dateTime >= convertDate(item.startDate) && dateTime <= convertDate(item.endDate))) {
                toolTipText += `<p>${item.subscibedUser ? 'Subscribed' : 'Created'} Alert Start Time : ${moment(item.startDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`;
                toolTipText += item.isActive ? '' : `<p>${item.subscibedUser ? 'Subscribed' : 'Created'} Alert End Time : ${moment(item.endDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`;
                toolTipText += `<p>${item.subscibedUser ? 'Subscribed' : 'Created'} Alert Classification : ${item.alertClassification || 'N/A'} </p>`;
              }
        })

        if (config.operationalRange) {
          let i = bisectDate(dateList, x0, 1),
            d0 = data[0].nativeList[i - 1],
            d1 = data[0].nativeList[i];
          let d = x0 - d0?.dateTime > d1?.dateTime - x0 ? d1 : d0;
          if (d.value >= config.operationalRange.minValue && d.value <= config.operationalRange.maxValue) {
            toolTipText += `<p>Lower Limit : ${config.operationalRange.minValue}</p>`;
            toolTipText += `<p>Upper Limit : ${config.operationalRange.maxValue}</p>`;
          }
        }

        container.selectAll('.line-tooltip')
          .attr("x1", xScale(dateTime))
          .attr("y1", 0)
          .attr("x2", xScale(dateTime))
          .attr("y2", height)
          .style('opacity', 1);

        tooltip
          .style("opacity", "0.89")
          .html(toolTipText)

        tooltip.node().style.right = 'auto';
        tooltip.node().style.top = 'auto';
        tooltip.node().style.left = 'auto';

        let width = svg.node().clientWidth;
        let tipWidth = tooltip.node().offsetWidth;
        let tipHeight = tooltip.node().offsetHeight;
        if (width - xScale(dateTime) < tipWidth) {
          tooltip.node().style.right = (width - xScale(dateTime)) - vhmarginleft + 10 + 'px';
          tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
          tooltip.node().style.left = 'auto';
          tooltip.classed('chart-tooltip-right', true);
          tooltip.classed('chart-tooltip-left', false);
        }
        else {
          tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
          tooltip.node().style.left = xScale(dateTime) + vhmarginleft + 10 + 'px';
          tooltip.node().style.right = 'auto';
          tooltip.classed('chart-tooltip-right', false);
          tooltip.classed('chart-tooltip-left', true);
        }
      });
    ploatFiltersvg?.select('.x_brush').call(brush.move, _x.range());
  }
}
