import * as d3 from "d3";
import { chartCustomScrollhandler, convertDate, convertValues, createSubGroups, formatAxisConfig, formatDataAggregation, getAxisTickWidth, getFilterPosition, getFormatedDate, getTimeStamp, hideTooltip, processDataForChart, setChartConfig,  updateAxisConfig } from "./chart-helper";
import { v4 as uuidv4 } from 'uuid';
import { COLOR, tooltipCircleRadius, handleWidth, lineOpacity, tooltipLineDasharray, tooltipLineWidth, tooltipLineColor, lineWidth, tooltipCircleStrokeWidth, tooltipCircleStrokeOpacity, VPmargin, viewportWidth, viewportHeight, handleWidthVP, tickSize } from "./chart-config";

export const lineGraphForAggregationSplitYAxis = (element, widgetData, config) => {

    const mainElement = d3.select(element);
    let width = mainElement.node()["clientWidth"];
    let height = mainElement.node()["clientHeight"];
    const brush = d3.brushX();
    const guid = uuidv4()
    let handle, subgroups, color, xScale, _x, zoom;
    let vwheight = (parseFloat(VPmargin.ploatfilterheight) / 100) * viewportWidth;
    let vhmarginleft =  (parseFloat(VPmargin.yAxisMarginleft) / 100) * viewportHeight;
    let vwmarginTop = (parseFloat(VPmargin.yAxisMarginTop) / 100) * viewportWidth;
    let vwhandleWidth = (parseFloat(handleWidthVP) / 100) * viewportWidth;
    let vwmarginBottom = (parseFloat(VPmargin.xAxisMarginBottom) / 100) * viewportWidth;
    let ploatFilterHeight = config.ploatFilterHeight || vwheight;
    config.chartConfig = setChartConfig(config.chartConfig);
    if (typeof (config.attributes) === "string") {
        subgroups = config.attributes;
    }
    else {
        subgroups = createSubGroups(config.attributes);
    }
    width = width - vhmarginleft - config.chartConfig.margin.right;
    height = height - vwmarginTop - vwmarginBottom;

    if (config.showScrollbar) {
        height = height - ploatFilterHeight;
    }

    d3.selectAll(`${element} svg`).remove();
    d3.selectAll(`${element} .chart-tooltip`).remove();

    const _data = processDataForChart(widgetData, config.widgetType, subgroups)
    const groups = _data.groups;
    const data = _data.data;

    /* Add tooltip */
    const tooltip = mainElement
        .append("div")
        .classed('chart-tooltip', true);

    /* Add SVG */
    const svg = mainElement
        .append("svg")
        .attr('class', 'chart-plot')
        .attr("width", (width + vhmarginleft + config.chartConfig.margin.right) + "px")
        .attr("height", (height + vwmarginTop + vwmarginBottom) + "px");

    /* Add Axis into SVG */

    xScale = d3.scaleBand()
        .range([0, width])
        .domain(groups.map(x => x.key))
        .padding(0.2);

    _x = d3.scaleBand()
        .range([0, width])
        .padding(0.2)
        .domain(groups.map(x => x.key));

    xScale.invert = function (x) {
        const domain = this.domain();
        const range = this.range()
        const scale = d3.scaleQuantize().domain(range).range(domain)
        return scale(x)
    }

    color = d3.scaleOrdinal()
        .domain(subgroups.map(x => x.name || x.description))
        .range(COLOR);

    const xAxis = d3.axisBottom(xScale);

    svg.append("defs").append("clipPath")
        .attr("id", `clip-${guid}`)
        .append("rect")
        .attr("x", 0)
        .attr("width", width)
        .attr("height", height);

    svg.append("g")
        .attr("class", `axis-bottom x-axis`)
        .attr("transform", `translate(${vhmarginleft}, ${height - vwmarginTop})`)
        .attr("clip-path", `url(#clip-${guid})`)
        .call(xAxis);

    const formatXAxis = () => {

        svg.selectAll(".x-axis").call(d3.axisBottom(xScale)
            .tickFormat((d, i) => {
                const tickFormat = config.widgetType === "linechart" ? data[0].values.find(x => x.time === d).date : data[i].date;
                return getFormatedDate(tickFormat, config.aggregationInterval);
            })
        );
        // const totalTick = getAxisTickWidth(svg, xScale);
        const totalTick = 5
        const selectPoint = Math.floor(xScale.domain().length / totalTick);
        svg.selectAll(".x-axis").call(d3.axisBottom(xScale)
            .tickFormat((d, i) => {
                if (i % selectPoint === 0 || selectPoint === 0) {
                    const tickFormat = config.widgetType === "linechart" ? data[0].values.find(x => x.time === d).date : data[i].date;
                    return getFormatedDate(tickFormat, config.aggregationInterval);
                }
                else {
                    return '';
                }
            })
        );

        svg.selectAll(".x-axis .tick line").each(function (d, i) {
            if (i % selectPoint === 0 || selectPoint === 0) {
                d3.select(this).style('opacity', 1)
            }
            else {
                d3.select(this).style('opacity', 0)
            }
        });
    }

    formatXAxis();

    const yAxis = [];
    const yScale = [];
    const line = [];
    const yAxisGrid = [];
    // let ticksSize = Math.floor(((height / data.length) - (vwmarginTop * data.length)) / 20);
    // ticksSize = ticksSize <= 0 ? 2 : ticksSize;

    const attributes = config.attributes.filter(res => {
        if (res.display || res?.display === undefined) {
            return res
        }
    })

    widgetData = widgetData.filter(item => {
        const row = subgroups.find(x => x.name === item.name)
        if (row.display || row.display === undefined) {
            return item;
        }
    })

    attributes.forEach((_element, index) => {
        let _height = (height / data.length);
        const container = svg.append('g')
            .attr("transform", (d) => {
                return `translate(${vhmarginleft}, ${vwmarginTop + ((index * _height))}  )`
            });

        /* Scale */
        const maxValue = d3.max(data[index].values.map(x => parseFloat(x.value))) || 10;
        const minValue = d3.min(data[index].values.map(x => parseFloat(x.value))) || 0;

        yScale[index] = d3.scaleLinear()
            .domain([+minValue, +maxValue])
            .range([_height - vwmarginBottom, vwmarginTop])
            .nice();

        yAxis[index] = d3.axisLeft(yScale[index]).ticks(tickSize).tickFormat((d: any) => {
            return convertValues(d);
        });
        yAxisGrid[index] = d3.axisLeft(yScale[index]).ticks(tickSize).tickSize(-width).tickFormat((d) => { return '' });

        line[index] = d3.line()
            .x((d: any) => {
                return (xScale(d.time)) + xScale.bandwidth() / 2
            })
            .y((d: any) => yScale[index](d.value))
            .defined((d: any, i) => {
                return xScale(d.time) !== undefined;
            });

        container.append('g')
            .attr('class', `y-axis-grid y-axis-grid-${index}`)
            .call(yAxisGrid[index]);

        container.append("path")
            .datum(data[index].values)
            .attr('class', 'line')
            .style('stroke-width', lineWidth)
            .style('stroke', (d: any, i: any) => color(_element.name || _element.description))
            .style('opacity', lineOpacity)
            .style('fill', 'none')
            .attr("clip-path", `url(#clip-${guid})`)
            .attr('d', (d: any) => line[index](data[index].values));

        container.append("g")
            .attr("class", `axis-left y-axis y-axis-${index}`)
            .call(yAxis[index])
            .append('text')
            .attr("y", 15)
            .attr("transform", "rotate(-90)")
            .attr("fill", "#000")

        formatAxisConfig(svg, config.chartConfig);

        container
            .append("circle")
            .attr('class', (d: any, i: any) => `circle-tooltip circle-tooltip-${index}`)
            .attr("r", tooltipCircleRadius)
            .style('fill', (d: any, i: any) => color(_element.name || _element.description))
            .style('stroke', (d: any, i: any) => color(_element.name || _element.description))
            .style('opacity', 0)
            .style('stroke-width', tooltipCircleStrokeWidth)
            .style('stroke-opacity', tooltipCircleStrokeOpacity)

        if (index + 1 === attributes.length) {
            let ploatFiltersvg;
            zoom = d3.zoom()
                .scaleExtent([1, Infinity])
                .translateExtent([[0, 0], [width, height]])
                .extent([[0, 0], [width, height]])
                .on("zoom", (d) => {
                    if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
                    hideTooltip(svg, tooltip)
                    const t = d3.event.transform;
                    xScale.range([0, width].map(d => {
                        return d3.event.transform.applyX(d)
                    }));
                    xScale.domain(_x.domain());
                    svg.selectAll(".x-axis").call(d3.axisBottom(xScale).tickFormat((d, i) => {
                        const tickFormat = config.widgetType === "linechart" ? data[0].values.find(x => x.time === d).date : data[i].date;
                        return getFormatedDate(tickFormat, config.aggregationInterval);
                    }));

                    formatXAxis();

                    svg.selectAll(".line").attr('d', (d: any, i) => {
                        const maxValue = formatDataAggregation([widgetData[i]], width - vhmarginleft - config.chartConfig.margin.right, xScale, subgroups.filter(x => x.name === widgetData[i].name));
                        yScale[i].domain([d3.min(maxValue), d3.max(maxValue)]).nice();
                        svg.select(`.y-axis-${i}`).call(yAxis[i]);
                        updateAxisConfig(yAxisGrid[i], yScale[i], svg, `.y-axis-grid-${i}`);
                        formatAxisConfig(svg, config.chartConfig);
                        svg.selectAll(`.circle-points-${i}`)
                            .style("opacity", function (d: any) {
                                return xScale.domain().indexOf(d.time) === -1 ? 0 : 100;
                            })
                            .attr("cx", (d: any) => {
                                return xScale(d.time) + xScale.bandwidth() / 2
                            })
                            .attr("cy", (d: any) => yScale[i](d.value))
                            .attr("r", tooltipCircleRadius);

                        return line[i](d);
                    })

                    ploatFiltersvg?.select(".x_brush")?.call(brush?.move, _x.range()?.map(t.invertX, t));

                    handle?.attr("x", (d) => {
                        const x = parseFloat(mainElement?.select(".x_brush")?.select(`.handle--${d.type}`)?.style('x'));
                        return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
                    })
                });
            svg
                .append("line")
                .attr("class", "line-tooltip")
                .attr("transform", `translate(${vhmarginleft}, ${0})`)
                .style("stroke", tooltipLineColor)
                .style("stroke-width", tooltipLineWidth)
                .style("stroke-dasharray", tooltipLineDasharray)
                .style('opacity', 0);


                svg
                .append("line")
                .attr("class", "location-line")
                .attr("transform", `translate(${vhmarginleft}, ${0})`)
                .style("stroke", tooltipLineColor)
                .style("stroke-width", tooltipLineWidth)
                .style('opacity', 0);


                svg
                .append ("circle")
                .attr("class", "location-first-circle")
                .attr("transform", `translate(${vhmarginleft}, ${0})`)
                .attr("r", 15)
                .style('fill', "#000000")
                .style('cursor', 'pointer')
                .style('opacity', 0);

            
                svg
                .append ("circle")
                .attr("class", "location-second-circle")
                .attr("transform", `translate(${vhmarginleft}, ${0})`)
                .attr("r", 15)
                .style('fill', "#000000")
                .style('cursor', 'pointer')
                .style('opacity', 0);


                // svg.append("image")
                // .attr("class", "location-image")
                // .attr("transform", `translate(${vhmarginleft}, ${0})`)
                // .attr("width", 30)            
                // .attr("height", 30)           
                // .attr("xlink:href", "assets/Neom-Images/location-HMI.svg")
                // .style("cursor", 'pointer');

                svg
                .append("line")
                .attr("class", "location-line-second")
                .attr("transform", `translate(${vhmarginleft}, ${0})`)
                .style("stroke", tooltipLineColor)
                .style("stroke-width", tooltipLineWidth)
                .style('opacity', 0);

                
            const tipMouseMove = () => {
                let toolTipText = '';
                const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft);
                let d = data[0].values.findIndex(x => x.time === x0);
                if (config.aggregationType === "min" || config.aggregationType === "max") {
                    toolTipText = `<p>${data[0].values[d].date.toLocaleString('en-GB')} </p>`;
                    data.forEach((element, index) => {
                        const device = config.attributes.find(x => x.name === element.name || x.description === element.name);
                        if (element.values[d].timestamp) {
                            toolTipText += `<p><span class='legend-box'  style='background-color:${color(element.name)}'></span> <span>${' '}${element.name} : ${element.values[d]?.value?.toFixed(3)}, </span> <span id='timestamp_${index}'>${element.values[d].timestamp.toLocaleString('en-GB')}</span> </p>`;
                        } else {
                            toolTipText += `<div> <span class='legend-box'  style='background-color:${color(element.name)}'></span> <span>${' '}${element.name} : ${element.values[d]?.value?.toFixed(3)}, </span><span id='timestamp_${index}'> <div class="loader"></div></span></div>`;
                            if (!element.values[d].isLoading) {
                                element.values[d].isLoading = true;
                                getTimeStamp(element.values[d], config, device).then((res: any) => {
                                    element.values[d].timestamp = convertDate(res.data);
                                    const currentPosition = parseFloat(svg.select('.line-tooltip').attr("x1")).toFixed(3);
                                    const requestPosition = (xScale(data[0].values[d].time) + xScale.bandwidth() / 2).toFixed(3);
                                    console.log(currentPosition, requestPosition);
                                    if (currentPosition === requestPosition) {
                                        tooltip.select(`#timestamp_${index}`).html(element.values[d].timestamp.toLocaleString('en-GB'));
                                    }
                                });
                            }
                        }
                    });
                } else {
                    toolTipText = `<p>${data[0]?.values[d]?.date.toLocaleString('en-GB')} </p>`;
                    data.forEach(element => {
                            toolTipText += `<p><span class='legend-box' style='background-color:${color(element?.name)}'></span>${' '} ${element?.name} : ${element?.values[d]?.value?.toFixed(3)}</p>`;
                    });
                }

                svg.selectAll('.line-tooltip')
                    .attr("x1", xScale(data[0].values[d].time) + xScale.bandwidth() / 2)
                    .attr("y1", 0)
                    .attr("x2", xScale(data[0].values[d].time) + xScale.bandwidth() / 2)
                    .attr("y2", height)
                    .style('opacity', 1);

                svg.selectAll(`.circle-tooltip`)
                    .attr("cx", (_d, i) => {
                        return xScale(data[0].values[d]?.time) + xScale.bandwidth() / 2;
                    })
                    .attr("cy", (_d: any, i) => {
                        return yScale[i](data[i]?.values[d]?.value || 0);
                    })
                    .style("opacity", (_d: any, i) => {
                        if (yScale[i](data[i]?.values[d]?.value)) {
                            return 1;
                        }
                        return 0;
                    });

                tooltip
                    .style("opacity", "0.89")
                    .html(toolTipText);

                tooltip.node().style.right = 'auto';
                tooltip.node().style.top = 'auto';
                tooltip.node().style.left = 'auto';

                let width = svg.node().clientWidth;
                let tipWidth = tooltip.node().offsetWidth;
                let tipHeight = tooltip.node().offsetHeight;
                if (width - (xScale(data[0].values[d].time) + xScale.bandwidth() / 2) < tipWidth) {
                    tooltip.node().style.right = (width - (xScale(data[0].values[d].time) + xScale.bandwidth() / 2)) - vhmarginleft + 10 + 'px';
                    tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
                    tooltip.node().style.left = 'auto';
                    tooltip.classed('chart-tooltip-right', true);
                    tooltip.classed('chart-tooltip-left', false);
                } else {
                    tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
                    tooltip.node().style.left = (xScale(data[0].values[d].time) + xScale.bandwidth() / 2) + vhmarginleft + 10 + 'px';
                    tooltip.node().style.right = 'auto';
                    tooltip.classed('chart-tooltip-right', false);
                    tooltip.classed('chart-tooltip-left', true);
                }
            };
            const locationLine = () => {
                
                const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft);
                let d = data[0].values.findIndex(x => x.time === x0);
                locationStartDate = data[0].values[d].dateRange[0];
            
                const xPos = xScale(data[0].values[d].time) + xScale.bandwidth() / 2;
            
                svg.selectAll('.location-line')
                    .attr("x1", xPos)
                    .attr("y1", 0)
                    .attr("x2", xPos)
                    .attr("y2", height)
                    .style('opacity', 1);
            
                svg.selectAll(`.location-first-circle`)
                    .attr("cx", xPos)
                    .style("opacity", 1);
            
            
                svg.selectAll('.location-first-circle').on('.drag', null);
                svg.selectAll('.location-line').on('.drag', null);
            
                const dragHandler = d3.drag()
                    .on("drag", function (_d) {
                        const event = d3.event;
                        const newX = Math.max(0, Math.min(width, event.x)); // Constrain dragging within bounds
            
                        svg.selectAll('.location-line')
                            .attr("x1", newX)
                            .attr("x2", newX);
            
                        svg.selectAll('.location-first-circle')
                            .attr("cx", newX);
            
                        tipMouseMove();
                    })
            
                svg.selectAll('.location-first-circle').call(dragHandler);
                svg.selectAll('.location-line').call(dragHandler);
            
                svg.selectAll('.location-line-second')
                    .style('opacity', 0);
            };

            

            // const locationLine = () => {
            //     const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft);
            //     let d = data[0].values.findIndex(x => x.time === x0);
            //     locationStartDate = data[0].values[d].dateRange[0];
            //     console.log('height---->', height);
            //     svg.selectAll('.location-line')
            //         .attr("x1", xScale(data[0].values[d].time) + xScale.bandwidth() / 2)
            //         .attr("y1", 0)
            //         .attr("x2", xScale(data[0].values[d].time) + xScale.bandwidth() / 2)
            //         // .attr('font-family', 'Arial')
            //         //     .attr('font-size', `${closeIconSize}px`)
            //         //     .attr('fill', '#000') 
            //         //     .attr('text-anchor', 'middle')
            //         //     .attr('dominant-baseline', 'middle')
            //         //     .style('cursor', 'pointer')
            //         //     .text('×')
            //         .attr("y2", height)
            //         .style('opacity', 1) ;

            //         svg.selectAll(`.circle-tooltip2`)
            //         .attr("cx", (_d, i) => {
            //             return xScale(data[0].values[d]?.time) + xScale.bandwidth() / 2
            //         })
            //         // .attr("cy", (_d: any, i) => {
            //         //     return 100
            //         // })
            //         .style("opacity", (_d: any, i) => {
            //             // if (yScale[i](data[i]?.values[d]?.value)) {
            //                 return 1;
            //             // }
            //         })

            //         console.log('data[0].values----->', data[0].values);
            //         // svg.selectAll('.close-icon')
            //         //     .data(data[0].values)  
            //         //     .enter()
            //         //     .append('text')
            //         //     .attr('class', 'close-icon')
            //         //     .attr('x', xScale(data[0].values[d].time) + xScale.bandwidth() / 2)
            //         //     .attr('y', -5) 
            //         //     .attr('font-family', 'Arial')
            //         //     .attr('font-size', `${closeIconSize}px`)
            //         //     .attr('fill', '#000') 
            //         //     .attr('text-anchor', 'middle')
            //         //     .attr('dominant-baseline', 'middle')
            //         //     .style('cursor', 'pointer')
            //         //     .text('×')
            //         //     .on('click', function (event, d) {
            //         //         console.log('Close icon clicked for data:', d);
            //         //     });

            //     svg.selectAll('.location-line-second'location-first-)
            //     .style('opacity', 0);
            // };


            // when drag circle-tooltip2 with drag location-line also
            // const locationLine = () => {
            //     const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft);
            //     let d = data[0].values.findIndex(x => x.time === x0);
            //     locationStartDate = data[0].values[d].dateRange[0];
            
            //     // Update the location line
            //     svg.selectAll('.location-line')
            //         .attr("x1", xScale(data[0].values[d].time) + xScale.bandwidth() / 2)
            //         .attr("y1", 0)
            //         .attr("x2", xScale(data[0].values[d].time) + xScale.bandwidth() / 2)
            //         .attr("y2", height)
            //         .style('opacity', 1);

            //     svg.selectAll(`.circle-tooltip2`)
            //         .attr("cx", (_d, i) => {
            //             return xScale(data[0].values[d]?.time) + xScale.bandwidth() / 2
            //         })
            //         .style("opacity", (_d: any, i) => {
            //             return 1;
            //         })
            
            //     console.log('data[0].values----->', data[0].values);
            
            //     svg.selectAll('.circle-tooltip2').on('.drag', null);
            //     svg.selectAll('.location-line').on('.drag', null);

            //     const dragHandler = d3.drag()
            //     .on("drag", function (_d) {
            //         const event = d3.event; 
            //         console.log('event------>', event);
            //         d3.select(this)
            //             .attr("cx", event.x)
            //             .attr("cy", event.y);
            //     });

            //     const dragHandler2 = d3.drag()
            //     .on("drag", function (_d) {
            //         const event = d3.event; 
            //         console.log('event------>', event);
            //         d3.select(this)
            //             .attr("cx", event.x)
            //             .attr("cy", event.y);
            //     });
            
            //     svg.selectAll('.circle-tooltip2').call(dragHandler);
            //     svg.selectAll('.location-line').call(dragHandler2);
            
            //     svg.selectAll('.location-line-second')
            //         .style('opacity', 0);
            // };


            // new -----------
        
            
            const secondlocationLine = () => {
                const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft);
                let d = data[0].values.findIndex(x => x.time === x0);
                locationEndDate = data[0].values[d].dateRange[0];
                const xPos = xScale(data[0].values[d].time) + xScale.bandwidth() / 2;
                svg.selectAll('.location-line-second')
                    .attr("x1", xPos)
                    .attr("y1", 0)
                    .attr("x2", xPos)
                    .attr("y2", height)
                    .style('opacity', 1);


                    svg.selectAll(`.location-second-circle`)
                    .attr("cx", xPos)
                    .style("opacity", 1);
            
                svg.selectAll('.location-second-circle').on('.drag', null);
                svg.selectAll('.location-line-second').on('.drag', null);
            
                const dragHandler = d3.drag()
                    .on("drag", function (_d) {
                        const event = d3.event;
                        const newX = Math.max(0, Math.min(width, event.x)); // Constrain dragging within bounds
            
                        svg.selectAll('.location-line-second')
                            .attr("x1", newX)
                            .attr("x2", newX);
            
                        svg.selectAll('.location-second-circle')
                            .attr("cx", newX);
            
                        tipMouseMove();
                    })
                    .on("end", function() {
                        svg.selectAll('.zoom')
                            .on("mousemove", tipMouseMove)
                            .on("mouseover", tipMouseMove)
                            .on("mouseout", () => hideTooltip(svg, tooltip));
                    });
            
                svg.selectAll('.location-second-circle').call(dragHandler);
                svg.selectAll('.location-line-second').call(dragHandler);
            
            };

           let locationStartDate;
           let locationEndDate;
            let firstclick = false;
            let secondClick = false;
            svg.append("rect")
                .attr("class", "zoom")
                .attr("width", width)
                .attr("height", height)
                .style("fill", 'none')
                .style("cursor", 'move')
                .style("pointer-events", 'all')
                .attr("transform", `translate(${vhmarginleft}, ${0})`)
                .call(zoom)
                .on("mouseover", tipMouseMove)
                .on("mouseout", () => {
                    hideTooltip(svg, tooltip)
                })
                .on("mousemove", tipMouseMove)
                .on("click", () => {
                    if (!firstclick) {
                        locationStartDate = '';
                        locationEndDate = '';
                        locationLine();
                        firstclick = true;
                    } else if (!secondClick) {
                        secondlocationLine()
                        secondClick = true;
                    }
                })
 
            if (config.showScrollbar) {
                ploatFiltersvg = d3.select(element)
                    .append("svg")
                    .attr("class", 'filter-plot')
                    .attr("width", width + vhmarginleft + config.chartConfig.margin.right)
                    .attr("height", ploatFilterHeight)

                const filterMain = ploatFiltersvg.append('g')
                    .attr("transform", `translate(${vhmarginleft},${0})`);

                brush.extent([[0, 0], [width, ploatFilterHeight]])
                    .on("brush", (d) => {
                        if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom

                        const extentX = d3.event.selection;
                        const selected = _x
                            .domain()
                            .filter(d => (extentX[0] - _x.bandwidth() + 1e-2 <= _x(d)) && (_x(d) <= extentX[1] - 1e-2));

                        xScale.domain(selected)
                            .range([0, width]);

                        svg.selectAll(".x-axis").call(d3.axisBottom(xScale)
                            .tickFormat((d, i) => {
                                const tickFormat = config.widgetType === "linechart" ? data[0].values.find(x => x.time === d).date : data.find(x => x.group === d).date;
                                return getFormatedDate(tickFormat, config.aggregationInterval);
                            })
                        );

                        formatXAxis();

                        svg.selectAll(".line").attr('d', (d: any, i) => {
                            const maxValue = formatDataAggregation([widgetData[i]], width - vhmarginleft - config.chartConfig.margin.right, xScale, subgroups.filter(x => x.name === widgetData[i].name));
                            yScale[i].domain([d3.min(maxValue), d3.max(maxValue)]).nice();
                            svg.select(`.y-axis-${i}`).call(yAxis[i]);
                            updateAxisConfig(yAxisGrid[i], yScale[i], svg, `.y-axis-grid-${i}`);
                            formatAxisConfig(svg, config.chartConfig);
                            svg.selectAll(`.circle-points-${i}`)
                                .style("opacity", function (d: any) {
                                    return xScale.domain().indexOf(d.time) === -1 ? 0 : 100;
                                })
                                .attr("cx", (d: any) => {
                                    return xScale(d.time) + xScale.bandwidth() / 2
                                })
                                .attr("cy", (d: any) => yScale[i](d.value))
                                .attr("r", tooltipCircleRadius);

                            return line[i](d);
                        })

                        svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
                            .scale(width / (extentX[1] - extentX[0]))
                            .translate(-extentX[0], 0));

                        handle.attr("x", (d) => {
                            const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
                            return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
                        })
                    });

                let context = filterMain.append("g")
                    .attr("class", "context")
                    .attr('transform', 'translate(' + 0 + ', ' + 0 + ')');

                handle = chartCustomScrollhandler(context, brush, ploatFilterHeight, ploatFiltersvg);
                ploatFiltersvg.select('.x_brush').call(brush.move, getFilterPosition(widgetData, _x));
                handle.attr("x", (d) => {
                    const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
                    return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
                })
            }
        }
    });
}
