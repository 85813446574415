<div *ngIf="mapLoader" class=" spinner">
    <div class="loader"></div>
</div>  

<div class="map-container" #mapViewNode>
</div>

<div *ngIf="mapInitialized && !mapLoader && selectedDeviceType !== DEVICE_TYPE_ENERGY_METER" id="rightOptions" class="mapOptions customTab">
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="showData">
        <mat-button-toggle value="devices" [checked]="true" (click)="selectOption('devices')">Devices</mat-button-toggle>
        <mat-button-toggle value="gateways" (click)="selectOption('gateways')">Gateways</mat-button-toggle>
    </mat-button-toggle-group>
</div>