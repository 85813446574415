import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges
} from "@angular/core";
import { loadModules } from "esri-loader";
import { ArcgisMapService } from 'src/app/services/arcgis-map/arcgis-map.service';
import { MapInfo } from "src/app/services/arcgis-map/interfaces";
import { ZoneMapService } from "./zone-map.service";
import { RegistrationService } from "src/app/reusable/services/registration.service";
import { ArcgisMapsDeviceConfigurationsService } from "src/app/services/arcgis-map/devices-fields-template-renderer/arcgis-maps-device-configurations.service";
import * as moment from "moment";
import { DATE_TIME_FORMAT, DEVICE_TYPE_ENERGY_METER } from "src/app/reusable/constant";
import { NC1_BASE_CAMP, SINDALAH_ISLAND, SYSTEM_TYPE_DEVICES, SYSTEM_TYPE_GATEWAYS } from "src/app/reusable/siteConstants";
import { createCustomPaginationForPopupHeader } from "src/app/services/arcgis-map/arcgis-map-templates";
import { TelemetryService } from "src/app/reusable/services/telemetry.service";
import { DEVICES, GATEWAYS, ZONES } from "./constant";


@Component({
  selector: 'app-zone-map',
  templateUrl: './zone-map.component.html',
  styleUrls: ['./zone-map.component.css', '../../../../../assets/Reusable-CSS/main.scss']
})
export class ZoneMapComponent implements OnInit {
  @ViewChild('mapViewNode', { static: true }) private mapViewEl: ElementRef;
  @Input() selectedSites:{ [key: string]: string[] };
  @Input() selectedDeviceType;

  mapInfo:MapInfo = {
    center: [35.110818, 28.11297],
    zoom: 16,
  } 
  zoomWatcher;
  currentZoom;
  mapInitialized = false;
  mapLoader = false;
  view;
  map;
  hitTestHandles;
  selectedGeometry;

  // featurelayers used
  zoneGioFencingGraphicLayer;
  zoneOrClusterLabelGraphicLayer
  devicesFeatureLayer;
  gatewaysFeatureLayer;
  selectedFeatureLayer:any;

  zones = ZONES
  sitesNameArr;
  recentSelectedSite;

  currentIndex = 0;
  totalOverllapedDevice = [];
  systemType = SYSTEM_TYPE_DEVICES;
  showData = DEVICES;
  DEVICE_TYPE_ENERGY_METER = DEVICE_TYPE_ENERGY_METER;
  GATEWAYS = GATEWAYS


  constructor(
    private arcgisMapService:ArcgisMapService,
    private zoneMapService: ZoneMapService,
    private registrationService: RegistrationService,
    private arcgisMapsDeviceConfigurationsService: ArcgisMapsDeviceConfigurationsService,
    private telemetryService: TelemetryService
  ) { }

  async ngOnInit() {
    await this.arcgisMapService.initializeMap(this.mapViewEl, this.mapInfo);
    this.map = this.arcgisMapService.getMapInstance();
    this.view = this.arcgisMapService.getViewInstance();
    this.addGioFencingOfZones();
    this.updateCenterOfMap();
    this.updateMapData(this.selectedSites);
    this.addViewWatchZoomEvent();
    this.addHitTestEvent();    
    this.mapInitialized = true;
    this.view.ui.add(document.getElementById('rightOptions'), 'top-left');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedSites'] && !changes['selectedSites'].firstChange) {
      // Detect changes in the mapData and update the map accordingly
      this.removeAllLayer();
      this.updateCenterOfMap();
      this.addGioFencingOfZones();
      this.updateMapData(this.selectedSites);
    }
  }

  updateCenterOfMap() {
    this.sitesNameArr = Object.keys(this.selectedSites);
    this.recentSelectedSite = this.sitesNameArr[this.sitesNameArr.length-1];
    if(!this.zones[this.recentSelectedSite])
        return;

    const center = this.zones[this.recentSelectedSite].center;
    this.arcgisMapService.setMapInfo({zoom: 16, center});
  }

  async addGioFencingOfZones() {
    const [GraphicsLayer] = await loadModules([
      "esri/layers/GraphicsLayer",
    ]);

    this.map.remove(this.zoneGioFencingGraphicLayer);

    this.zoneGioFencingGraphicLayer = new GraphicsLayer();
    this.zoneOrClusterLabelGraphicLayer = new GraphicsLayer();

    this.map.addMany([this.zoneGioFencingGraphicLayer, this.zoneOrClusterLabelGraphicLayer]);
    const uniqueZonesOrCluster = new Set<string>();

    // Iterate over each array in selectedSites and add each item to the Set
    Object.values(this.selectedSites).forEach((data) => {
        data.forEach(zoneOrClusterName => uniqueZonesOrCluster.add(zoneOrClusterName));
    });

    // Convert the Set to an array of unique zone or cluster names
    const uniqueZonesOrClusterArr = Array.from(uniqueZonesOrCluster);
    uniqueZonesOrClusterArr.forEach(async (zoneOrCluster:string) => {
      const data = this.zones[NC1_BASE_CAMP].data[zoneOrCluster] || undefined;
      if(!data) return ;

      const rectange = await this.zoneMapService.getRectangleGraphic(data);
      const textLabelGraphics = await this.zoneMapService.getTextLabelGraphics(data);
      
      this.zoneGioFencingGraphicLayer.add(rectange);
      this.zoneOrClusterLabelGraphicLayer.add(textLabelGraphics);
    })
  }

  async updateMapData(selectedSites) {
    this.fetchAndUpdateGatewaysDataOnMap(selectedSites);

    // first fetch devices and there parameters and consumption
    await this.fetchAndUpdateDevicesDataOnMap(selectedSites);
    //fetch parameters and consumptions data
    Object.keys(selectedSites).forEach(site =>{
      this.getFacilitiesParameterData(site, this.selectedDeviceType);
      this.getConsumptionsOfDevices(site);
    });
  }

  async fetchAndUpdateGatewaysDataOnMap(selectedSites) {
    const payload = {
      filter: selectedSites,
      deviceType: this.selectedDeviceType
    }
    const gatewaysData = await this.registrationService.getGatewayDetailsBySiteAndDeviceType(payload).toPromise();
    const graphics =  await this.zoneMapService.addGatewaysData(gatewaysData, this.getSystemType());
    this.addGatwaysOnFeatureLayer(graphics);
  }
  
  
  async addGatwaysOnFeatureLayer(graphics) {
    const [FeatureLayer, SimpleMarkerSymbol, UniqueValueRenderer] = await loadModules([
      "esri/layers/FeatureLayer",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/renderers/UniqueValueRenderer"
    ]);

    this.gatewaysFeatureLayer = new FeatureLayer({
      source: graphics,
      fields: this.arcgisMapsDeviceConfigurationsService.createFields(this.getSystemType()),
      objectIdField: 'oid',
      geometryType: 'point',
      id: 'gatewaysFeatureLayer',
      spatialReference: { wkid: 4326 },
      title: 'device',
      renderer: this.arcgisMapsDeviceConfigurationsService.getRenderer(UniqueValueRenderer, SimpleMarkerSymbol),
    })
    if(this.showData === GATEWAYS && this.recentSelectedSite !== NC1_BASE_CAMP) {
      this.map.add(this.gatewaysFeatureLayer)
    }
  }

  async fetchAndUpdateDevicesDataOnMap(selectedSites) {
    const payload = {
      filter: selectedSites,
      deviceType: this.selectedDeviceType
    }
    try {
      this.mapLoader = true;
      const deviceData = await this.registrationService.getDeviceDetailsBySiteAndDeviceType(payload).toPromise();
      const graphics =  await this.zoneMapService.addDeviceData(deviceData, this.systemType);
      this.addDevicesOnFeatureLayer(graphics)
      this.mapLoader = false;
    }
    catch(error) {
      this.mapLoader = false;
    }
  }

  async addDevicesOnFeatureLayer(graphics) {
    const [FeatureLayer, SimpleMarkerSymbol, UniqueValueRenderer] = await loadModules([
      "esri/layers/FeatureLayer",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/renderers/UniqueValueRenderer"
    ]);
    this.devicesFeatureLayer = new FeatureLayer({
      source: graphics,
      fields: this.arcgisMapsDeviceConfigurationsService.createFields(this.systemType),
      objectIdField: 'oid',
      geometryType: 'point',
      id: 'devicesFeatureLayer',
      spatialReference: { wkid: 4326 },
      title: 'device',
      renderer: this.arcgisMapsDeviceConfigurationsService.getRenderer(UniqueValueRenderer, SimpleMarkerSymbol),
    })

    if(this.recentSelectedSite !== NC1_BASE_CAMP) {
      this.map.add(this.devicesFeatureLayer)
    }
  }

 
  addViewWatchZoomEvent() {
    this.zoomWatcher = this.view.watch("zoom", (newZoom) => {
      if (Math.ceil(newZoom) !== Math.ceil(this.currentZoom)) {
        this.currentZoom = newZoom;
        this.arcgisMapService.setCurrentZoom(this.currentZoom);
        // for the first time of adding devicesFeatureLayer on map
        if(this.showData === DEVICES && !this.map.findLayerById('devicesFeatureLayer')) {
          this.map.add(this.devicesFeatureLayer);
        }
        else if(this.showData === GATEWAYS && !this.map.findLayerById('gatewaysFeatureLayer')){
          this.map.add(this.gatewaysFeatureLayer);
        }
        
        if(newZoom > 16) {
          this.toggleDevicesGateWaysfeatureLayer(true);
          this.toggleZoneOrClusterLabeling(false);
          
        } 
        else {
          this.toggleDevicesGateWaysfeatureLayer(false);
          this.toggleZoneOrClusterLabeling(true);
        }
      }
    })
  }

  toggleDevicesGateWaysfeatureLayer(visible) {
    if(this.recentSelectedSite === NC1_BASE_CAMP) {
      if(this.showData === DEVICES) 
        this.devicesFeatureLayer.visible = visible;
      else 
        this.gatewaysFeatureLayer.visible = visible;
    }
       
  }

  toggleZoneOrClusterLabeling(visible) {
    this.zoneOrClusterLabelGraphicLayer.visible = visible;
  }


  getConsumptionsOfDevices(site) {
    const start = moment().subtract(29, 'days').startOf('day').format(DATE_TIME_FORMAT);
    const end = moment().endOf('day').format(DATE_TIME_FORMAT);

    const payload = {
      dates: [start, end],
      deviceType: this.selectedDeviceType,
      site: site
    }
    try {
      this.telemetryService.deviceWiseConsumptionForSite(payload).subscribe((devicesConsumptions) => {
        const devicesData = this.devicesFeatureLayer.source.items;
        if(!devicesConsumptions) return;
        devicesData.forEach(device => {
          const deviceData = device.attributes;
          deviceData['consumptionFor30Days'] = devicesConsumptions[deviceData['uplinkReferenceKey']];
          deviceData['consumptionUnit'] = this.selectedDeviceType === DEVICE_TYPE_ENERGY_METER ? "kWh" : 'm³';
        })
  
      }) 
    }
    catch(error) {
      console.log('error while fetching consumption of devices', error);
    }
  
  }



  selectOption(option) {
    this.showData = option;
    this.toggleDevicesAndGateways(); 
  }

  toggleDevicesAndGateways() {
    if(this.showData === DEVICES && !this.map.findLayerById('devicesFeatureLayer')) {
      this.map.add(this.devicesFeatureLayer);
    }
    else if(this.showData === GATEWAYS && !this.map.findLayerById('gatewaysFeatureLayer')){
      this.map.add(this.gatewaysFeatureLayer);
    }
    if(this.showData === DEVICES) {
      this.devicesFeatureLayer.visible = true;
      this.gatewaysFeatureLayer.visible = false;
    }
    else {
      this.devicesFeatureLayer.visible = false;
      this.gatewaysFeatureLayer.visible = true;
    }
  }

  async getFacilitiesParameterData(site, deviceType) {
    const start = moment().subtract(29, 'days').startOf('day').format(DATE_TIME_FORMAT);
    const end = moment().endOf('day').format(DATE_TIME_FORMAT);
    const payload = {
      dates : [start, end],
      divisionTypes: [deviceType]
    }

    const res = await this.registrationService.getFacilitiesParametersData(payload).toPromise();
    const data = res[deviceType];
    const devicesData = this.devicesFeatureLayer.source.items;
    devicesData.forEach(device => {
      const deviceData = device.attributes;
      deviceData['percentageData'] = data[deviceData['uplinkReferenceKey']]['percentageDataFetched'];
  })
    // this.devicesFeatureLayer.source = devicesData;
    this.devicesFeatureLayer.refresh();  
  }

  addHitTestEvent() {
    this.hitTestHandles = this.view.on("click", (event) => {
      this.view.hitTest(event).then(({ results }) => {
        // By click on map, close the popupTemplate first and 
        this.closePopupTemplate();
        this.handleClick(results);
        /* call open popup component after 100ms so that map will complete there operations meanwhile
           open popup function is not async function and it not either give the instance to check of it open status, that's why need to wait manually
        */
        setTimeout(() => {
          this.openPopupTemplate(results, event);
        }, 100);
      });
    });
    this.addCustomActionOnPopupTooltipAndSubscibeActionEvent();
  }

  handleClick(results) {
    const isCluster = results[0].layer?.featureReduction?.type === "cluster";
    const clusterCount = results[0]?.graphic?.attributes?.cluster_count;
    const isItSiteCluster = results[0].layer?.title === 'cluster';
    // if it is cluster and size greater than 1 then zoom to devices
    if((isCluster && clusterCount > 1) || isItSiteCluster){
      const cluster = {target:results[0].graphic.geometry, zoom: 16};
      this.view.goTo(
        {
          target: cluster.target,
          zoom: cluster.zoom
        },
        {
          duration: 1200, // Duration in milliseconds (e.g., 2000ms = 2 seconds)
          easing: "ease-in-out" // Easing function for smooth transition
        }
      );
    }
  }

  addCustomActionOnPopupTooltipAndSubscibeActionEvent() {
    this.arcgisMapService.addCustomActionOnPopupTooltipAndSubscibeActionEvent(this.systemType)
  }

 
  closePopupTemplate() {
    this.view.popup.close();
  }

  openPopupTemplate(response, event) {
    const isItDevice = response[0].layer?.title === 'device';
    if(isItDevice) {
      const {results, selectedFeatureLayer} = this.getClickedFeatureLayerOfDevice(response);
      if(!results || !results.length) return;
      this.selectedFeatureLayer = selectedFeatureLayer;
      this.arcgisMapService.setSelectedFeatureLayer(this.selectedFeatureLayer);
      this.currentIndex = 0; 
      this.arcgisMapService.setCurrentIndex(this.currentIndex);
      this.selectedGeometry = null;
      let selected = results[this.currentIndex];
      this.totalOverllapedDevice = results.filter((result) => result.graphic.geometry.longitude == selected.graphic.geometry.longitude && result.graphic.geometry.latitude == selected.graphic.geometry.latitude);
      this.arcgisMapService.setTotalOverllapedDevices(this.totalOverllapedDevice);
      let data = this.selectedFeatureLayer.source.items.filter((source) => source.attributes.oid === selected.graphic.attributes.oid)[0];
      this.selectedGeometry = selected.graphic.geometry;
      this.arcgisMapService.setSelectedGeometry(this.selectedGeometry);
      this.showDataOnPopupTooltip(data, selected.graphic.geometry);      
    }
  }

  getClickedFeatureLayerOfDevice(response) {
    if(this.showData === DEVICES) {
      const isItDeviceFeatureLayer = response.filter((result) => result.graphic.layer.id === this.devicesFeatureLayer?.id);
      if(isItDeviceFeatureLayer.length) return {results: isItDeviceFeatureLayer, selectedFeatureLayer: this.devicesFeatureLayer};  
    }
    else {
      const isItGatewayFeatureLayer = response.filter((result) => result.graphic.layer.id === this.gatewaysFeatureLayer?.id);
      if(isItGatewayFeatureLayer.length) return {results: isItGatewayFeatureLayer, selectedFeatureLayer: this.gatewaysFeatureLayer};
    }
  }

  showDataOnPopupTooltip(data, geometry) {
    if(!data) return;
    this.view.popup.open({
      content: this.arcgisMapsDeviceConfigurationsService.createTemplate(data.attributes, this.getSystemType()),
      location: geometry,
    });
  }

  getSystemType(){
    return this.showData === DEVICES ? SYSTEM_TYPE_DEVICES : SYSTEM_TYPE_GATEWAYS
  }

  removeAllLayer() {
    this.map.remove(this.devicesFeatureLayer);
    this.map.remove(this.gatewaysFeatureLayer);
    this.map.remove(this.zoneGioFencingGraphicLayer);
    this.map.remove(this.zoneOrClusterLabelGraphicLayer);
  }



  }
