<div class="container-fluid">
    <div *ngIf="isMerged;else unmergedCards" style="height: 100%; width: 100%;">
        <!--div class="temp_wrapper">
            <h3>{{title}}</h3>
            <div class="temperature_table">
                <div class="table_mainheader table_flex">
                    <span>Name</span>
                    <span>Device ID</span>
                    <span>Value</span>
                    <span class="table-align">Date</span>
                </div>
            </div>

            <div *ngFor="let cardInfo of groups" class="table_flex table_row">
                <span class="tagName" [ngbTooltip]="displayName(cardInfo?.name)">{{displayName(cardInfo?.name)}}</span>
                <span class="tagName">{{cardInfo?.deviceType === "Water Meter" ? "WM" : '' }} {{cardInfo?.deviceType === "Energy Meter"
                    ? "EM": '' }}
                    {{cardInfo?.deviceType === "DubaPlantScada" ? "DP": '' }}{{cardInfo?.deviceId ||
                    cardInfo?.deviceid || cardInfo?.deviceID}}</span>
                <span class="tagName">{{cardInfo?.value | number : '1.2-2'
                    || '-'}} <span [innerHTML]="cardInfo.unit"></span></span>
                <span class="tagName">{{cardInfo?.latestDate}}</span>
            </div>
        </div-->
        <div id="kpiTable">
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Device ID</th>
                        <th>Value</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cardInfo of groups">
                        <td>{{displayName(cardInfo?.name)}}</td>
                        <td>{{cardInfo?.deviceType === "Water Meter" ? "WM" : '' }} {{cardInfo?.deviceType === "Energy Meter"? "EM": '' }} {{cardInfo?.deviceType === "DubaPlantScada" ? "DP": '' }}{{cardInfo?.deviceId || cardInfo?.deviceid || cardInfo?.deviceID}}</td>
                        <td>{{cardInfo?.value}} <span [innerHTML]="cardInfo.unit"></span></td>
                        <td>{{cardInfo?.latestDate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <ng-template #unmergedCards>
        <!--div class="row cardAlign">
            <div *ngFor="let cardInfo of groups" class="kpiCardRow">
                <div class="kpicard">
                    <div class="kpiWhitetext overflow">{{cardInfo?.deviceType === "Water Meter" ? "WM" : '' }}
                        {{cardInfo?.deviceType === "Energy Meter" ? "EM": '' }}
                        {{cardInfo?.deviceType === "DubaPlantScada" ? "DP": '' }}{{cardInfo?.deviceId ||
                        cardInfo?.deviceid}}</div>
                    <div *ngIf="cardInfo?.displayName">{{cardInfo?.displayName}}</div>
                    <div class="tagName overflow">{{cardInfo?.name}}</div>
                    <div class="unit overflow">{{cardInfo?.value | number : '1.2-2' || '-'}} <span *ngIf="cardInfo.unit"
                            [innerHTML]="cardInfo.unit"></span></div>
                    <div class="kpiCardDate overflow">{{cardInfo?.latestDate}}</div>
                </div>
            </div>
        </div-->
        <div class="n-kpiGrid">
            <div *ngFor="let cardInfo of groups" class="n-kpiCard">
                <div class="overflow n-kpiDeviceId">
                    {{cardInfo?.deviceType === "Water Meter" ? "WM" : '' }}
                    {{cardInfo?.deviceType === "Energy Meter" ? "EM": '' }}
                    {{cardInfo?.deviceType === "DubaPlantScada" ? "DP": '' }}{{cardInfo?.deviceId ||
                    cardInfo?.deviceid}}
                    </div>
                <div class="overflow" *ngIf="cardInfo?.displayName">
                    {{cardInfo?.displayName}}
                </div>
                <div [ngbTooltip]="displayName(cardInfo?.name)" class="overflow n-kpiDeviceName">
                    {{displayName(displayName(cardInfo?.name))}}
                </div>
                <div class="overflow n-kpiDeviceUnit">
                    {{cardInfo?.value}}<span *ngIf="cardInfo.unit" [innerHTML]="cardInfo.unit"></span>
                </div>
                <div class="overflow n-kpiDeviceDate">
                    {{cardInfo?.latestDate}}
                </div>
            </div>
        </div>
    </ng-template>

    <div *ngIf="!hideButton">
        <div *ngIf="!isMerged && groups.length > 1" class="kpi-merged-btn">
            <button (click)="changeMerge()"><img src="assets/images/merge.svg" width="20px"
                    style="padding-right: 5px;">Merge
                Cards</button>
        </div>
        <div *ngIf="isMerged && groups.length > 1" class="kpi-unmerged-btn">
            <button (click)="changeMerge()"><img src="assets/images/unmerge.svg" width="14px"> Unmerge
                Cards</button>
        </div>
    </div>
</div>