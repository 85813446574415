<div class="pagination">
    <div style="min-width: 3.66vw;" class="mr-2">
        <mat-select [(ngModel)]="itemPerPage" (selectionChange)="tablePageSizeChange($event)" [disableOptionCentering]="true">
            <mat-option *ngFor="let page of pageSizeOption" [value]="page">{{page}}</mat-option>
        </mat-select>
    </div>

    <span>{{(current-1) * itemPerPage + 1 }} - {{current === total ? totalCount : current *
        itemPerPage}} of
        {{totalCount}}</span>
    <button [ngClass]="current > 1 ? 'activePage':'nonActivePage'" class="actionButtons"
        [ngClass]="current > 1 ? 'activePage':'nonActivePage'" (click)="onGoTo(1)"><img
            src="assets/images/table-first-link.svg" class="imgW12" width="12px" title="First"></button>
    <button [ngClass]="current > 1 ? 'activePage':'nonActivePage'" class="actionButtons" (click)="onPrevious()"><img
            src="assets/images/table-back-icon.svg" width="7px" title="Previous"></button>
    <button [ngClass]="current < total ? 'activePage':'nonActivePage'" class="actionButtons reverseIcon"
        (click)="onNext()"><img src="assets/images/table-back-icon.svg" width="7px" title="Next"></button>
    <button [ngClass]="current < total ? 'activePage':'nonActivePage'" class="actionButtons reverseIcon"
        (click)="onGoTo(total)"><img src="assets/images/table-first-link.svg" class="imgW12" width="12px" title="Last"></button>
</div>