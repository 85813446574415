import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { SqlInjectionValidatorOptions } from '../reusable/common.interfaces';

@Directive({
  selector: '[appSqlInjectionValidation]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: SqlInjectionValidationDirective, multi: true }
  ]
})
export class SqlInjectionValidationDirective implements Validator {

  private _options: SqlInjectionValidatorOptions = {
    maxLength: 50
  };

  @Input('appSqlInjectionValidation')
  set options(value: SqlInjectionValidatorOptions) {
    // Merge default options with provided options whenever input changes
    this._options = { ...this._options, ...value };
  }

  get options(): SqlInjectionValidatorOptions {
    return this._options;
  }

  constructor(private customValidator: CustomvalidationService) { }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.customValidator.sqlInjectionValidator(this.options)(control);
  }
}
