import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccesToken, Certificate, UsernamePassword } from '../devices-profile-modal/device-profile';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastService } from 'src/app/services/toast/toast.service';
import * as JSZip from 'jszip';
import * as saveAs from 'file-saver';
import { DevicesService } from '../devices/devices.service';

@Component({
  selector: 'app-devices-profile-success-modal',
  templateUrl: './devices-profile-success-modal.component.html',
  styleUrls: ['./devices-profile-success-modal.component.scss', '../../../../assets/styles/form.css']
})
export class DevicesProfileSuccessModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private clipboard: Clipboard,
    public toastrService: ToastService,
    private deviceService: DevicesService,
    private dialogRef: MatDialogRef<DevicesProfileSuccessModalComponent>) { }

  ngOnInit(): void {

  }

  closeModal(){
    this.dialogRef.close();
  }

  copyAuthentication(type,item) {
    if (type === AccesToken) {
      this.clipboard.copy(item.token);
      this.toastrService.success("Acces Token copied successfully");
    }
    else if (type === UsernamePassword) {
      this.clipboard.copy(JSON.stringify({ userName: item.username, password: item.password }));
      this.toastrService.success("Username and Password copied successfully");
    }
    else if (type === "Certificate") {
      // this.saveZip("certificates", item.certificates);
      this.deviceService.downloadCertificateByDeviceId(this.data.deviceId).then((response: any) => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = response.message;
        a.download = `${this.data.deviceId}_certificates`;
        a.click();
        document.body.removeChild(a);
        this.dialogRef.close();
      })
    }
    this.dialogRef.close();
  }

  saveZip = (filename, urls) => {
    if (!urls) return;
    const zip = new JSZip();
    const folder = zip.folder("files"); // folder name where all files will be placed in 
    urls.forEach((url) => {
      const blobPromise = fetch(url).then((r) => {
        if (r.status === 200) return r.blob();
        return Promise.reject(new Error(r.statusText));
      });
      const name = url.substring(url.lastIndexOf("/") + 1);
      folder.file(name, blobPromise);
    });
    zip.generateAsync({ type: "blob" }).then((blob) => saveAs(blob, filename));
  };

}
