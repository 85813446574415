import { Injectable } from '@angular/core';
import { loadModules } from "esri-loader";
import { ArcgisMapsDeviceConfigurationsService } from 'src/app/services/arcgis-map/devices-fields-template-renderer/arcgis-maps-device-configurations.service';


@Injectable({
  providedIn: 'root'
})
export class ZoneMapService {

  constructor(
    private arcgisMapsDeviceConfigurationsService: ArcgisMapsDeviceConfigurationsService
  ) { }

  async getRectangleGraphic(data) {
    const [ Graphic] = await loadModules([
      "esri/Graphic"
    ]);
    return  new Graphic({
      geometry: {
        type: 'polygon',
        rings: data['coordinates']
      },
      symbol: {
        type: 'simple-fill',
        color: data['fillColor'], // Set the fill color (red with 50% transparency)
        outline: {
          color: data['borderColor'], // Set the outline color (red)
          width: 1 // Set the outline width
        }
      }
    });

  }

  async getTextLabelGraphics(data) {
    const [ Graphic, Point, TextSymbol] = await loadModules([
      "esri/Graphic",
      "esri/geometry/Point",
      "esri/symbols/TextSymbol"
    ]);
    const  labelLocation = new Point({
      x: data['labelLocation'][0],
      y: data['labelLocation'][1]
      // spatialReference: spatialReference // Replace with desired label/icon location coordinates and spatial reference
    });
    
    // Label Text
    const labelTextSymbol = new TextSymbol({
      text: data['labelText'], // Replace with your desired label text
      color: [0, 0, 0], // Replace with desired text color
      haloColor: [255, 255, 255], // Replace with desired halo color
      haloSize: "1px", // Replace with desired halo size
      font: {
        size: 12, // Replace with desired font size
        weight: "normal" // Replace with desired font weight
      },
      xoffset: 4
    });
    return new Graphic({
      geometry: labelLocation,
      symbol: labelTextSymbol
    });   
  }

  async addDeviceData(deviceData, systemType) {
    const [Point, Graphic] = await loadModules([
      "esri/geometry/Point",
      "esri/Graphic"
    ]);
    const groupedDevices = this.arcgisMapsDeviceConfigurationsService.groupTheDeviceCountOnLatLong(deviceData);
    const graphics = [];
    deviceData.forEach(device => {
      if(device.location && this.arcgisMapsDeviceConfigurationsService.isItValidCoordinates(device.location)) {
        const point = new Point({
          longitude: device.location.longitude,
          latitude: device.location.latitude,  
        });
  
        const graphic = new Graphic({
          geometry: point,
          attributes: this.arcgisMapsDeviceConfigurationsService.createAttributes(device, groupedDevices, systemType)
        });
        graphics.push(graphic);
      }
    });
    return graphics;
  }  

  async addGatewaysData(gatewaysData, systemType) {
    const [Point, Graphic] = await loadModules([
      "esri/geometry/Point",
      "esri/Graphic"
    ]);
    const groupedDevices = this.arcgisMapsDeviceConfigurationsService.groupTheDeviceCountOnLatLong(gatewaysData);
    const graphics = [];
    gatewaysData.forEach(gatway => {
      if(gatway.location && this.arcgisMapsDeviceConfigurationsService.isItValidCoordinates(gatway.location)) {
        const point = new Point({
          longitude: gatway.location.longitude,
          latitude: gatway.location.latitude,  
        });
  
        const graphic = new Graphic({
          geometry: point,
          attributes: this.arcgisMapsDeviceConfigurationsService.createAttributes(gatway, groupedDevices, systemType)
        });
        graphics.push(graphic);
      }
    });
    return graphics;
  }

}
