<div class="divContainer">
    <div class="">
        <div>
            <div>
                <app-breadcrumb [data]="breadcrumb"></app-breadcrumb>
            </div>
        </div>

        <div class="">
            <div class="meterType">
                <span class="main-Title">{{this.meterType === 'water' ? 'Water Meters' : (this.meterType === 'energy' ? 'Energy Meters' : 'Cooling Meters')}}</span>
            </div>
            <div class="navPages">
                <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event.tab.textLabel)"
                    class="navigation" [selectedIndex]="tabIndex" disableRipple>
                    <mat-tab label="Dashboard">
                        <ng-template mat-tab-label>
                            <img class="img1 tabIconDashboard w-24" src="assets/images/Dashboard-new.svg" width="24px">
                            <label>Dashboard</label>
                        </ng-template>
                    </mat-tab>
                    <mat-tab label="Device Listing">
                        <ng-template mat-tab-label>
                            <img class="img1 tabIconListing w-30" src="assets/images/Device-listing-new.svg" width="25px">
                            <label>Device Listing</label>
                        </ng-template>
                    </mat-tab>
                    <!-- <mat-tab *ngIf="meterType === 'water'" label="Leakage Insights">
                        <ng-template mat-tab-label>
                            <img class="img1 tabIconListing w-23" src="assets/images/Leakage-insights-new.svg" width="23px">
                            <label>Leakage Insights</label>
                        </ng-template>
                    </mat-tab> -->
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>