import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as d3 from "d3";
import { renderChart } from 'src/app/reusable/chart-util/aggregation-line-bar-area';
import { ThresholdAlertChartsService } from '../../threshold-alert/services/threshold-alert-charts.service';
import { ThresholdAlertService } from '../../threshold-alert/services/threshold-alert.service';
import { lineGraphForNative } from 'src/app/reusable/chart-util/line-graph-native';
import { initializeDateRange, setDateRange } from 'src/app/reusable/date-util';
import { breakPoints, DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START, DEVICE_TYPE_WATER_METER } from 'src/app/reusable/constant';
import { BreakpointService } from 'src/app/services/breakpoint-service/breakpoint.service';
import { AlertsManagementService } from 'src/app/reusable/services/alerts-management.service';
import { RegistrationService } from 'src/app/reusable/services/registration.service';
import { Sites } from '../../device-management/interfaces';
import { SeverityCount } from 'src/app/reusable/common.interfaces';
import * as _ from 'underscore';
import * as moment from 'moment';
import { NC1_BASE_CAMP } from 'src/app/reusable/siteConstants';
import { Subscription } from 'rxjs';
import { renderChartForLeakage } from 'src/app/reusable/chart-util/aggregation-line-bar-area-leakage';
@Component({
  selector: 'app-leakage-insights',
  templateUrl: './leakage-insights.component.html',
  styleUrls: ['./leakage-insights.component.css', '../../../../assets/Reusable-CSS/main.scss']
})
export class LeakageInsightsComponent implements OnInit, OnDestroy {
  public date = null;
  public startDate = null;
  public endDate = null;
  public meterType: string;
  public statusBasedLeakageDataChartOption: any;
  // Leakage Alerts start
  public leakageDataLoader = false;
  public leakageData;
  // Leakage Alerts end
  // Leakage Alert Count start 
  public leakageDetectionTimeNoDataFound: boolean = false;
  public leakageDetectionTimeComparisonDataLoader: boolean = false;
  public leakageAlertAggregate: string = 'monthly';
  // Leakage Alert Count end 
  // Water Leaked start 
  public leakageVsPotentialSavingNoDataFound: boolean = false;
  public leakageVsPotentialSavingDataLoader: boolean = false;
  public leakageWaterLeakedAggregate: string = 'daily';
  // Water Leaked end 

  // breakpoint related to responsive design
  public breakPoints = breakPoints;
  public leakageDetectionTimeGraphSubscription: Subscription;
  
  public noDataFound:boolean = false;

  selectedLocation: string = NC1_BASE_CAMP; // Default Value on Load

  potentialWaterSavingData: {potentialSaving: number};
  alertDetectionTimeData: {averageDifferenceTime: number, percentageDifference: number};
  totalLeakageData: {waterLeaked: number};
  severityCountData: {severityCount: SeverityCount};
  leakageVsPotentialSavingData: {resolvedOn: string, count: number, waterLeaked: number, waterSaved: number}[];
  leakageDetectionTimeComparisonData: {startDate: string, endDate: string, deviceAlert: number, smartAlert: number}[];
  missedAlertCountData: { missedAlertCount: number,missedAlertTotalLeakage: number};

  severityDonutChartOption: any;

  sites: Sites;
  locations: { id: number, name: string }[] = [];

  totalSeverityAlertCount: number = 0;

  public breadcrumb: Array<any> = [
    {
      title: 'Home',
      route: '/home',
      isActive: false
    }
  ];

  constructor(
    private router: Router,
    private thresholdAlertServices: ThresholdAlertService,
    private alertsManagementService: AlertsManagementService,
    private thresholdAlertChartServices: ThresholdAlertChartsService,
    private breakpointService: BreakpointService,
    private registrationService: RegistrationService

  ) { }

  ngOnInit() {
    this.date = initializeDateRange();
    this.startDate = this.date.startDate;
    this.endDate = this.date.endDate;

    this.setHeader();

    this.getSites();

    this.leakageDetectionTimeGraphSubscription = this.breakpointService.breakpointChanges.subscribe(matches => {
      if(this.sites && this.selectedLocation){
        const selectedLocationWithSites: Sites = this.getSitesFromSelectedLocation();
        this.getLeakageDetectionTimeComparison(selectedLocationWithSites);
      }
      
    });
  }



  datepicker(dateArr: any) {
    this.date = setDateRange(dateArr);
    this.startDate = this.date.startDate;
    this.endDate = this.date.endDate;

    this.loadDashboardData();
  }

  createLineChart() {
    try {

      const waterSavedData = this.leakageVsPotentialSavingData?.map((item) => ({
        dateTime: `${item.resolvedOn}.853+00:00`,
        value: item.waterSaved,
      }));

      const waterLeakedData = this.leakageVsPotentialSavingData?.map((item) => ({
        dateTime: `${item.resolvedOn}.853+00:00`,
        value: item.waterLeaked, 
      }));

      this.createChart(waterSavedData, 'Potential Water Saving', waterLeakedData, 'Water Leaked', '.leakageVsPotentialWaterSaving', 'line', this.leakageWaterLeakedAggregate);
    } catch (error) {
      throw new Error("LeakageInsights : createLineChart() : " + error);
    }
  }

  createBarChart() {
    try {

      const deviceAlertData = this.leakageDetectionTimeComparisonData?.map((item) => ({
        dateTime: `${item.startDate}.853+00:00`,
        value: item.deviceAlert,
      }));

      const smartAlertData = this.leakageDetectionTimeComparisonData?.map((item) => ({
        dateTime: `${item.startDate}.853+00:00`,
        value: item.smartAlert, 
      }));

      this.createChart(smartAlertData, 'Detection time by AI', deviceAlertData, 'Detection time by device', '.leakageDetectionTimeComparison', 'bar', this.leakageAlertAggregate);

    } catch (error) {
      throw new Error("LeakageInsights : createBarChart() : " + error);
    }
  }

  createChart(data, name1, data2, name2, element, chartType = 'line', aggregationInterval) {
    try {
      const dataSet = [
        {
          name: name1,
          nativeList: data
        },
        {
          name: name2,
          nativeList: data2
        }
      ];
      const config = {
        attributes: [{ name: name1 }, { name: name2 }],
        showScrollbar: true,
      }
      d3.select(element).attr('hidden', null);
      if (chartType === 'line') {
        this.leakageVsPotentialSavingNoDataFound = dataSet.every((set) => {
          return set.nativeList?.every((item) => {
            const hasEmptyAggregation = item.aggregation && _.isEmpty(item.aggregation);
            const hasZeroValue = item.value === 0;
            return hasEmptyAggregation || hasZeroValue;
          });
        });

        if(!this.leakageVsPotentialSavingNoDataFound) {
          const dataSetForLineChart = dataSet.map((set) => {
            const resultObj = {};
            set.nativeList?.forEach((nativeData, index) => {
              let { dateTime, value } = nativeData
              // value = Math.floor(Math.random() * 6);
              const dateRange = [dateTime];
              resultObj[`Tag ${index}`] = {
                dateRange,
                value
              }
            })
            set['aggregation'] = resultObj;
            return set;
          });
          config["widgetType"] = 'linechart';
          config["removeExisting"] = true;
          config["aggregationInterval"] = aggregationInterval;
          config["fromLeakageInsights"] = true;
          renderChart(element, dataSetForLineChart, config);
        }
        else {
          d3.selectAll(`${element} svg`).remove();
        }
        
      } else {
         this.leakageDetectionTimeNoDataFound = dataSet.every((set) => {
          return set.nativeList?.every((item) => {
            const hasEmptyAggregation = item.aggregation && _.isEmpty(item.aggregation);
            const hasZeroValue = item.value === 0;
            return hasEmptyAggregation || hasZeroValue;
          });
        });

        if(!this.leakageDetectionTimeNoDataFound) {
          const dataSetForBarChart = dataSet.map((set, index) => {
            const resultObj = {};
              set.nativeList?.forEach((nativeData, index) => {
                let { dateTime, value } = nativeData
                // value = Math.floor(Math.random() * 6);
                const dateRange = [dateTime];
                resultObj[`Tag ${index}`] = {
                  dateRange,
                  value
                }
              })
              set['aggregation'] = resultObj;
            return set;
          });
          // config["width"] = 900;
          // config["height"] = 300;
          config['showScrollbar'] = false;
          config["widgetType"] = 'barchart';
          config["removeExisting"] = true;
          config["aggregationInterval"] = aggregationInterval;
          config["fromLeakageInsights"] = true;
            renderChartForLeakage(element, dataSetForBarChart, config);
        }
        else {
          d3.selectAll(`${element} svg`).remove();
        }
      }
    } catch (error) {
      d3.selectAll(`${element} svg`).remove();
      throw new Error("LeakageInsights : createChart() : " + error);
    }

  }

  loadDashboardData(locationChange: boolean = false) {
      const selectedLocationWithSites: Sites = this.getSitesFromSelectedLocation();

      this.getPotentialWaterSavings(selectedLocationWithSites);

      this.getAlertDetectionTime(selectedLocationWithSites);
      
      this.getTotalLeakage(selectedLocationWithSites);

      this.getSeverityCount(selectedLocationWithSites);

      this.getLeakageVsPotentialSaving(selectedLocationWithSites);

      if(locationChange){
        this.getLeakageDetectionTimeComparison(selectedLocationWithSites);
      }   

      this.getMissedAlertCount(selectedLocationWithSites);
  }

  getSitesFromSelectedLocation(): Sites {
    let selectedLocationWithSites: Sites;
    if (this.selectedLocation) {
      selectedLocationWithSites = {
        [this.selectedLocation]: this.sites[this.selectedLocation]
      }
    } else {
      selectedLocationWithSites = this.sites;
    }
    return selectedLocationWithSites;
  }

  createDonutChart() {
    this.severityDonutChartOption = this.thresholdAlertChartServices.donutChart([
      { value: this.severityCountData.severityCount.Critical, name: 'Critical' },
      { value: this.severityCountData.severityCount.High, name: 'High' },
      { value: this.severityCountData.severityCount.Medium, name: 'Medium' },
      { value: this.severityCountData.severityCount.Low, name: 'Low' }
    ],
      ["#dc0000", "#fd8c00", "#fccc1f", "#00ac46"],
      ["55%", "80%"]);
  }

  getSites() {
    this.registrationService.getSiteZonesByDeviceType(DEVICE_TYPE_WATER_METER).subscribe(res => {
      const sites: Sites = {};
      this.locations = [];
      for (const key in res) {
        if (key) {
          sites[key] = res[key];
          let obj = { id: this.locations.length + 1, name: key };
          this.locations.push(obj);
          // this.locations.push(key);
        }
      }
      this.sites = sites;
      this.loadDashboardData(true);
    });

  }


  setHeader(){
    this.breadcrumb.push({
      title: 'Leakage Insights',
      isActive: true
    })
  }

  getPotentialWaterSavings(sites: Sites){
    const potentialWaterSavingPayload = {
      "dates": [
        this.startDate,
        this.endDate
      ],
      "deviceType": DEVICE_TYPE_WATER_METER,
      "sites": sites
    };

    this.alertsManagementService.getPotentialWaterSavings(potentialWaterSavingPayload).subscribe((result: any) => {
      if (result) {
        this.potentialWaterSavingData = result.data;
      }
    }, (err) => {
      // console.error('Error fetching alerts rules:', err);
    });
  }

  getAlertDetectionTime(sites: Sites){
    const alertDetectionTimePayload = {
      "dates": [
        this.startDate,
        this.endDate
      ],
      "deviceType": DEVICE_TYPE_WATER_METER,
      "sites": sites
    }

    this.alertsManagementService.getAlertDetectionTime(alertDetectionTimePayload).subscribe((result: any) => {
      if (result) {
        this.alertDetectionTimeData = result.data;
      }
    }, (err) => {
      // console.error('Error fetching alerts rules:', err);
    });
  }

  getTotalLeakage(sites: Sites) {
    const totalLeakagePayload = {
      "dates": [
        this.startDate,
        this.endDate
      ],
      "deviceType": DEVICE_TYPE_WATER_METER,
      "sites": sites
    }

    this.alertsManagementService.getTotalLeakage(totalLeakagePayload).subscribe((result: any) => {
      if (result) {
        this.totalLeakageData = result.data;
      }
    }, (err) => {
      // console.error('Error fetching alerts rules:', err);
    });
  }

  getSeverityCount(sites: Sites) {
    let severityCountPayload = {
      "dates": [
        this.startDate,
        this.endDate
      ],
      "deviceType": DEVICE_TYPE_WATER_METER,
      "sites": sites
    }


    this.alertsManagementService.getSeverityCount(severityCountPayload).subscribe((result: any) => {
      if (result) {
        this.severityCountData = result.data;
      }

      this.totalSeverityAlertCount = this.severityCountData.severityCount.Critical + this.severityCountData.severityCount.High + this.severityCountData.severityCount.Medium + this.severityCountData.severityCount.Low

      this.createDonutChart();

    }, (err) => {
      // console.error('Error fetching alerts rules:', err);
    });
  }

  getLeakageVsPotentialSaving(sites: Sites) {
    const leakageVsPotentialSavingPayload = {
      "dates": [
        this.startDate,
        this.endDate
      ],
      "deviceType": DEVICE_TYPE_WATER_METER,
      "aggregationType": this.leakageWaterLeakedAggregate, //hourly/ daily / monthly
      "sites": sites
    }

    this.leakageVsPotentialSavingDataLoader = true;

    this.alertsManagementService.getLeakageVsPotentialSaving(leakageVsPotentialSavingPayload).subscribe((result: any) => {
      if (result) {
        this.leakageVsPotentialSavingData = result.data;
        this.leakageVsPotentialSavingDataLoader = false;
        this.createLineChart();
      }
    }, (err) => {
      // console.error('Error fetching alerts rules:', err);
    });
  }

  getLeakageDetectionTimeComparison(sites: Sites) {
    const startDate = moment().subtract(5, 'months').startOf('month').format(DATE_TIME_FORMAT_DAY_START) // startOf('month') is used to achieve something similar to MTD
    const endDate = moment().format(DATE_TIME_FORMAT_DAY_END);
    const leakageDetectionTimeComparisonPayload = {
      "dates": [
        startDate,
        endDate
      ],
      "deviceType": DEVICE_TYPE_WATER_METER,
      "aggregationType": this.leakageAlertAggregate, //hourly/ daily / monthly
      "sites": sites
    }

    this.leakageDetectionTimeComparisonDataLoader = true;

    this.alertsManagementService.getLeakageDetectionTimeComparison(leakageDetectionTimeComparisonPayload).subscribe((result: any) => {
      if (result) {
        this.leakageDetectionTimeComparisonData = result.data;
        this.leakageDetectionTimeComparisonDataLoader = false;
        this.createBarChart();
      }

    }, (err) => {
      // console.error('Error fetching alerts rules:', err);
    });
  }

  getMissedAlertCount(sites: Sites){
    const missedAlertCountPayload = {
      "dates": [
        this.startDate,
        this.endDate
      ],
      "deviceType": DEVICE_TYPE_WATER_METER,
      "sites": sites
    }

    this.alertsManagementService.getMissedAlertCount(missedAlertCountPayload).subscribe((result: any) => {
      if (result) {
        this.missedAlertCountData = result.data;
      }
    }, (err) => {
      // console.error('Error fetching alerts rules:', err);
    });
  }

 ngOnDestroy(){
  if(this.leakageDetectionTimeGraphSubscription) {
    this.leakageDetectionTimeGraphSubscription.unsubscribe();
  }
 }

}
