<div class="dashboardTopCardBlk">
    <div class="consumptionBlk">
        <h3 class="consumptionHeading">Energy Consumption <span>(MTD)</span></h3>
        <div class="consumptionValue">
            <div class="consumptionData">{{convertToK(energyData?.totalConsumption) | number: '1.2-2' }} K <span>kWh</span></div>
            <div [ngClass]="percentageChange?.energyConsumptionChange>=0?'consumptionDown':'consumptionUp'"><span class="material-symbols-outlined">{{percentageChange?.energyConsumptionChange>=0?'arrow_upward_alt':'arrow_downward_alt'}}</span> {{roundingOfAndFixedNumber(percentageChange?.energyConsumptionChange)}}%</div>
        </div>
        <div class="sourceblk"><span>Source</span><span>NEOM Community 1</span></div>
    </div>
    <div class="consumptionBlk">
        <h3 class="consumptionHeading">Water Consumption <span>(MTD)</span></h3>
        <div class="consumptionValue">
            <div class="consumptionData">{{convertToK(waterData?.totalConsumption) | number: '1.2-2' }} K <span>m<sup>3</sup></span></div>
            <div [ngClass]="percentageChange?.waterConsumptionChange>=0?'consumptionDown':'consumptionUp'"><span class="material-symbols-outlined">{{percentageChange?.waterConsumptionChange>=0?'arrow_upward_alt':'arrow_downward_alt'}}</span> {{roundingOfAndFixedNumber(percentageChange?.waterConsumptionChange)}}%</div>
        </div>
        <div class="sourceblk"><span>Source</span><span>NEOM Community 1</span></div>
    </div>
    <div class="consumptionBlk">
        <h3 class="consumptionHeading">Water Production Totalizer</h3>
        <div class="consumptionValue">
            <div class="consumptionData">{{convertToK(waterProduced) | number: '1.2-2' }} K <span>m<sup>3</sup></span></div>
            <!-- <div [ngClass]="percentageChange?.waterProducedChange>=0?'consumptionDown':'consumptionUp'"><span class="material-symbols-outlined">{{percentageChange?.waterProducedChange>=0?'arrow_upward_alt':'arrow_downward_alt'}}</span> {{roundingOfAndFixedNumber(percentageChange?.waterProducedChange)}}%</div> -->
        </div>
        <div class="sourceblk"><span>Source</span><span>Duba Desalination Plant</span></div>
    </div>
    <div class="consumptionBlk">
        <h3 class="consumptionHeading">Active Alerts</h3>
        <div class="consumptionValue pt-29">
            <div class="activeAlerts">{{(alertCount || 0) | number: '1.0'}} <span class="material-symbols-outlined">warning</span></div>
            <div class="allAlerts" routerLink="/alert-management">All Alerts<span class="material-symbols-outlined">arrow_forward</span></div>
        </div>
    </div>
</div>