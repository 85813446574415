import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { ThresholdInputValidatorOptions } from '../reusable/common.interfaces';

@Directive({
  selector: '[appThresholdInputValidation]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ThresholdInputValidationDirective, multi: true }
  ]
})
export class ThresholdInputValidationDirective implements Validator {

  // Default options for the directive
  private _options: ThresholdInputValidatorOptions = {
    type: 'NUMBER',
    maxValue: 0,
  };

  @Input('appThresholdInputValidation')
  set options(value: ThresholdInputValidatorOptions) {
    // Merge default options with provided options whenever input changes
    this._options = { ...this._options, ...value };
  }

  get options(): ThresholdInputValidatorOptions {
    return this._options;
  }

  constructor(private customValidator: CustomvalidationService) { }

  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.customValidator.thresholdInputValidator(this.options)(control);
  }

}
