export const createCustomPaginationForPopupHeader = (popupHeader, current, totalCount) => {
    // Clear the existing content (if any)
    while (popupHeader.firstChild) {
      popupHeader.removeChild(popupHeader.firstChild);
    }
  
    // Create a DocumentFragment to assemble the new structure
    const fragment = document.createDocumentFragment();
  
    // Create the custom div for the new content
    const customDiv = document.createElement('div');
    customDiv.style.display = 'flex';
    customDiv.style.alignItems = 'center';
    customDiv.style.justifyContent = 'space-between';
    customDiv.style.width = '100%';
  
    // Create the left-side zoom section
    const zoomDivContainer = document.createElement('div');
    const zoomDiv = document.createElement('div');
    zoomDiv.classList.add('material-symbols-outlined');
    zoomDiv.id = 'custom-esri-zoom';
    zoomDiv.textContent = 'zoom_in';
  
    const zoomSpan = document.createElement('span');
    zoomSpan.textContent = ' Zoom to';
  
    zoomDiv.appendChild(zoomSpan);
    zoomDivContainer.appendChild(zoomDiv);
  
    // Create the pagination section
    const paginationDivContainer = document.createElement('div');
    const paginationInnerDiv = document.createElement('div');
    paginationInnerDiv.style.display = 'flex';
    paginationInnerDiv.style.alignItems = 'center';
    paginationInnerDiv.style.justifyContent = 'space-between';
    paginationInnerDiv.style.width = '100%';
  
    // Previous button
    const prevButton = document.createElement('div');
    prevButton.classList.add('material-symbols-outlined');
    prevButton.id = 'esri-pagination-prev';
    prevButton.style.cursor = 'pointer';
    prevButton.style.marginRight = '10px';
    prevButton.textContent = 'chevron_backward';
  
    // Pagination text
    const paginationText = document.createElement('div');
    paginationText.id = 'esri-pagination-text';
    paginationText.textContent = `${current} of ${totalCount}`;
  
    // Next button
    const nextButton = document.createElement('div');
    nextButton.classList.add('material-symbols-outlined');
    nextButton.id = 'esri-pagination-next';
    nextButton.style.cursor = 'pointer';
    nextButton.style.marginLeft = '10px';
    nextButton.textContent = 'chevron_forward';
  
    // Append pagination elements
    paginationInnerDiv.appendChild(prevButton);
    paginationInnerDiv.appendChild(paginationText);
    paginationInnerDiv.appendChild(nextButton);
  
    paginationDivContainer.appendChild(paginationInnerDiv);
  
    // Append both sections to the custom div
    customDiv.appendChild(zoomDivContainer);
    customDiv.appendChild(paginationDivContainer);
  
    // Append customDiv to the DocumentFragment
    fragment.appendChild(customDiv);
  
    // Finally, append the fragment to the popupHeader
    popupHeader.appendChild(fragment);
}
