<div class="page-wrapper" [ngClass]="menuVisible ? 'active' : ''" >
    <div class="sideBarNav">
        <div class="menuBarIcon">
            <span class="material-symbols-outlined">menu</span>
        </div>

        <div class="sideBarTopIcon borderBottom">
            <ul>
                <li>
                    <a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><span class="material-symbols-outlined" matTooltip="Home" matTooltipPosition="after" matTooltipClass="after">home</span></a>
                </li>
                <li>
                    <button class="subNavigationClick" (click)="subNavigationClick()">
                        <span class="dashboardChildData"> <!-- matTooltip="Dashboard" matTooltipPosition="after" matTooltipClass="after"-->
                            <div class="material-symbols-outlined">grid_view</div>
                            <div class="material-symbols-outlined navArrow">chevron_right</div>
    
                            <div class="dashboardSubNav" id="dashboardSubNav"  *ngIf="!clickSubNav">
                                <!-- <button class="newDashboard"><span class="material-symbols-outlined">add_box</span> New Dashboard</button>
                                <div class="clickOn"><span>Click on +</span> to create dashboard</div> -->
                                <div class="switchesCustomerIndus">
                                    <div class="customerIndusTab" [ngClass]="{'active': selectedDashboard === 'Consumer'}" (click)="handleToggle('Consumer')">
                                        Consumer
                                    </div>
                                    <div class="customerIndusTab" [ngClass]="{'active': selectedDashboard === 'industrial'}" (click)="handleToggle('industrial')">
                                        Industrial
                                    </div>
                                </div>
                                <div class="accordion">
                                    <div class="option" *ngIf="getSelectedValue.Energy.length">
                                      <input type="checkbox" id="toggle1" class="toggle" />
                                      <label class="title" for="toggle1">
                                        <span class="material-symbols-outlined">bolt</span> Energy
                                      </label>
                                      <div class="content">
                                        <ul>
                                            <li *ngFor="let value of getSelectedValue.Energy">
                                                <a routerLink="{{value.route}}"  (click)="handleClick()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{value.description}} 
                                                    <!-- <div class="locationName">{{value.location}}</div>  -->
                                                    <div *ngIf="value.description == 'Solar Plant'" class="commingSoon optionActive">(coming soon)</div>
                                                </a>
                                                <!-- <a *ngIf="value.description == 'Solar Plant'"routerLink="{{value.route}}"  (click)="handleClick()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" matTooltip="Coming Soon" matTooltipPosition="above" matTooltipClass="above">{{value.description}} </a> -->
                                            </li>
                                        </ul>
                                      </div>
                                    </div>
                                
                                    <div class="option">
                                      <input type="checkbox" id="toggle2" class="toggle" />
                                      <label class="title" for="toggle2">
                                        <span class="material-symbols-outlined">water_drop</span> Water
                                      </label>
                                      <div class="content">
                                        <ul>
                                            <li *ngFor="let value of getSelectedValue.Water">
                                                <a routerLink="{{value.route}}"  (click)="handleClick()" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{value.description}} 
                                                    <div *ngIf="!(value.description?.toLowerCase().includes('cooling') || value.description?.toLowerCase().includes('water'))" class="locationName optionActive">{{value.location}}</div>
                                                </a>
                                            </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div class="option" *ngIf="selectedDashboard === 'Consumer'">
                                        <input type="checkbox" id="toggle3" class="toggle" />
                                        <label class="title" for="toggle3">
                                            <span class="material-symbols-outlined">bolt</span> District Energy
                                        </label>
                                        <div class="content">
                                            <ul>
                                                <li *ngFor="let value of getSelectedValue.Cooling">
                                                    <a routerLink="{{value.route}}" (click)="handleClick()" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{ exact: true }">{{value.description}}
                                                        <div *ngIf="!(value.description?.toLowerCase().includes('water') || value.description?.toLowerCase().includes('cooling'))"
                                                            class="locationName optionActive">{{value.location}}</div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                
                                    <div class="option">
                                      <input type="checkbox" id="toggle4" class="toggle" />
                                      <label class="title" for="toggle4">
                                        <span class="material-symbols-outlined">dashboard_customize</span> My Dashboards
                                      </label>
                                      <div class="content">
                                        <ul>
                                            <li *ngFor="let dashboard of customDashboard; let i = index"><a [routerLink]="getRouterLink(dashboard)" (click)="over(false)" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{dashboard.name}}</a>
                                                <span class="material-symbols-outlined whiteBg" (click)="savePinnedDashboard(dashboard,getPinnedUnPinnedInfo(dashboard), i)">
                                                    <div class="loader" *ngIf="pinnedDashboardLoader == i"></div>
                                                    {{getPinnedUnPinnedInfo(dashboard) == 'pin' ? 'keep' : 'keep_off'}}
                                                </span>
                                            </li>
                                        </ul>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </button>
                </li>
            </ul>
        </div>

        <div class="sideBarTopIcon">
            <ul>
                <li>
                    <a routerLink="/connectioncanvas" target="_blank" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><span class="material-symbols-outlined" matTooltip="HMI Builder" matTooltipPosition="after" matTooltipClass="after">filter_vintage</span></a>
                </li>
                <!-- <li>
                    <a routerLink="/leakage-insights" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><span class="material-symbols-outlined" matTooltip="Leakage Insights" matTooltipPosition="after" matTooltipClass="after">list</span></a>
                </li> -->
                <li>
                    <a routerLink="/alert-management" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><span class="material-symbols-outlined" matTooltip="Alert Management" matTooltipPosition="after" matTooltipClass="after">warning</span></a>
                </li>
                <!-- <li>
                    <a routerLink="/arcgis-map" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><span class="material-symbols-outlined" matTooltip="ArcGIS Map" matTooltipPosition="after" matTooltipClass="after">map</span></a>
                </li> -->
                <li class="btnDisabled">
                    <!-- <a routerLink="/admin/device-profile" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> -->
                        <span class="material-symbols-outlined" matTooltip="Device Management" matTooltipPosition="after" matTooltipClass="after">settings</span>
                    <!-- </a> -->
                </li>
                <li>
                    <a routerLink="/download-tn-panel" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <div [ngClass]="{ 'ripple': showRippleForDownload }" matTooltip="Data Export Manager" matTooltipPosition="after" matTooltipClass="above">
                            <span class="material-symbols-outlined">download</span>
                        </div>
                        <!-- <span class="material-symbols-outlined" matTooltip="Download Manager" matTooltipPosition="after" matTooltipClass="above">download</span> -->
                    </a>
                     <div class="toasterBlk" *ngIf="statusLoaderInfo.show" [ngClass]="{'fadeIn' : statusLoaderInfo.show }">
                        <span class="material-symbols-outlined">{{statusLoaderInfo.icon}}</span> <!-- check -->
                        <span class="toasterContent">{{statusLoaderInfo.text}}</span>
                     </div>
                </li>
            </ul>
        </div>

        <div class="sideBarBottomIcon">
            <ul>
                <li class="btnDisabled">
                    <!-- <a routerLink="/access-management/user-groups" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"> -->
                        <span class="material-symbols-outlined" matTooltip="User Management" matTooltipPosition="after" matTooltipClass="after">group</span>
                    <!-- </a> -->
                </li>
                <li>
                    <a routerLink="/developers-page" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><span class="material-symbols-outlined" matTooltip="Developer Guide" matTooltipPosition="after" matTooltipClass="after">help</span></a>
                </li>
            </ul>
        </div>
    </div>
</div>