import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from '../shared-module/shared-meter-type.service';
import { SharedServiceService } from "../../services/shared-service.service";
import { CustomDashboardService } from 'src/app/services/CustomDashboard/custom-dashboard.service';
import { DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_GATEWAY, DEVICE_TYPE_PARAMETER_ENERGY, DEVICE_TYPE_PARAMETER_WATER, DEVICE_TYPE_WATER_METER, DEVICE_TYPE_WATER_METER_GATEWAY, DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_START, DATE_TIME_FORMAT_DAY_END } from 'src/app/reusable/constant';
import { getLoginUser } from 'src/app/reusable/user-util';
import { RegistrationService } from 'src/app/reusable/services/registration.service';
import * as moment from 'moment';
import { TelemetryService } from 'src/app/reusable/services/telemetry.service';
import { FILTER_COLUMNS } from '../threshold-alert/declaration';

@Component({
  selector: 'app-select-sector',
  templateUrl: './select-sector.component.html',
  styleUrls: ['./select-sector.component.css', '../../../assets/Reusable-CSS/main.scss']
})
export class SelectSectorComponent implements OnInit {
  public returnUrl: string;
  public customDashboard = [];
  public showIcon = false;
  public isLoader = false;
  public dashboard: any;
  public option: any;
  public userName: string;
  public activeUserName = '';
  public showChildData = false;
  public defaultDashboard :any;
  public filteredDevices: any = []; // this will store the filtered data based on search and filter
  public devices = [];  // this will store the original data, and used inside the functions
  CONSUMPTION_FOR_30_DAY = "consumptionFor30Days"
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private sharedChange: SharedDataService,
    private sharedService: SharedServiceService,
    public customDashboardService: CustomDashboardService,
    public registrationService: RegistrationService,
    private telemetryService: TelemetryService,

  ) { }

  ngOnInit(): void {
    localStorage.removeItem('filtersofPage');
    this.getTagDataForStatusValues();
    this.sharedChange.onSectorChange();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/custom-dashboard/";
    this.userName = getLoginUser();
    if (this.userName) {
      this.activeUserName = this.userName.split('@')[0].split(".").join(' ');
    }
    // this.getDeviceListing();

  }


  redirectHome(meterType: string) {
    sessionStorage.setItem('meterType', meterType);
    this.router.navigate([this.returnUrl]);
  }

  redirectDashboard(dashboardId: string) {
    this.router.navigate([this.returnUrl + dashboardId]);
  }

  showTooltip(e) {
    var x = e.layerX + 20;
    var y = e.layerY + 20;
    document.getElementById("tooltip").style.left = x + "px";
    document.getElementById("tooltip").style.top = y + "px";
    document.getElementById("tooltip").style.opacity = '1';
  }

  hideTooltip() {
    document.getElementById("tooltip").style.opacity = '0';
  }

  getTagDataForStatusValues() {
    this.registrationService.getTagDataForStatusValues().subscribe((data: any) => {
      this.sharedService.statusDateList = data;
    })
  }

  isMap: boolean = true;
  showContent = 'energy';
  public meterType: string;
  objOfConsumptionFor30Days: any =  {};
  selectedSite;
  public filterColumns: any = [];
  
  handleToggle(selectedToggle) {
    this.showContent = selectedToggle;
  }

  async getDeviceListing() {
    try {
      // this.loading = true;
      const dates = [moment().subtract(29, 'days').format(DATE_TIME_FORMAT_DAY_START),moment().format(DATE_TIME_FORMAT_DAY_END)];
      const payload = {
        dates,
        filter: {'NEOM Community 1': ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Zone 5']},
        meterType: this.selectedToggleData
      }
      this.registrationService.getDevices(payload).subscribe(async (res)=>{
      // add the necessary fields to data and create filter columns
      this.addDataToTable(res);
      // this.loading = false;
      })
    }
    catch (error) {
      // this.loading = false;
      console.log(error);
    }
  }


  addDataToTable(allDeviceArray) {
    // reset the filters default value other on site changes filters persist
    this.filterColumns = new FILTER_COLUMNS;
    const keys = Object.keys(this.filterColumns);

    // add the selected feild show that we have download option available
    const data = allDeviceArray?.filter((item) => {
      if(item.deviceType.toLowerCase() != DEVICE_TYPE_GATEWAY.toLowerCase() && item.deviceType.toLowerCase() != DEVICE_TYPE_WATER_METER_GATEWAY.toLowerCase()) {
        keys.map(key => {
          if (item[key]) {
            if (this.filterColumns[key].findIndex(o => o.name === item[key]) === -1) {
              this.filterColumns[key].push({ selected: false, name: item[key], key, [key]: item[key] });
            }
          }
        })
        item['selected'] = false;  //used to track the selected device
        item['missingDates'] = item?.report?.missingDates?.length; // used to show the missing dates
        item[this.CONSUMPTION_FOR_30_DAY] = "";
      }
      return item.deviceType.toLowerCase() != DEVICE_TYPE_GATEWAY.toLowerCase() && item.deviceType.toLowerCase() != DEVICE_TYPE_WATER_METER_GATEWAY.toLowerCase();
    })
    this.devices = data;
    this.filteredDevices = this.devices;
  }
  selectedToggleData = 'Energy Meter';
  selectedToggle($event){
    console.log('$event',$event);
    
    this.selectedToggleData = $event =='water' ?'Water Meter': 'Energy Meter'
    this.getDeviceListing()
  }

  mapGrapgToggle(e){
    this.isMap=e
  }

}
